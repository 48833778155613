import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify';

import GreenCheck from './../../assets/green-checked.svg';
import GrayIncomplete from './../../assets/zondicons/close-solid.svg';
import LinkIcon from './../../assets/zondicons/link.svg';

import axios from 'axios';

import DefaultMapThumbnail from './../../assets/default-map-thumbnail.png';

import greenCheckLogo from './../../assets/green-check-border.svg';

const Spinner = require('react-spinkit');


class SignUp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      showVerification: false,
      loading: false,
      newUser: null,
      accountUUID: null,
      referringAccountUUID: this.props.match.params.referringAccountUUID,
      referringMapUUID: this.props.match.params.referringMapUUID,
      referringToken: this.props.match.params.referringToken,
      map: null, //desired map to preregister
      owner: null, // the owner of the desired map
      permissions: [], //permissions on desired map for preregister
      maps: [],
      validInvite: false // set to true when the email the user enters in matches one of the desired map permissions
    }
    this.handleChange = (e) => this._handleChange(e);
    this.handleSignupSubmit = (e) => this._handleSignupSubmit(e);
    this.handleConfirmationSubmit = (e) => this._handleConfirmationSubmit(e);
  }

  // http://localhost:3001/users/sign_up/ea9ecce1-fecf-476e-854a-ceaeb0078a5a/bfc3c1d0-9a8f-4c03-b927-51f70de764e6
  componentDidMount() {
    const { referringAccountUUID, referringMapUUID, referringToken } = this.state;
    if (referringAccountUUID && referringMapUUID && referringToken) {

      axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${referringAccountUUID}/${referringMapUUID}`,{
        responseType: 'json',
        accountUUID: referringAccountUUID,
        mapUUID: referringMapUUID,
        token: referringToken
      })
      .then(response => {
        return response.data
      }).then(map => {
        this.setState({map});
        return (map.permissions || [])
      }).then(permissions => {
        this.setState({permissions});
        return permissions.find(p => p.role==='owner')
      }).then(owner => {
        if (owner) this.setState({owner});
      })

    }
  }

  _handleChange = event => {
    const { permissions, referringToken, map } = this.state;
    if (event.target.id==='email') {

      // set email state to show up in input form
      let emailLowercase = event.target.value.toLowerCase();
      this.setState({[event.target.id]: emailLowercase});

      // search desired map permissions to see if this email matches a current pending_invitation
      let search = permissions.find(p => p.email===emailLowercase);
      if (search) {
        console.log("Search => ", search);
      }
      if (search && ['follower','member'].includes(search.role) && search.status==='invitation_sent') {
        console.log("ACK: ACCESS");

        //make sure the user has the correct token
        if (search.token===referringToken) {
          this.setState({
            validInvite: true,
            maps: [{
              uuid: map.uuid,
              account_uuid: map.accountUUID,
              role: "follower",
              title: map.title,
              status: "active"
            }]
          })
        }

      } else {
        console.log("ACK: NO ACCESS");
        this.setState({
          validInvite: false,
          maps: []
        })
      }

    } else {
  		this.setState({[event.target.id]: event.target.value});
    }
	};

  _handleSignupSubmit = async event => {
		event.preventDefault();
		this.setState({ loading: true });

		try {
			const newUser = await Auth.signUp({
				username: this.state.email,
				password: this.state.password
			});
      //console.log(newUser);
      this.props.createAccount({
        uuid: newUser.userSub,
        email: this.state.email,
        firstName: "firstName",
        lastName: "lastName",
        maps: this.state.maps
      })
			this.setState({
				newUser,
        showVerification:true,
        accountUUID: newUser.userSub
			});
      console.log(this.state);
		} catch (e) {
			alert(e.message);
		}

		this.setState({ loading: false });
	};

  _handleConfirmationSubmit = async event => {
		event.preventDefault();

    const { email, password, confirmationCode } = this.state;
    const { setAccountUUID, setToken } = this.props;

		this.setState({ loading: true });

		try {
			await Auth.confirmSignUp(email, confirmationCode);
			const user = await Auth.signIn(email, password);
      //console.log(user);
      setAccountUUID(user.username);//user.username);
      setToken(await Auth.currentSession());
		} catch (e) {
			alert(e.message);
			this.setState({ loading: false });
		}
	};

  componentDidUpdate(prevProps) {
    const { session, history } = prevProps;
    const nextProps = this.props;
    if (nextProps.session.token !== session.token) {
      if ( nextProps.session.token) {
        nextProps.history.push('/desktop');
      }
    }
  }

  render () {
    const {
      map,
      owner,
      validInvite,
      loading,
      showVerification,
      confirmationCode,
      confirmPassword,
      email,
      password
    } = this.state;

    const { session: { tenant: { title } } } = this.props;

    return [
      <div key='Signup' className="Signup">
        {!map && <h5>{title} Sign Up</h5>}
        {map && owner && <div>
          <div className='SignUpCard'>
            <div className='SignUpCard_container'>
              <div className="card_map">
                <img alt='default' src={DefaultMapThumbnail}/>
              </div>
              <div className="CardDetail">
                <div className="CardDetailContainer">
                  <div className="card_name">
                    <div className='CardDetailContainer__container'>
                      <h4>{map.title}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='InvitedBy'>
            <p><img src={LinkIcon}/> sent by {owner.email}</p>
          </div>
        </div>}
        {
          !showVerification ? (
            <form onSubmit={this.handleSignupSubmit}>
              <div className='formInput'>
                <input
                  id='email'
                  value={email}
                  placeholder='Email'
                  type='email'
                  onChange={this.handleChange} />
                {validInvite && <img src={greenCheckLogo} />}
              </div>
              <div className='formInput'>
                <input
                  id='password'
                  value={password}
                  placeholder='Password'
                  type='password'
                  minLength={6}
                  onChange={this.handleChange} />
              </div>
              <div className='formInput'>
                <input
                  id='confirmPassword'
                  value={confirmPassword}
                  placeholder='Confirm Password'
                  type='password'
                  minLength={6}
                  onChange={this.handleChange} />
              </div>
              <div className='formInput'>
                <div className={(loading || (map && !validInvite)) ? 'loginButton disabled' : 'loginButton'}>
                  {!loading && <input type='submit' value='Sign up'/>}
                  {loading && <div className='SpinnerBox'><Spinner name='ball-clip-rotate' color='white'/></div>}
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={this.handleConfirmationSubmit}>
              <div className='Signup__StepSummary'>
                <div className='Signup__StepSummaryStep'>
                  <div className='icon'><img src={GreenCheck}/></div>
                  <p>Register for Geotiller</p>
                </div>
                <div className='Signup__StepSummaryStep'>
                  <div className='icon'>
                    {(confirmationCode==='') ? (
                      <img src={GrayIncomplete}/>
                    ) : (
                      <img src={GreenCheck}/>
                    )}
                  </div>
                  <p>Check your email for verification code</p>
                </div>
                <div className='Signup__StepSummaryStep'>
                  <div className='icon'><img src={GrayIncomplete}/></div>
                  <p>Login with new password</p>
                </div>
              </div>
              <div>
                <input
                  id='confirmationCode'
                  value={confirmationCode}
                  onChange={this.handleChange}
                  placeholder='Verification code' />
              </div>
              <div>
                <div className={loading ? 'loginButton disabled' : 'loginButton'}>
                  {!loading && <input type='submit' value='Verify'/>}
                  {loading && <div className='SpinnerBox'><Spinner name='ball-clip-rotate' color='white'/></div>}
                </div>
              </div>
            </form>
          )
        }
      </div>,
      <div key='Signup-footer' className="Signup-footer">
        Already have an account? <Link to="/users/sign_in">Sign in</Link>
      </div>
    ]
  }
}

export default SignUp
