
import React, {Component} from 'react'

import searchIcon from './../../assets/search.svg';
import closeIcon from './../../assets/close-1.svg';

import { confirmAlert } from 'react-confirm-alert'; // Import


import axios from 'axios';

class InfoMapSettingsAddUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "", //email search text input state
      searching: false, //bool to show error message
      searchFail: false,
      searchUsers: [],
      // roles: [
      //   //{ key: "owner", title: "Owner"},
      //   //{ key: "member", title: "Member"},
      //   { key: "follower", title: "Follower"}
      // ],
      users: this.props.users
      // users: [
      //   {
      //     accountUUID: "ea9ecce1-fecf-476e-854a-sd",
      //     email: "edmundrichards@gmail.com",
      //     role: "follower",
      //     status: null
      //   },
      //   {
      //     accountUUID: "ea9ecce1-fecf-dfgdfg-854a-ceaeb008a5a", //implys they are user
      //     email: "invited-geotiller-user@gmail.com",
      //     role: "follower",
      //     status: 'pending_invitation' //some that the map owner has invited to the map
      //   },
      //   {
      //     accountUUID: "ea9ecce1-fecf-dfgdfg-854a-sdfdsf", //implys they are user
      //     email: "invited-user-confirmed@gmail.com",
      //     role: "follower",
      //     status: 'confirmed_invitation' //some that the map owner has invited to the map
      //   },
      //   {
      //     email: "invitied-not-user@gmail.com",
      //     role: "follower",
      //     status: 'pending_invitation' //some that the map owner has invited to the map
      //   },
      //   {
      //     email: "invitied-not-user-map-service-executed@gmail.com",
      //     role: "follower",
      //     status: 'invitation_sent' //map service successfully sent out the invitation to sign up to this non geotiller user
      //   },
      //   {
      //     accountUUID: "ea9ecce1-fecf-476e-854a-453", //implys they are user
      //     email: "requested@gmail.com",
      //     role: "follower",
      //     status: 'requested' //someone who random requests access to your map, either as a user or during sign up
      //   },
      //   {
      //     accountUUID: "ea9ecce1-fecf-476e-854a-sdfdsf",
      //     email: "confirmed@gmail.com",
      //     role: "follower",
      //     status: 'request_confirmed' //somone who has been confirmed to view your map
      //   },
      // ]
    };

    this.handleMapPermissionRemoval = (email) => this._handleMapPermissionRemoval(email);
    this.handleSearch = (e) => this._handleSearch(e);
    this.handleSearchTermKeyPress = (e) => this._handleSearchTermKeyPress(e);
    this.handleInviteUser = () => this._handleInviteUser()
    this.handleContactSelection = (contact) => this._handleContactSelection(contact);
    this.handlePendingUserConfirm = (user) => this._handlePendingUserConfirm(user)
  }

  _handleSearch = () => {
    const searchTerm = this.state.searchTerm; //e.target.value;
    this.setState({searching: true});
    if (searchTerm) {
      var state = this.state;
      axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/accounts`,{
        params: {
          email: searchTerm
        }
      })
      .then(response => response.data)
      .then(account => {
        state.searchUsers = [{
          accountUUID: account.uuid,
          email: account.email,
          firstName: account.firstName,
          lastName: account.lastName,
          role: "",
          active: false
        }];
        this.setState(state);
      })
      .catch((err) => {
        console.log(err);
        this.setState({searchUsers: [], searchFail: true});
      })
    }
  }

  _handleSearchTermKeyPress = (e) => {
    let searchTerm = e.target.value;
    searchTerm = searchTerm.replace(/\s/g, "");
    this.setState({searchTerm, searching: false, searchFail: false});
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  }

  _handleMapPermissionRemoval = (email) => {
    this.setState({
      users: this.state.users.filter((user) => user.email!==email).map((user) => user)
    });
    this.props.onUserRemove({email})
  }

  _handleInviteUser = () => {
    var searchTerm = this.state.searchTerm
    var user = {
      email: searchTerm,
      role: "follower",
      status: "pending_invitation"
    };
    confirmAlert({
      title: 'Confirm subscription',
      message: `Are you sure you want to invite ${user.email} to have read-only privledges on this project?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({
              searchTerm: "",
              searching: false,
              searchFail: false,
              searchUsers: [],
              users: this.state.users.concat([user])
            });
            this.props.onUserInvite(user);
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting handleInviteUser")}
        }
      ]
    })

  }

  _handleContactSelection = (contact) => {

    var user = {
      ...contact,
      role: "follower",
      status: "pending_invitation"
    }
    confirmAlert({
      title: 'Confirm subscription',
      message: `Are you sure you want to give read-only privledges to ${user.email}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({
              searchTerm: "",
              searching: false,
              searchFail: false,
              searchUsers: [],
              users: this.state.users.concat([user])
            });
            this.props.onUserSelection(user);
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting handleContactSelection")}
        }
      ]
    })

  }

  _handlePendingUserConfirm = (confirmedUser) => {

    var user = {
      ...confirmedUser,
      status: "request_confirmed"
    }
    confirmAlert({
      title: 'Confirm subscription',
      message: `Are you sure you want to give read-only privledges to ${user.email}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({
              users: this.state.users.map((user) => {
                return {
                  ...user,
                  status: ((user.status==='requested'&&user.accountUUID===confirmedUser.accountUUID) ? 'request_confirmed' : user.status)
                }
              })
            })
            this.props.onUserConfirm(user)
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting handlePendingUserConfirm")}
        }
      ]
    })
  }

  render() {
    const {
      //viewport,
      //title,
      searching,
      searchTerm,
      searchFail,
      searchUsers,
      //roles,
      users
    } = this.state;

    const {
      header,
      subHeader,
      //userType,
    } = this.props;

    const confirmedUsers = users.filter((user) => user.status==='confirmed_invitation'||user.status==='request_confirmed');
    const pendingUsers = users.filter((user) => user.status==='requested'||user.status==='invitation_sent'||user.status==='pending_invitation').sort(user => user.status==='requested').reverse();


    return (

      <div className='InfoMapSettings__content-item'>
        <h5>{header}</h5>
        <p>{subHeader}</p>
        <div className='InfoMapSettings__content-item-input'>
          <div className='UserSelection'>
            <div className='UserSelection__search'>
              <div className='UserSelection__search-container'>
                <img alt='Search' src={searchIcon} />
                <input placeholder='Search user by email' value={searchTerm} onChange={this.handleSearchTermKeyPress} onKeyPress={this.handleSearchTermKeyPress}/>
                <button onClick={this.handleSearch}>Search</button>
              </div>
            </div>
            <div className='UserSelection__content'>
              <div className='UserSelection__content-items'>
                {searching && searchFail && <div className='UserSelection__content-item--fail'>
                  <div className='UserSelection__content-item-name'>
                    <span>No user found.</span>
                  </div>
                  <div className='UserSelection__content-item-invite'>
                    <button onClick={this.handleInviteUser}>Invite {searchTerm}</button>
                  </div>
                </div>}
                {searchUsers.map((user, index) => {
                  return (
                    <div key={index} className='UserSelection__content-item' onClick={() => this.handleContactSelection(user)}>
                      <div className={user.active ? 'UserSelection__content-item-accessory UserSelection__content-item-accessory--active': 'UserSelection__content-item-accessory'}>
                      </div>
                      <div className='UserSelection__content-item-name'>
                        {false && <span>{user.firstName} {user.lastName}</span>}
                        <span>{user.email}</span>
                      </div>
                      <div className='UserSelection__content-item-title'>
                        <span>{user.status}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {pendingUsers.length > 0 && <div className='UserSelection__footer'>
              <div className='UserSelection__footer-container'>
                <div className='UserSelection__footer-header'>
                  <h3>Pending Subscribers</h3>
                </div>
                <div className='UserSelection__footer-items'>
                  {pendingUsers.map((user, index) => {
                    return (
                      <div key={index} className='UserSelection__footer-item'>
                        <div className='UserSelection__footer-item-name'>
                          <span>{user.email}</span>
                        </div>
                        <div className='UserSelection__status'>
                          {(user.status==='pending_invitation' || user.status==='invitation_sent') && <span>Invitation sent</span>}
                          {user.status==='requested' && <div className='UserSelection__content-item-invite'>
                            <button onClick={() => this.handlePendingUserConfirm(user)}>Confirm</button>
                          </div>}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>}
            {confirmedUsers.length > 0 && <div className='UserSelection__footer'>
              <div className='UserSelection__footer-container'>
                <div className='UserSelection__footer-header'>
                  <h3>Active Subscribers</h3>
                </div>
                <div className='UserSelection__footer-items'>
                  {confirmedUsers.map((user, index) => {
                    return (
                      <div key={index} className='UserSelection__footer-item'>
                        <div className='UserSelection__footer-item-name'>
                          {false && <span>{user.firstName} {user.lastName}</span>}
                          <span>{user.email}</span>
                        </div>
                        <div className='UserSelection__footer-item-select'>
                        </div>
                        {user.role!=='owner' && <div className='UserSelection__footer-item-close' onClick={() => this.handleMapPermissionRemoval(user.email)}>
                          <img alt='Close' src={closeIcon}/>
                        </div>}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    );
  }
};

export default InfoMapSettingsAddUser
