
import React, {Component} from 'react'

//import Select from 'react-select';
//import 'react-select/dist/react-select.css';

import FeatureListClick from './FeatureListClick'

import './FeatureItemFeedClick.css';

class FeatureItemFeedClick extends Component {

  render() {
    // const assetTypeOptions = [
    //   { label: 'Office', value: 'Office' },
    //   { label: 'Retail', value: 'Retail' },
    //   { label: 'Industrial', value: 'Industrial' },
    //   { label: 'Multifamily', value: 'Multifamily' },
    //   { label: 'Hotel', value: 'Hotel' }
    // ];
    // const fileTypeOptions = [
    //   { label: "Leases & Abstracts", value: "Leases & Abstracts" },
    //   { label: "Offering Memorandum/Sale Flyer", value: "Offering Memorandum/Sale Flyer"},
    //   { label: "Operating Agreements", value: "Operating Agreements"},
    //   { label: "Leasing Flyer", value: "Leasing Flyer"},
    //   { label: "Legal", value: "Legal"},
    //   { label: "Letter of Intent", value: "Letter of Intent"},
    //   { label: "Property Reports", value: "Property Reports"},
    //   { label: "Rent Roll", value: "Rent Roll"},
    //   { label: "Financials", value: "Financials"},
    //   { label: "Sale Comps", value: "Sale Comps"},
    //   { label: "Lease Comps", value: "Lease Comps"},
    //   { label: "Loan", value: "Loan"},
    //   { label: "Investment Memo", value: "Investment Memo"},
    //   { label: "Plans", value: "Plans"},
    //   { label: "Stacking Plan", value: "Stacking Plan"},
    //   { label: "Permit/Zoning", value: "Permit/Zoning"},
    //   { label: "Title/Survey", value: "Title/Survey"},
    //   { label: "Research", value: "Research"},
    //   { label: "Articles", value: "Articles"}
    // ]
    const {
      accountUUID,
      setPlaceId,
      places,
      feature,
      features,
      featuresByPlaceId,
      toggleMediaGallery,
      //documents,
      maps,
      mapsByUUID,
      setMapUUID,
      //setSidebarTab,
      //setFeature,
      setFeatureUUID,
      setFeatureAnimate,
      setPlaceAnimate,
      createFeature,
      //assetTypeTopics,
      //toggleAssetTypeFilter,
      //fileTypeTopics,
      //toggleFileTypeFilter,
      navigation,
      setSidebarCreateFeature,
      displayPhotos,
      history,
      parentView,
      match
    } = this.props;

    return (
      <div className='FeatureItemFeedClick FeatureItemFeedClick--full'>
        <div className='FeatureItemFeedClick__container'>
                <FeatureListClick
                  places={places}
                  navigation={navigation}
                  toggleMediaGallery={toggleMediaGallery}
                  feature={feature}
                  features={features}
                  featuresByPlaceId={featuresByPlaceId}
                  maps={maps}
                  mapsByUUID={mapsByUUID}
                  setPlaceId={setPlaceId}
                  setMapUUID={setMapUUID}
                  setFeatureUUID={setFeatureUUID}
                  setFeatureAnimate={setFeatureAnimate}
                  setPlaceAnimate={setPlaceAnimate}
                  createFeature={createFeature}
                  setSidebarCreateFeature={setSidebarCreateFeature}
                  displayPhotos={displayPhotos}
                  history={history}
                  accountUUID={accountUUID}
                  parentView={parentView}
                  match={match}
                />
        </div>
      </div>
    )
  }
};

export default FeatureItemFeedClick
