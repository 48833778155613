
import React, {Component} from 'react'
//import PropTypes from 'prop-types'
import './InfoMapCardDeck.css';

import {StaticMap} from 'react-map-gl';

import { Link } from 'react-router-dom';

import InfoMapSettings from './InfoMapSettings';

import verticalMenu from './../../assets/vertical-menu.svg';
//import eye from './../../assets/eye.svg';

import config from './../../config'

import viewHideIcon from './../../assets/zondicons/view-hide.svg';
import viewShowIcon from './../../assets/zondicons/view-show.svg';
import shareIcon from './../../assets/zondicons/share-alt.svg';
import CloseIcon from './../../assets/close-2.svg';

import DefaultMapThumbnail from './../../assets/default-map-thumbnail.png';

const uuidv4 = require('uuid/v4');

class InfoMapCardDeck extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mapName: null,
      mapURL: null,
      creatingMap: false,
      cloningMap: false,
      titleEdit: {
        uuid: null,
        title: null
      }
    };

    // var data = {
    //
    // }

    this.handleMapNameKeyPress = (e) => this._handleMapNameKeyPress(e);
    this.handleMapNameKeyUp = (e) => this._handleMapNameKeyUp(e);
    this.toggleCreate = () => this._toggleCreate();
    this.handleCreateMap = () => this._handleCreateMap();

    this.handleMapURLKeyPress = (e) => this._handleMapURLKeyPress(e);
    this.handleMapURLKeyUp = (e) => this._handleMapURLKeyUp(e);
    this.toggleClone = (e) => this._toggleClone(e);
    this.handleCloneMap = () => this._handleCloneMap();

    this.onTitleChange = (e) => this._onTitleChange(e);
  }

  _onTitleChange = (e) => {
    console.log(e.target);
    console.log(e.target.value);
    var { titleEdit } = this.state;
    titleEdit.title = e.target.value;
    this.setState({titleEdit})
  }

  _handleMapNameKeyPress = (e) => {
    if (e.key === 'Enter') this.handleCreateMap();
  }

  _handleMapNameKeyUp = (e) => {
    this.setState({mapName: e.target.value});
  }

  _toggleCreate = () => {
    const { creatingMap } = this.state;
    this.setState({creatingMap: !creatingMap});
  }

  _handleCreateMap = () => {
    const { mapName } = this.state;
    const { account, accountUUID } = this.props;
    if (mapName) {
      this.props.createMap({
        accountUUID,
        uuid: uuidv4(),
        title: mapName,
        mapType: "Real Estate",
        lat: 39.017437,
        lng: -97.815787,
        zoom: 1,
        secret: true,
        permissions: [
          {
            accountUUID,
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            role: "owner"
          }
        ]
      })
      this.toggleCreate();
    }
  }

  _handleMapURLKeyPress = (e) => {
    if (e.key === 'Enter') this.handleCloneMap();
  }

  _handleMapURLKeyUp = (e) => {
    this.setState({mapURL: e.target.value});
  }

  _toggleClone = () => {
    const { cloningMap } = this.state;
    this.setState({cloningMap: !cloningMap});
  }

  _handleCloneMap = () => {
    const { mapURL } = this.state;
    this.toggleClone();

    var entities = mapURL.split('/infomap/')[1];
    var cloneAccountUUID = entities.split('/')[0];
    var cloneMapUUID = entities.split('/')[1];

    const { accountUUID, buildMap, setError } = this.props;

    if (cloneAccountUUID && cloneMapUUID) {
      buildMap({
        accountUUID: accountUUID,
        clone: {
          accountUUID: cloneAccountUUID,
          mapUUID: cloneMapUUID
        }
      })
    } else {
      setError({
        message: "Issue cloning map"
      })
    }

  }

  render() {
    const { creatingMap, cloningMap, titleEdit } = this.state;
    const {
      account,
      filteredMaps,
      mapUUIDs,
      deselectMap,
      //resetMaps,
      getMaps,
      selectMap,
      selectedMap,
      updateMap,
      setMapUUID,
      navigation,
      setCardDeckTab,
      //createMap,
      accountUUID,
      toggleInfomapEditor,
      toggleHamburger,
      setSubnav,
      trashMap,
      history,
      isRegularAccountType,
      session,
      session: { tenant: { nomenclature: { partition: { singular: Partition, plural: Partitions }}}}
    } = this.props;
    return (
      <div>
        {navigation.infomapeditor.display && <InfoMapSettings session={session} history={history} map={selectedMap} trashMap={trashMap} toggleHamburger={toggleHamburger} updateMap={updateMap} toggleInfomapEditor={toggleInfomapEditor} getMaps={getMaps} account={account}/>}
        <div className='CardDeckNav'>
          <div className='CardDeckNav__close'>
            <div className='menu'>
              <a onClick={() => setSubnav(false)}>
                <img alt='Close' src={CloseIcon}/>
              </a>
            </div>
          </div>
          <div className='CardDeckNav__title'>
            <h1>{Partitions}</h1>
          </div>
          <div className='CardDeckNav__createInfomap'>
            <div className='button-box'>
              {false && <a className='button' onClick={this.toggleClone}>Add {Partition}</a>}
              {false && <a className='button' onClick={this.toggleClone}>Clone {Partition}</a>}
              {isRegularAccountType && <a className='button primary' onClick={this.toggleCreate}>Create {Partition}</a>}
            </div>
            { cloningMap &&
            <div className='CardDeckNav__config'>
              <input ref='mapURL' type='text' placeholder='Enter URL...' onKeyUp={this.handleMapURLKeyUp} onKeyPress={this.handleMapURLKeyPress}/>
              <div className='button-box'>
                <a className='button primary' onClick={this.handleCloneMap}>Add</a>
              </div>
            </div>
            }
            { creatingMap &&
            <div className='CardDeckNav__config'>
              <input ref='mapName' type='text' placeholder={`Title your ${Partition.toLowerCase()}...`} onKeyUp={this.handleMapNameKeyUp} onKeyPress={this.handleMapNameKeyPress}/>
              <div className='button-box'>
                <a className='button primary' onClick={this.handleCreateMap}>Create</a>
              </div>
            </div>
            }
          </div>
        </div>
        <div className='card-deck-tab-container'>
          <div className={navigation.carddeck.tabcontainer.all ? 'card-deck-tab selected' : 'card-deck-tab'} onClick={() => setCardDeckTab({all: true, owned: false, member: false, following: false}) }>
            <div className='card-deck-tab-name'>
              <span>All {filteredMaps.maps.length}</span>
            </div>
          </div>
          <div className={navigation.carddeck.tabcontainer.owned ? 'card-deck-tab selected' : 'card-deck-tab'} onClick={() => setCardDeckTab({all: false, owned: true, member: false, following: false}) }>
            <div className='card-deck-tab-name'>
              <span>My {Partitions} {filteredMaps.ownedMaps.length}</span>
            </div>
          </div>
          <div className={navigation.carddeck.tabcontainer.member ? 'card-deck-tab selected' : 'card-deck-tab'} onClick={() => setCardDeckTab({all: false, owned: false, member: true, following: false}) }>
            <div className='card-deck-tab-name'>
              <span>Member {filteredMaps.memberMaps.length}</span>
            </div>
          </div>
          <div className={navigation.carddeck.tabcontainer.following ? 'card-deck-tab selected' : 'card-deck-tab'} onClick={() => setCardDeckTab({all: false, owned: false, member: false, following: true}) }>
            <div className='card-deck-tab-name'>
              <span>Following {filteredMaps.followingMaps.length}</span>
            </div>
          </div>
        </div>
        <div className='CardDeck'>
          {isRegularAccountType && filteredMaps.filteredMaps.length===0 && <div>
            {navigation.carddeck.tabcontainer.all && <span>No {Partitions.toLowerCase()}. <a onClick={this.toggleCreate}>Create your first {Partition.toLowerCase()}</a></span>}
            {navigation.carddeck.tabcontainer.owned && <span>No {Partitions.toLowerCase()}. <a onClick={this.toggleCreate}>Create your first {Partition.toLowerCase()}</a></span>}
            {navigation.carddeck.tabcontainer.member && <span>Not a member of any {Partitions.toLowerCase()}.</span>}
            {navigation.carddeck.tabcontainer.following && <span>Not following any {Partitions.toLowerCase()}.</span>}
          </div>}
          {filteredMaps.filteredMaps.map((map) => {
            //const InfoMapURL = 'infomap/account_uuid_1/map_uuid_1';
            return (
              <div key={map.uuid} className={((map.pending) ? 'card disabled' : ((mapUUIDs.includes(map.uuid) ? 'card in-deck selected' : 'card in-deck')))}>
                <div className='card_container'>
                  {false && <div className='CardContainer__preview'>
                    <Link to={`/infomap/${map.accountUUID}/${map.uuid}`} target="_blank"><img alt='Share' src={shareIcon} /></Link>
                  </div>}
                  {map.accountUUID===accountUUID && <div className='CardContainer__edit'>
                    <a onClick={() => {
                      //check if current account is the owner before displaying infomap editor
                      if (map.accountUUID===accountUUID) {
                        setMapUUID(map.uuid);
                        toggleInfomapEditor();
                      }
                    }}><img alt='Menu' src={verticalMenu} /></a>
                  </div>}
                  <div className="card_map" onClick={() => {
                    if (mapUUIDs.includes(map.uuid)) return deselectMap(map)
                    return selectMap(map)
                  }}>
                    {false && <StaticMap
                      mapStyle="mapbox://styles/mapbox/streets-v9"
                      zoom={map.default.zoom}
                      height={160}
                      width={220}
                      latitude={map.default.lat}
                      longitude={map.default.lng}
                      reuseMaps={true}
                      mapboxApiAccessToken={config.MapBox}
                    >
                    </StaticMap>}
                    <img alt='default' src={DefaultMapThumbnail}/>
                  </div>
                  <div className="CardDetail" onClick={() => {
                    if (mapUUIDs.includes(map.uuid)) return deselectMap(map)
                    return selectMap(map)
                  }}>
                    <div className="CardDetailContainer">
                      <div className="card_name">
                        {((titleEdit.uuid===map.uuid) ? (
                          <div className='CardDetailContainer__container'>
                            <input id={map.uuid} onChange={this.onTitleChange} value={titleEdit.title}/>
                          </div>
                        ) : (
                          <div className='CardDetailContainer__container'>
                            {(mapUUIDs.includes(map.uuid)) ? (
                              <img alt='Selected' style={{color: 'white'}} src={viewShowIcon} />
                            ) : (
                              <img alt='Hidden' style={{color: 'black'}} src={viewHideIcon} />
                            )}
                            <h3>{map.title}</h3>
                            {false && <div onClick={() => this.setState({titleEdit: {uuid: map.uuid, title: map.title}})}>EDIT</div>}
                          </div>
                        ))}
                      </div>
                      <div className="card_url"></div>
                      <div className="setup"></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
};

export default InfoMapCardDeck
