
import DefaultTenant from './Default'

export default {
  ...DefaultTenant,
  "title": "Golden State Landmark",
  "homepageBackgroundImg": "/homepage/GSL.jpg",
  "callToAction": {
    "backgroundColor": "#4098D7",
  },
  "nomenclature": {
    "partition": {
      "singular": "Partition",
      "plural": "Partitions"
    }
  },
  "mapMultiselect": false,
  "mapClusters": false,
}
