import React, {Component} from 'react'
//import PropTypes from 'prop-types'

import FilePublisher from './FilePublisher';
import LinkPublisher from './LinkPublisher';
import DataPublisher from './DataPublisher';

import MapGL, {Marker} from 'react-map-gl';
//import SplitPane from 'react-split-pane';
//import { Table } from 'react-materialize';
//import { Link } from 'react-router-dom';
import Progress from 'react-progress';

//import PropertyPopup from './../PropertyPopup'
//import FeatureBuilder from './../FeatureBuilder'
import MiniCardDeck from './../MiniCardDeck'

//import SeedPublisherIcon from './../../assets/geotiller.gif'
//import Logo from './../../assets/geotiller.gif';
import greenCheckLogo from './../../assets/green-check-border.svg';
import mapLogo from './../../assets/zondicons/map.svg';
import locationLogo from './../../assets/zondicons/location-current.svg';
import publishLogo from './../../assets/zondicons/flag.svg';

import './SeedPublisher.css';

const uuidv4 = require('uuid/v4');
const geocoder = require('geocoder');
const Spinner = require('react-spinkit');

class SeedPublisher extends Component {

  constructor(props) {
    super(props);
    this.state = {
      linkForm: {
        snapshotAt: null,
        values: {
          url: null
        }
      },
      dataForm: {
        mapType: null,
        locationType: null,
        locationTypeKey: null,
        dataType: null,
        snapshotAt: null,
        values: {
        }
      },
      placeholderAddress: '',
      createdFeature: false //when set to true, handleCancel will destroy the feature
    };
    this.handleKeyUp = (e) => this._handleKeyUp(e);
    this.handleKeyPress = (e) => this._handleKeyPress(e);
    this.handleResultSelection = (e) => this._handleResultSelection(e);
    this.handleCreateMap = (e) => this._handleCreateMap(e);
    this.handleLocationOnChange = (e) => this._handleLocationOnChange(e);
    this.handleLocationSet = () => this._handleLocationSet();
    this.handleCancel = () => this._handleCancel();
    this.handlePublish = () => this._handlePublish();
    this.resize = () => this._resize();
  }

  componentDidMount() {
    const {
      mapUUID,
      placeId,
      flagType
    } = this.props.match.params;
    const {
      setMapUUID,
      setPlaceId,
      setMapSearchResults,
      setSeedPublisherTab,
      unsetSeedPublisherFileSuccess,
    } = this.props;

    unsetSeedPublisherFileSuccess();

    setMapUUID( ((mapUUID==='null') ? null : mapUUID) || null);
    //setFeatureUUID(null);
    setPlaceId( ((placeId==='null') ? null : placeId) || null);

    if (flagType) {
      let tabs = {file: true, link: false, data: false};
      if (flagType==='link') tabs = {file: false, link: true, data: false};
      if (flagType==='data') tabs = {file: false, link: false, data: true};
      setSeedPublisherTab(tabs)
    }

    setMapSearchResults([]);

    // resize map
    window.addEventListener('resize', this.resize);
    setTimeout(() => this.resize(), 300);

  }

  componentDidUpdate(prevProps) {
    const { feature } = prevProps;
    const nextProps = this.props;

    if (nextProps.feature!==feature) {
      if (nextProps.feature && nextProps.feature.uuid) {
        nextProps.setFeatureUUID(nextProps.feature.uuid);
      }
    }

  }

  componentWillUnmount() {
    this.props.setMapSearchResults([]);
    //this.props.setMapUUID(null);
    window.removeEventListener('resize', this.resize);
  }

  _resize = () => {
    let viewport = {
      ...this.props.navigation.seedpublisher.viewport,
      width: window.innerWidth,//-this.props.navigation.sidebar.width,
      height: window.innerHeight
    };
    this.props.updatePublisherMapViewport(viewport)
  };

  _handleResultSelection = (result) => {
    const {
      map,
      createFeature,
      setMapSearchResults,
      setFeatureUUID,
      setMapUUID,
      setPlaceId
      //setPublisherMapHeight
    } = this.props;
    if (map) {
      var featureUUID = uuidv4();
      createFeature({
        accountUUID: map.accountUUID,
        mapUUID: map.uuid,
        uuid: featureUUID,
        title: result.formatted_address,
        //parentFeatureUUID: this.props.console.feature.uuid,
        featureType: 'PROPERTY',
        formattedAddress: result.formatted_address,
        lat: result.geometry.location.lat,
        lng: result.geometry.location.lng,
        placeID: result.place_id,
      })
      setPlaceId(result.place_id);
      setMapUUID(map.uuid);
      setFeatureUUID(featureUUID);
      setMapSearchResults([]);
      this.setState({createdFeature: true});
    }
  }

  _handleCancel = () => {
    const {
      mapUUID,
      featureUUID,
      deleteFeature,
      history,
      //setMapUUID
    } = this.props;

    //setMapUUID(null);
    //unsetPlaceId();

    const { createdFeature } = this.state;

    if (mapUUID && featureUUID && createdFeature) {
      //console.log("SeedPublisher feature delete - featureUUID=> ", featureUUID);
      deleteFeature({
        mapUUID,
        featureUUID
      }, {notification: false});
    }

    history.goBack();
  }

  _handlePublish = () => {
    const {
      onDataPublish,
      onLinkPublish,
      accountUUID,
      history,
      placeId,
      //setMapUUID
    } = this.props;

    //unsetPlaceId();
    //setMapUUID(null);

    const {
      linkForm,
      dataForm
    } = this.state;

    console.log('SeedPublisher.handlePublish dataForm => ', dataForm);

    if (linkForm.snapshotAt) {
      console.log('SeedPublisher.handlePublish publishing Link =>', linkForm.values.url);
      onLinkPublish(linkForm.values);
    }

    if (dataForm.snapshotAt) {
      console.log('SeedPublisher.handlePublish publishing Data =>', dataForm.values);
      onDataPublish(dataForm);
    }

    //history.goBack();
    history.push(`/desktop/${accountUUID}/${placeId}/docpane`);
  }

  _handleKeyPress = (e) => {
    const { setMapSearchResults} = this.props;
    this.setState({placeholderAddress: e.target.value})
    if (e.key === 'Enter') {
      geocoder.geocode(e.target.value, ( err, data ) => {
        if (data && data.results) {
          //console.log(data.results);
          setMapSearchResults(data.results);
        }
      }, {key: 'AIzaSyA-ZsQ_cNv0oH_v_1EqCGHnCJFNNe784Uk'})
    }
  }

  _handleLocationSet = () => {
    console.log('handleLocationSet');
    const { setMapSearchResults} = this.props;
    const { placeholderAddress } = this.state;
    console.log(placeholderAddress);
    geocoder.geocode(placeholderAddress, ( err, data ) => {
      if (data && data.results) {
        setMapSearchResults(data.results);
      }
    }, {key: 'AIzaSyA-ZsQ_cNv0oH_v_1EqCGHnCJFNNe784Uk'})
  }

  _handleLocationOnChange = (e) => {
    const { setMapSearchResults} = this.props;
    this.setState({placeholderAddress: e.target.value})
    geocoder.geocode(e.target.value, ( err, data ) => {
      if (data && data.results) {
        setMapSearchResults(data.results);
      }
    }, {key: 'AIzaSyA-ZsQ_cNv0oH_v_1EqCGHnCJFNNe784Uk'})
  }

  _handleCreateMap = (e) => {
    const mapName = e.target.value;
    const { account, accountUUID, setMapUUID } = this.props;
    if (e.key === 'Enter' && mapName) {
      var mapUUID = uuidv4();
      this.props.createMap({
        accountUUID,
        uuid: mapUUID,
        title: mapName,
        mapType: "Real Estate",
        lat: 39.017437,
        lng: -97.815787,
        zoom: 1,
        secret: true,
        permissions: [
          {
            accountUUID,
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.email,
            role: "owner"
          }
        ]
      })
      setMapUUID(mapUUID);
    }
  }

  getSeedPublisherHeader() {

    const {
      feature,
      //navigation,
      //accountUUID,
      mapUUID,
      map,
      unsetFeatureUUID,
      unsetMapUUID,
      //unsetPlaceId
    } = this.props;

    return (
      <div className='StepBox__container'>
        <div className={mapUUID ? 'StepBox__step completed' : 'StepBox__step'}>
          <div className='StepBox__step-container' onClick={() => {
              unsetMapUUID(mapUUID);
              unsetFeatureUUID();
              //unsetPlaceId();
            }}>
            <div className='StepBox__stepAccessory'>
              {(mapUUID) ? (
                <img alt='Done' className='completed' src={greenCheckLogo}/>
              ) : (
                <img alt='Select map' className='completed' src={mapLogo}/>
              )}
            </div>
            {mapUUID ? (
              <div className='StepBox__stepDetails'>
                <span className='status'>Completed</span>
                {map && <span className='title'>{map.title}</span>}
              </div>
            ) : (
              <div className='StepBox__stepDetails'>
                <span className='status'>Step 1</span>
                <span className='title'>Choose a map</span>
              </div>
            )}
          </div>
        </div>
        <div className={feature ? 'StepBox__step completed' : (map ? 'StepBox__step' : 'StepBox__step disabled')}>
          <div className='StepBox__step-container' onClick={() => {
            unsetFeatureUUID();
            //unsetPlaceId();
          }}>
            <div className='StepBox__stepAccessory'>
              {feature ? (
                <img alt='Done' className='completed' src={greenCheckLogo}/>
              ) : (
                <img alt='Select location' className='completed' src={locationLogo}/>
              )}
            </div>
            {feature ? (
              <div className='StepBox__stepDetails'>
                <span className='status'>Completed</span>
                <span className='title'>{feature.title}</span>
                </div>
            ) : (
              <div className='StepBox__stepDetails'>
                <span className='status'>Step 2</span>
                <span className='title'>Find a location</span>
              </div>
            )}
          </div>
        </div>
        <div className={(mapUUID&&feature) ? 'StepBox__step' : 'StepBox__step disabled'}>
          <div className='StepBox__step-container'>
            <div className='StepBox__stepAccessory'>
              <img alt='Select items to publish' className='completed' src={publishLogo}/>
            </div>
            <div className='StepBox__stepDetails'>
              <span className='status'>Step 3</span>
              <span className='title'>Publish</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {

    const { flagType } = this.props.match.params;

    const {
      setSeedPublisherTab,
      onFilePublish,
      onFilePublishSuccess,
      featuresByPlaceId,
      feature,
      createFeature,
      navigation,
      accountUUID,
      mapUUID,
      locationTypes,
      dataTypes,
      mapType,
      mapTypeKey,
      setMapTypeKey,
      locationType,
      locationTypeKey,
      setLocationTypeKey,
      dataType,
      dataTypeKey,
      setDataTypeKey,
      //unsetFeatureUUID,
      //map,
      //maps,
      writableMaps,
      setMapUUID,
      //selectMap,
      //unsetMapUUID,
      mapSearchResults,
      setFeatureUUID,
      setPlaceId,
      //unsetPlaceId,
      placeId
    } = this.props;

    const { placeholderAddress } = this.state;

    const savingDisabled = (
      navigation.seedpublisher.loading.blocking ||
      !mapUUID ||
      !feature
    );

    return (
      <div>
        {navigation.seedpublisher.loading.blocking && <div className='SeedPublisherProgress'>
          <Progress percent={navigation.seedpublisher.loading.progress}/>
        </div>}
        {navigation.seedpublisher.loading.blocking && <div className='SeedPublisherBlockLoading'>
          <Spinner name='double-bounce' />
        </div>}

          <div className="FSMapContainer">
            {navigation.seedpublisher.viewport.latitude && <MapGL
              {...navigation.seedpublisher.viewport}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              onViewportChange={(viewport) => {
                this.props.updatePublisherMapViewport({
                  ...viewport,
                  //height: navigation.seedpublisher.viewport.height
                })
              }}
              mapboxApiAccessToken='pk.eyJ1IjoiYWR2M2N0dXMiLCJhIjoiY2pkNmticjZrNTB5dTJ3bzF2dnQzZXA1MSJ9.sHLvu8If1_NerQ3ZXKixgA'
            >
              {(feature && feature.uuid) &&
                <Marker key={feature.uuid} latitude={feature.location.lat} longitude={feature.location.lng} offsetLeft={feature.marker.offsetLeft} offsetTop={feature.marker.offsetTop}>
                  <img alt='Marker' className={feature.marker.zoomClass} src={feature.marker.icon}/>
                </Marker>
              }
            </MapGL>}
            <div className='FSMapContainer__popover'>
              <div className='FSMapContainer__popoverContainer'>
                <div className='StepBox'>
                  {this.getSeedPublisherHeader()}
                </div>
                {(!mapUUID && !feature) && <div className='LocationStep'>
                  <div className='LocationStep__container'>
                    {writableMaps.length!==0 && <h5 className='LocationStep__description'>Select from exisiting infomap</h5>}
                    <MiniCardDeck maps={writableMaps} writableMaps={writableMaps} setMapUUID={(mapUUID) => {
                      setMapUUID(mapUUID);
                      //selectMap(mapUUID);

                      //check to see if there is a feature with this place id and map uuid
                      //if not, create the feature
                      console.log(featuresByPlaceId);
                      console.log(placeId);
                      console.log(featuresByPlaceId[placeId]);

                      var featuresByPlace = (featuresByPlaceId[placeId] || []);

                      if ( featuresByPlace.length===0 ) {
                        //make user create a feature from scratch
                        console.log(`Feature with placeId ${placeId} does not exist on any features on any of the maps`);
                      } else {
                        var featuresByMap = featuresByPlace.filter(f => f.mapUUID===mapUUID);
                        if ( featuresByMap.length===0 ) {
                          // this feature lives on another map, automatically create it on this new map
                          console.log(`Feature with placeId ${placeId} exists on another map`);

                          var featureOnAnotherMap = featuresByPlace[0];
                          var featureUUID = uuidv4();
                          createFeature({
                            ...featureOnAnotherMap,
                            mapUUID,
                            accountUUID,
                            uuid: featureUUID,
                            formattedAddress: featureOnAnotherMap.location.formatted_address,
                            lat: featureOnAnotherMap.location.lat,
                            lng: featureOnAnotherMap.location.lng,
                            placeID: featureOnAnotherMap.location.place_id,
                          })
                          setPlaceId(featureOnAnotherMap.location.place_id);
                          setFeatureUUID(featureUUID);

                        } else {
                          console.log(`Feature with placeId ${placeId} exists on the the selected map ${mapUUID}`);
                        }
                      }
                    }} mapUUID={mapUUID}/>
                  </div>
                </div>}
                {(mapUUID && !feature) && <div className='LocationStep'>
                  <div className='LocationStep__container'>
                    <h5 className='LocationStep__description'>Search a location</h5>
                    <div className='LocationStep__textField'>
                      {false && <input placeholder='800 North Broad Street, New Orleans, LA' onKeyPress={this.handleKeyPress} onChange={this.handleLocationOnChange} value={placeholderAddress}/>}
                      {placeholderAddress!=='' && <div className='LocationStep__textField_cta'>
                        <div className='button-box'>
                          <a className='button primary' onClick={this.handleLocationSet}>Set</a>
                        </div>
                      </div>}
                      <input placeholder='800 North Broad Street, New Orleans, LA' onChange={this.handleLocationOnChange} value={placeholderAddress}/>
                    </div>
                    <div className='LocationStep__textFieldResults__container'>
                      {mapSearchResults.length!==0 && <h6 className='LocationStep__textFieldResultsTitle'>Select location</h6>}
                      {mapSearchResults.length!==0 && <ul className='LocationStep__textFieldResults'>
                        {mapSearchResults.map((result) => {
                          return (
                            <li key={result.place_id} onClick={() => this.handleResultSelection(result)}>
                              <div key={result.place_id}>
                                {result.formatted_address}
                              </div>
                            </li>
                          )
                        })}
                      </ul>}
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>


          {(accountUUID&&mapUUID&&feature) && <div className='SeedPublisher'>
            <div className='SeedPublisher__header'>
              {navigation.seedpublisher.tabcontainer.file && <div className='SeedPublisher__header-container'>
                <h2>File Publisher</h2>
                <h5>Select a file from your device</h5>
              </div>}
              {navigation.seedpublisher.tabcontainer.link && <div className='SeedPublisher__header-container'>
                <h2>Link Publisher</h2>
                <h5>Enter the URL of the article</h5>
              </div>}
              {navigation.seedpublisher.tabcontainer.data && <div className='SeedPublisher__header-container'>
                <h2>Data Publisher</h2>
                <h5>Select a location type to add location-specific data</h5>
              </div>}
            </div>
            <div className={(accountUUID&&mapUUID&&feature) ? 'SeedPublisher__content active' : 'SeedPublisher__content'}>
              <div className='SeedPublisher__tabs'>
                {flagType==='all' && <div className='SeedPublisher__tabContainer'>
                  <div className={navigation.seedpublisher.tabcontainer.file ? 'SeedPublisher__tab SeedPublisher__tab--selected' : 'SeedPublisher__tab'} onClick={()=> setSeedPublisherTab({file: true, link: false, data: false})}>
                    <div className='SeedPublisher__tab-name'>
                      <span>File</span>
                    </div>
                  </div>
                  <div className={navigation.seedpublisher.tabcontainer.link ? 'SeedPublisher__tab SeedPublisher__tab--selected' : 'SeedPublisher__tab'} onClick={()=> setSeedPublisherTab({file: false, link: true, data: false})}>
                    <div className='SeedPublisher__tab-name'>
                      <span>Link</span>
                    </div>
                  </div>
                  <div className={navigation.seedpublisher.tabcontainer.data ? 'SeedPublisher__tab SeedPublisher__tab--selected' : 'SeedPublisher__tab'} onClick={()=> setSeedPublisherTab({file: false, link: false, data: true})}>
                    <div className='SeedPublisher__tab-name'>
                      <span>Data</span>
                    </div>
                  </div>
                </div>}
                <div className='SeedPublisher__content-item'>
                  {(() => {
                    if (navigation.seedpublisher.tabcontainer.file) {
                      return <FilePublisher onFilePublish={onFilePublish} onFilePublishSuccess={onFilePublishSuccess}/>
                    }
                    else if (navigation.seedpublisher.tabcontainer.link) {
                      return <LinkPublisher handlePublish={this.handlePublish} onSubmit={linkForm => this.setState({linkForm})}/>
                    }
                    else if (navigation.seedpublisher.tabcontainer.data) {
                      return <DataPublisher
                        locationTypes={locationTypes}
                        dataTypes={dataTypes}
                        mapType={mapType}
                        mapTypeKey={mapTypeKey}
                        setMapTypeKey={setMapTypeKey}
                        locationType={locationType}
                        locationTypeKey={locationTypeKey}
                        setLocationTypeKey={setLocationTypeKey}
                        dataType={dataType}
                        dataTypeKey={dataTypeKey}
                        setDataTypeKey={setDataTypeKey}
                        handlePublish={this.handlePublish}
                        onSubmit={dataForm => this.setState({dataForm})}
                      />
                    }
                    })()}
                </div>
              </div>
            </div>
          </div>}

        <div className='SeedPublisher__footer'>
          <div className='SeedPublisher__footer-item--desc'>
            <input
              name="allowProcessing"
              type="checkbox"
              checked={true}
              onChange={() => {}} />
            <label>
              Let Geotiller automatically process the files
            </label>
          </div>
          <div className='SeedPublisher__footer-item--cta'>
            <div className='button-box'>
              <a className='button secondary' onClick={this.handleCancel}>Cancel</a>
              <a className={savingDisabled ? 'button primary disabled' : 'button primary'} onClick={this.handlePublish}>Publish</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeedPublisher;
