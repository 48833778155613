

// percentage //1 decimal
// decimal //2 decimal
// number //with comma
// date_year
// full_date //06/11/2018
// currency_with_decimal
// currency_without_decimal
// string
// select
// textarea

import RealEstate__Shared from './mapTypes/RealEstate/shared'
import RealEstate__Retail from './mapTypes/RealEstate/Retail'
import RealEstate__Apartments from './mapTypes/RealEstate/Apartments'
import RealEstate__Hotel from './mapTypes/RealEstate/Hotel'
import RealEstate__Office from './mapTypes/RealEstate/Office'
import RealEstate__Industrial from './mapTypes/RealEstate/Industrial' //smoke stacks
import RealEstate__MixedUse from './mapTypes/RealEstate/MixedUse' //mixed-use
import RealEstate__Condo from './mapTypes/RealEstate/Condo' //mixed-use
//import RealEstate__HealthCare from './mapTypes/RealEstate/HealthCare'
import RealEstate__SingleFamily from './mapTypes/RealEstate/SingleFamily' //house
import RealEstate__Land from './mapTypes/RealEstate/Land' //vacant sign
import RealEstate__Generic from './mapTypes/RealEstate/Generic' //smoke stacks

import Travel__Shared from './mapTypes/Travel/shared'
import Travel__Hotel from './mapTypes/Travel/Hotel'
import Travel__Generic from './mapTypes/Travel/Generic'

const mapTypes = (
    state = {
      "Real Estate": {
        "Retail": RealEstate__Retail,
        "Hotel": RealEstate__Hotel,
        "Condo": RealEstate__Condo,
        "Mixed-Use": RealEstate__MixedUse,
        "Apartments": RealEstate__Apartments,
        "Industrial": RealEstate__Industrial,
        "Office": RealEstate__Office,
        "Land": RealEstate__Land,
        "Single Family": RealEstate__SingleFamily,
        "Generic": RealEstate__Generic,
        //"Healthcare": RealEstate__HealthCare,
        //"Mixed Use": RealEstate__Shared,
        //"Speciality": RealEstate__Shared,
        "Shared": RealEstate__Shared
      },
      "Travel": {
        "Hotel": Travel__Hotel,
        "Generic": Travel__Generic,
        "Shared": Travel__Shared
      }
    },
    action
  ) => {
  switch (action.type) {
    case 'SET_DATA_TYPE_FIELD':
      return {...state}
    default:
      return state
  }
}

export default mapTypes
