import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify';

class SignUp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      showVerification: false,
      loading: false,
      forgotPassword: null
    }
  }

  handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

  handleForgotPasswordSubmit = async event => {
		event.preventDefault();
		this.setState({ loading: true });

		try {
			const forgotPassword = await Auth.forgotPassword(this.state.email);
      console.log(forgotPassword);
      // forgotPassword.then((data) => {
      //   console.log('forgotPassword => ', data);
      // }).catch((err) => {
      //   console.log('forgotPassword => ', err);
      // })
			this.setState({
				forgotPassword,
        showVerification:true
			});
      console.log(this.state);
		} catch (e) {
			alert(e.message);
		}

		this.setState({ loading: false });
	};

  handleConfirmationSubmit = async event => {
		event.preventDefault();

    const { email, password, confirmationCode } = this.state;
    const { setAccountUUID, setToken } = this.props;

		this.setState({ loading: true });

		try {
			await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      // forgotPasswordSubmit.then((data) => {
      //   console.log('forgotPasswordSubmit data => ', data);
      // }).catch((err) => {
      //   console.log('forgotPasswordSubmit err => ', err);
      // })
			const user = await Auth.signIn(email, password);
      console.log(user);
      setAccountUUID(user.username);//user.username);
      setToken(await Auth.currentSession());
		} catch (e) {
			alert(e.message);
			this.setState({ loading: false });
		}
	};

  componentDidUpdate(prevProps) {
    const { session, history } = prevProps;
    const nextProps = this.props;
    if (nextProps.session.token !== session.token) {
      if ( nextProps.session.token) {
        nextProps.history.push('/desktop');
      }
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { session, history } = this.props;
  //   if (nextProps.session.token !== session.token) {
  //     if ( nextProps.session.token) {
  //       history.push('/desktop');
  //     }
  //   }
  // }

  render () {
    return [
      <div key='Signup' className="PasswordReset">
        <h5>Password Reset</h5>
        {
          !this.state.showVerification ? (
            <form onSubmit={this.handleForgotPasswordSubmit}>
              <div>
                <input
                  id='email'
                  value={this.state.email}
                  placeholder='Email'
                  type='email'
                  onChange={this.handleChange} />
              </div>
              <div>
                <input className='loginButton' type='submit' value='Submit'/>
              </div>
            </form>
          ) : (
            <form onSubmit={this.handleConfirmationSubmit}>
              <div>
                <input
                  id='confirmationCode'
                  value={this.state.confirmationCode}
                  onChange={this.handleChange}
                  placeholder='Verification code' />
                <input
                  id='password'
                  value={this.state.password}
                  placeholder='New Password'
                  type='password'
                  minLength={6}
                  onChange={this.handleChange} />
              </div>
              <div>
                <input className='loginButton' type='submit' value='Verify'/>
              </div>
            </form>
          )
        }
      </div>,
      <div key='PasswordReset-footer' className="PasswordReset-footer">
        Dont have an account? <Link to="/users/sign_up">Sign up</Link>
      </div>
    ]
  }
}

export default SignUp
