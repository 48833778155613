import React, { Component } from 'react'
//import { authenticateUser } from './Cognito'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify';

const Spinner = require('react-spinkit');

class SignIn extends Component {
  constructor (props) {
    super(props);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleSigninSubmit = this.handleSigninSubmit.bind(this);

    this.state = {
      email: this.props.session.account.email ? this.props.session.account.email : '',
      password: '',
      loading: false,
    }
  }

  changeEmail (e) {
    this.setState({ email: e.target.value.toLowerCase() })
  }

  changePassword (e) {
    this.setState({ password: e.target.value })
  }

  handleSigninSubmit = async event => {
    event.preventDefault();

    const { email, password } = this.state;
    const { setAccountType, setAccountUUID, setToken } = this.props;

		this.setState({ loading: true });

		try {
			const user = await Auth.signIn(email, password);
      //console.log(user);
      setAccountUUID(user.username);//user.username);
      setAccountType('regular');
      const authenticateUser = await Auth.currentAuthenticatedUser()
      setToken(authenticateUser.signInUserSession);
      window.location.reload();
		} catch (e) {
			alert(e.message);
			this.setState({ loading: false });
		}
	};

  componentDidUpdate(prevProps, prevState) {
    const { session, history, location } = this.props;
    if (prevProps.session.token !== session.token) {
      if (session.token) {
        var path = '/desktop';
        if ( location && location.pathname && location.pathname!=='/users/sign_in' && !location.pathname.includes('desktop') ) path = location
        history.push(path);
      }
    }
  }


  render () {
    const { history, session: { tenant: { title } } } = this.props;
    return [
      <div key='Signin' className="Signin">
        {title ? <h5>{title}</h5> : <h5>Sign In</h5>}
        <form onSubmit={this.handleSigninSubmit}>
          <div className='input-box'>
            <input
              id="email"
              value={this.state.email}
              placeholder='Email Address'
              type='text'
              onChange={this.changeEmail} />
          </div>
          <div className='input-box'>
            <input
              id="password"
              value={this.state.password}
              placeholder='Password'
              type='password'
              minLength={6}
              onChange={this.changePassword} />
          </div>
          <div className='link-box'>
            <a onClick={e => history.push('/users/password')}><span>I forgot my password</span></a>
          </div>
          <div className='button-box'>
            <div className={this.state.loading ? 'loginButton disabled' : 'loginButton'}>
              {!this.state.loading && <input type='submit' />}
              {this.state.loading && <div className='SpinnerBox'><Spinner name='ball-clip-rotate' color='white'/></div>}
            </div>
          </div>
        </form>
      </div>,
      <div key='Signin-footer' className="Signin-footer">
        <Link to="/users/sign_up">Create account</Link>
      </div>
    ]
  }
}

export default SignIn
