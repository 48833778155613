
import React, {Component} from 'react'
//import PropTypes from 'prop-types'
import './BookmarkSearch.css';

import searchIcon from './../../../assets/search.svg';

class BookmarkSearch extends Component {

  render() {
    return (
      <div className='BookmarkSearch'>
        <div className='BookmarkSearch__inputContainer'>
          <img alt='Search' src={searchIcon} />
          <input type='text' placeholder='Search bookmarks and locations' />
        </div>
      </div>
    );
  }
};

export default BookmarkSearch
