import React, {Component} from 'react'

import MapList from './MapList'
import FeatureList from './FeatureList'
import FeatureView from './FeatureView'

import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import appConfig from './../../config'

import './Dashboard.css';

class Dashboard extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log('Dashboard componentDidMount');

    const {
      mapUUID,
      featureUUID
    } = this.props.match.params;

    const {
      setMapUUID,
      deselectAllMaps,
      unsetPlaceId
    } = this.props;

    //deselectAllMaps();
    //unsetPlaceId();

    console.log('mapUUID => ', mapUUID);

    if (mapUUID) setMapUUID(mapUUID);
  }

  render() {
    const {
      mapUUID,
      featureUUID
    } = this.props.match.params;
    const {
      //openMap,
      setMapUUID,
      filteredMaps,
      setCardDeckTab,
      navigation,
      selectedMap,
      filteredFeatures,
      setFeatureUUID,
      history,
      match
    } = this.props;
    return (
      <div>
        <NotificationContainer enterTimeout={10} leaveTimeout={10} />
        <div className='Home'>
          <div className='Home__nav'>
          </div>
          {!mapUUID && !featureUUID && <MapList filteredMaps={filteredMaps} setMapUUID={setMapUUID} setCardDeckTab={setCardDeckTab} navigation={navigation} history={history}/>}
          {mapUUID && !featureUUID && <FeatureList filteredFeatures={filteredFeatures} map={selectedMap} setFeatureUUID={setFeatureUUID} match={match}/>}
          {mapUUID && featureUUID && <FeatureView
            account={this.props.account}
            accountUUID={this.props.accountUUID}
            mapUUID={this.props.console.mapUUID}
            setMapUUID={this.props.setMapUUID}
            map={this.props.selectedMap}
            place={this.props.place}
            placeId={this.props.console.placeId}
            placeDocumentsByType={this.props.placeDocumentsByType}
            document={this.props.console.document}
            toggleDocumentAnalysis={this.props.toggleDocumentAnalysis}
            toggleSeedPublisher={this.props.toggleSeedPublisher}
            viewDocument={this.props.viewDocument}
            toggleDocumentConfigDropwdown={this.props.toggleDocumentConfigDropwdown}
            trashDocument={this.props.trashDocument}
            updateDocumentAssetType={this.props.updateDocumentAssetType}
            updateDocumentFileType={this.props.updateDocumentFileType}
            setDocPaneMapView={this.props.setDocPaneMapView}
            viewport={this.props.console.viewport}
            createBookmark={this.props.createBookmark}
            writableMaps={this.props.writableMaps}
            history={this.props.history}
            navigation={this.props.navigation}
            updateDocumentTitle={this.props.updateDocumentTitle}
            deleteFeature={this.props.deleteFeature}
            mapType={this.props.mapType}
            statuses={this.props.statuses}
            onDataPublish={this.props.onDataPublish}
            statusDataType={this.props.statusDataType}
            featureUUID={this.props.console.featureUUID}
            lifecycleDataType={this.props.lifecycleDataType}
            locationNameDataType={this.props.locationNameDataType}
            updateViewport={this.props.updateViewport}
            places={this.props.places}
            mapStyle={this.props.mapStyle}
            setPlaceMapHeight={this.props.setPlaceMapHeight}
          />}
        </div>
      </div>
    );
  }
}

export default Dashboard;
