
import React, {Component} from 'react'

//import searchIcon from './../../assets/search.svg';

import './Profile.css';

class Profile extends Component {

  constructor(props) {
    super(props);
    this.handleSave = this._handleSave.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  _handleSave() {

  }

  render() {
    const {
      toggleProfile,
      account
    } = this.props;

    return (
      <div className='Profile'>
        <div className='Profile__content'>
          <div className='Profile__content--body'>
            <div className='Profile__content--input'>
              <span>Handle</span>
              <input type="input" className="inline-input" value={account.uuid}/>
            </div>
            <div className='Profile__content--input'>
              <span>First Name</span>
              <input type="input" className="inline-input" value={account.firstName}/>
            </div>
            <div className='Profile__content--input'>
              <span>Last Name</span>
              <input type="input" className="inline-input" value={account.lastName}/>
            </div>
          </div>
          <div className='Profile__content--cta'>
            <div className='button-box'>
              <a className='button primary' onClick={this.handleSave}>Save</a>
              <a className='button cancel' onClick={toggleProfile}>Cancel</a>
            </div>
          </div>
        </div>

      </div>
    );
  }
};

export default Profile
