export default {
  "dataTypes": [{
      "key": "single_family_type",
      "title": "Single-Family Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Detached", "Attached", "Townhome", "Manufactured"]
      }]
    },
    {
      "key": "bed_bath",
      "title": "Bed/Bath",
      "fields": [{
        "key": "bed_bath",
        "title": "Bed/Bath",
        "type": "select",
        "choices": ["0x1", "1x1", "2x1", "2x2", "3x2", "3x3", "3x4", "4x2", "4x3", "4x4", "5x5"]
      }]
    },
    {
      "key": "lease_comp",
      "title": "Lease Comp",
      "fields": [{
        "key": "lease_date",
        "title": "Lease Date",
        "type": "string"
      }, {
        "key": "lease_rate",
        "title": "Lease Rate (gross)",
        "type": "currency_without_decimal"
      }, {
        "key": "lease_rate_psf",
        "title": "Lease Rate PSF",
        "type": "currency_with_decimal"
      }, {
        "key": "lease_term",
        "title": "Lease Term (months)",
        "type ": "number_with_comma"
      }, {
        "key": "rent_concession",
        "title": "Rent Concession (months)",
        "type ": "number_with_comma"
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      }, {
        "key": "sale_price_per_sf",
        "title": "Sale Price PSF",
        "type ": "currency_without_decimal"
      }, {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string"
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "buyer_agent",
        "title": "Buyer Agent",
        "type ": "string"
      }, {
        "key": "seller_agent",
        "title": "Seller Agent",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
