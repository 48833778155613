
import React, {Component} from 'react';

import MapGL, {Marker, StaticMap, Popup, NavigationControl, Source, Layer} from 'react-map-gl';

import MapSearch from './MapSearch'
import PropertyPopup from './../PropertyPopup'
import PropertyMiniPopup from './../PropertyMiniPopup'
import MiniCardDeck from './../MiniCardDeck'

import { Pin, Group } from './FeatureMapPin';
import Cluster from './FeatureMapCluster';

import config from './../../config'

import './FeatureMap.css';

const geocoder = require('geocoder');
const uuidv4 = require('uuid/v4');

const style = {
  width: '20px',
  height: '20px',
  color: '#fff',
  background: '#1978c8',
  borderRadius: '20px',
  textAlign: 'center'
};

class FeatureMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newPlace: null,
      hoverPlace: null
    }
    this.handleNewPlace = (place) => this._handleNewPlace(place);
    this.handleMarkerMouseOver = (place) => this._handleMarkerMouseOver(place);
    this.handleMarkerMouseOut = () => this._handleMarkerMouseOut();
  }

  _handleNewPlace(place) {
    //this.props.setMapUUID(null);
    this.setState({newPlace: (place ? {
      place_id: place.place_id,
      location: place.geometry.location,
      title: place.formatted_address
    } : null)})
  }

  _handleMarkerMouseOver(hoverPlace) {
    const { place } = this.props;
    if (place && !place.location.lat && !place.location.lng) {
      this.setState({hoverPlace});
    }
  }

  _handleMarkerMouseOut() {
    this.setState({hoverPlace: null});
  }

  render() {
    const {
      viewport,
      updateViewport,
      place,
      places,
      //feature,
      //features,
      createFeature,
      //featuresByPlaceId,
      //setDocPaneListView,
      navigation,
      //setFeatureUUID,
      //unsetPlaceId,
      //unsetFeatureUUID,
      map,
      maps,
      writableMaps,
      mapUUID,
      setMapUUID,
      //unsetMapUUID,
      setMapSearchResults,
      mapSearchResults,
      setFeatureAnimate,
      history,
      accountUUID,
      getUserLocation,
      mapStyle,
      invertedMapStyle,
      updateMapStyle,
      toggleSubnav,
      setDocPaneListView,
      isRegularAccountType,
      tenant,
    } = this.props;

    const { newPlace, hoverPlace } = this.state;

    return (
      <div>
        {isRegularAccountType && <MapSearch 
          map={map}
          handleNewPlace={this.handleNewPlace}
          getUserLocation={getUserLocation}
          setFeatureAnimate={setFeatureAnimate}
          writableMaps={writableMaps}
          maps={maps}
          mapUUID={mapUUID}
          navigation={navigation}
          setMapUUID={setMapUUID}
          createFeature={createFeature}
          setMapSearchResults={setMapSearchResults}
          mapSearchResults={mapSearchResults}
        />}
        {(viewport && viewport.longitude && viewport.latitude && invertedMapStyle) && <div className='MapStyleSelect'>
          <a onClick={() => updateMapStyle(invertedMapStyle)}>
            <StaticMap
              mapStyle={invertedMapStyle}
              zoom={viewport.zoom-4}
              height={72}
              width={72}
              latitude={viewport.latitude}
              longitude={viewport.longitude}
              mapboxApiAccessToken={config.MapBox}
            >
            </StaticMap>
          </a>
        </div>}
        {(viewport && viewport.longitude && viewport.latitude) && <MapGL
          latitude={viewport.latitude}
          longitude={viewport.longitude}
          zoom={viewport.zoom}
          minZoom={viewport.minZoom}
          width={viewport.width}
          height={viewport.height}
          bounds={viewport.bounds}
          pitch={viewport.pitch}
          maxPitch={viewport.maxPitch}
          bearing={viewport.bearing}
          altitude={viewport.altitude}
          maxZoom={viewport.maxZoom}
          minPitch={viewport.minPitch}
          onViewportChange={(viewport) => {
            if (this.mapRef && this.mapRef.getMap()) {
              updateViewport({
                ...viewport,
                height: window.innerHeight,
                bounds: this.mapRef.getMap().getBounds()
              })
            }
          }}
          ref={ map => this.mapRef = map }
          style={{
            zIndex: 1
          }}
          onClick={(e) => {
            //console.log(e);
            //console.log(viewport);
            //console.log(writableMaps);
            if (isRegularAccountType) {
              if (e.pointerType && (e.pointerType==='mouse'||e.pointerType==='touch') && viewport.zoom>12) {
                if (e.type && e.type==='click' && writableMaps.length>0) {

                  if (e.target.className!=='mapboxgl-popup-close-button') {
                    var lat = e.lngLat[1];
                    var lng = e.lngLat[0];
                    geocoder.geocode(`${lat},${lng}`, ( err, data ) => {
                      if (data.results[0]) {
                        this.handleNewPlace(data.results[0])
                      }
                    }, {key: 'AIzaSyA-ZsQ_cNv0oH_v_1EqCGHnCJFNNe784Uk'})
                  }

                }
              }
            }
          }}
          reuseMaps={true}
          mapStyle={mapStyle}
          mapboxApiAccessToken={config.MapBox}
          minZoom={4.5}
        >
          {(newPlace && newPlace.location.lat && newPlace.location.lng) &&
            <Popup tipSize={5}
              anchor="top"
              longitude={newPlace.location.lng}
              latitude={newPlace.location.lat}
              offsetLeft={16}
              offsetTop={10}
              className={navigation.map.full ? 'MapPopup MapPopup--full' : 'MapPopup'}
              style={{
                'width': '600px'
              }}
              closeButton={false}>
              <div>
                <button className='mapboxgl-popup-close-button' onClick={() => {
                  this.handleNewPlace(null);
                }}>x</button>
              </div>
              <div className='MapSelectPopup'>
                <div className='MapSelectPopup__header'>
                  <h2>{newPlace.title}</h2>
                </div>
                <div className='MapSelectPopup__content'>
                  {maps.length===0 && <p>No Projects <a onClick={toggleSubnav}>Select an Project</a></p>}
                  {maps.length!==0 && <p>Selected Project:</p>}
                  {maps.length!==0 && <MiniCardDeck maps={maps} writableMaps={writableMaps} setMapUUID={setMapUUID} mapUUID={mapUUID} size='sm' />}
                </div>
                {isRegularAccountType && <div className='MapSelectPopup__footer'>
                  <div className='MapSelectPopup__cta'>
                    <button disabled={!mapUUID} onClick={(e) => {
                      setMapUUID(mapUUID);
                      createFeature({
                        accountUUID: accountUUID,
                        mapUUID: mapUUID,
                        uuid: uuidv4(),
                        title: newPlace.title,
                        formattedAddress: newPlace.title,
                        lat: newPlace.location.lat,
                        lng: newPlace.location.lng,
                        placeID: newPlace.place_id,
                        featureType: 'PROPERTY'
                      })
                      setDocPaneListView();
                      history.push(`/desktop/${accountUUID}/${newPlace.place_id}/docpane`)
                    }}>Plant Flag</button>
                  </div>
                </div>}
              </div>
            </Popup>
          }
          {(viewport.width && viewport.width > 700 && place && place.location.lat && place.location.lng) &&
            <Popup tipSize={5}
              anchor="top"
              longitude={place.location.lng}
              latitude={place.location.lat}
              offsetLeft={place.popup.offsetLeft}
              offsetTop={place.popup.offsetTop}
              className={navigation.map.full ? 'MapPopup MapPopup--full' : 'MapPopup'}
              closeButton={false}>
              <div>
                <button className='mapboxgl-popup-close-button' onClick={() => {
                  history.push(`/desktop/${accountUUID}`);
                }}>x</button>
              </div>
              <PropertyPopup
                place={place}
                viewButton={true}
                statsPanel={true}
                isRegularAccountType={isRegularAccountType}
                onViewClick={() => {
                  setDocPaneListView();
                  history.push(`/desktop/${accountUUID}/${place.id}/docpane`);
                }}
              />
            </Popup>
          }
          {(hoverPlace) &&
            <Popup tipSize={5}
              anchor="top"
              longitude={hoverPlace.location.lng}
              latitude={hoverPlace.location.lat}
              offsetLeft={hoverPlace.popup.offsetLeft}
              offsetTop={hoverPlace.popup.offsetTop}
              className={navigation.map.full ? 'MapPopup MapPopup--full' : 'MapPopup'}
              closeButton={false}>
              <PropertyMiniPopup
                place={hoverPlace}
              />
            </Popup>
          }
          {tenant.mapClusters && this.mapRef && (
            <Cluster
              map={this.mapRef.getMap()}
              radius={20}
              extent={512}
              nodeSize={40}
              element={clusterProps => (
                <Group onViewportChange={(viewport) => {
                  if (this.mapRef && this.mapRef.getMap()) {
                    updateViewport({
                      ...viewport,
                      height: window.innerHeight,
                      bounds: this.mapRef.getMap().getBounds()
                    })
                  }
                }} {...clusterProps} />
              )}
            >
              {/* 
                each Marker MUST have a 
                uniqe key other wise cluster will not rerender on change
              */}
              {places.map((place, i) => (
                <Pin
                  key={place.location.place_id}
                  longitude={place.location.lng}
                  latitude={place.location.lat}
                />
              ))}
            </Cluster>
          )}
          {!tenant.mapClusters && places.map((place) => {
              var className = `MasterIcon ${place.marker.icon}`;
              if (place.targetProperty) className += ' TargetProperty';
              return (
                <Marker key={place.location.place_id} latitude={place.location.lat} longitude={place.location.lng} offsetLeft={place.marker.offsetLeft} offsetTop={place.marker.offsetTop}>
                  <div className={className} onMouseOver={(e) => this.handleMarkerMouseOver(place)} onMouseOut={(e) => this.handleMarkerMouseOut()} onClick={(e) => {
                    this.handleMarkerMouseOut();
                    history.push(`/desktop/${accountUUID}/${place.id}/map`);
                  }}/>
                </Marker>
              )
            }
          )}
          {newPlace && (
            <Marker key={newPlace.place_id} latitude={newPlace.location.lat} longitude={newPlace.location.lng} offsetLeft={5} offsetTop={-40}>
              <div className='MasterIcon FlagIcon' />
            </Marker>
          )}
          <NavigationControl style={{ zIndex: 9999}} showCompass={false} onViewportChange={(viewport) => {
            updateViewport({
              ...viewport,
              //width: window.innerWidth-navigation.sidebar.width,
              height: window.innerHeight
            })
          }} />
        </MapGL>}
        {(viewport.width && viewport.width <= 700 && place && place.location.lat && place.location.lng) &&
            <div className='MapPopup__fixed'>
              <div>
                <button className='mapboxgl-popup-close-button' onClick={() => {
                  history.push(`/desktop/${accountUUID}`);
                }}>x</button>
              </div>
              <PropertyPopup
                place={place}
                viewButton={true}
                statsPanel={true}
                isRegularAccountType={isRegularAccountType}
                onViewClick={() => {
                  setDocPaneListView();
                  history.push(`/desktop/${accountUUID}/${place.id}/docpane`);
                }}
              />
            </div>
          }
      </div>
    );
  };
}

export default FeatureMap
