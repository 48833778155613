
import React, {Component} from 'react'

import MapGL from 'react-map-gl';

import InfoMapSettingsAddUser from './InfoMapSettingsAddUser'

//import { Link } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert'; // Import

import searchIcon from './../../assets/search.svg';
import closeIcon from './../../assets/close-1.svg';
import colorMapIcon from './../../assets/color-map.svg';
//import partitionIcon from './../../assets/container.svg';
import partitionIcon from './../../assets/color-map.svg';

//import TrashIcon from './../../assets/zondicons/trash.svg'

import axios from 'axios';

import './InfoMapSettings.css';

class InfoMapSettings extends Component {

  constructor(props) {
    super(props);

    //const recentContacts = this.props.account.contacts.recent;

    const { toggleHamburger, map } = this.props;

    this.state = {
      title: (map ? map.title : ''),
      viewport: {
        latitude: 39.017437,
        longitude: -97.815787,
        zoom: 4,
        height: window.innerHeight,
        width: window.innerWidth
      },
      searchTerm: "", //email search text input state
      searching: false, //bool to show error message
      searchFail: false,
      // users: recentContacts.map((user) => {
      //   return { ...user, accountUUID: user.uuid, role: "", active: false }
      // }),
      users: [],
      roles: [
        //{ key: "owner", title: "Owner"},
        { key: "member", title: "Member"},
        { key: "follower", title: "Follower"}
      ],
      mapTypes: [{
        key: 'real_estate',
        title: 'Real Estate'
      },{
        key: 'travel',
        title: 'Travel'
      }],
      selectedMapType: 'real_estate',
      permissions: ((map && map.permissions) ? map.permissions : []),
      displayDelete: false
    };

    toggleHamburger(false);

    this.handleMapPermissionRemoval = (email) => this._handleMapPermissionRemoval(email);
    this.handleSearch = (e) => this._handleSearch(e);
    this.handleSearchTermKeyPress = (e) => this._handleSearchTermKeyPress(e);
    this.handleMapTitleKeyPress = (e) => this._handleMapTitleKeyPress(e);
    this.handleContactSelection = (contact) => this._handleContactSelection(contact);
    this.handleMapDelete = () => this._handleMapDelete();
    this.resetContactSelection = () => this._resetContactSelection();
    this.handleContactRoleSelection = (contact, roleKey) => this._handleContactRoleSelection(contact, roleKey);
    this.handleMapTypeSelection = (mapTypeKey) => this._handleMapTypeSelection(mapTypeKey);
    this.handleUpdateMap = (options={updatePermissions: true}) => this._handleUpdateMap(options);
    this.handleInviteUser = () => this._handleInviteUser()
  }

  _handleSearch = () => {
    const searchTerm = this.state.searchTerm; //e.target.value;
    this.setState({searching: true});
    if (searchTerm) {
      var state = this.state;
      axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/accounts`,{
        params: {
          email: searchTerm
        }
      })
      .then(response => response.data)
      .then(account => {
        state.users = [{
          accountUUID: account.uuid,
          email: account.email,
          firstName: account.firstName,
          lastName: account.lastName,
          role: "",
          status: "",
          active: false
        }];
        this.setState(state);
      })
      .catch((err) => {
        console.log(err);
        this.setState({users: [], searchFail: true});
      })
    }
  }

  _handleInviteUser = () => {
    const { session: { tenant: { nomenclature: { partition: { singular: Partition, plural: Partitions }}}} } = this.props;
    var searchTerm = this.state.searchTerm
    var user = {
      email: searchTerm,
      role: "member",
      status: "pending_accept"
    };
    confirmAlert({
      title: 'Confirm subscription',
      message: `Are you sure you want to invite ${user.email} to have read-write privledges on this ${Partition.toLowerCase()}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({
              searchTerm: "",
              searching: false,
              searchFail: false,
              users: [],
              permissions: this.state.permissions.concat([user])
            });
            //setTimeout(() => this.handleUpdateMap(), 400);
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting handleInviteUser")}
        }
      ]
    })

  }

  _handleSearchTermKeyPress = (e) => {
    let searchTerm = e.target.value;
    searchTerm = searchTerm.replace(/\s/g, "");
    this.setState({searchTerm, searching: false, searchFail: false});
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  }

  _handleUpdateMap = (options) => {
    console.log('%cUpdating map', 'color: green; font-weight: bold');
    console.log(options);
    const { map, updateMap } = this.props;
    const {
      viewport,
      title,
      permissions,
    } = this.state;
    const secret = true;
    console.log({permissions});
    let updatedMap = {
      ...map,
      title,
      secret,
      default: {
        lat: viewport.latitude,
        lng: viewport.longitude,
        zoom: viewport.zoom
      }
    };
    if (options.updatePermissions) {
      console.log("UPDATING PERMISSIONS!");
      updatedMap.permissions = permissions;
    }
    updateMap(updatedMap);
  }

  _handleMapPermissionRemoval = (email) => {
    console.log("Removing => ", email);
    var permissions = this.state.permissions.filter((user) => user.email!=email).map((user) => user);
    console.log({permissions});
    this.setState({permissions: permissions});
    console.log(this.state.permissions);
  }

  _handleMapTypeSelection = (mapTypeKey) => {
    this.setState({
      selectedMapType: mapTypeKey
    });
  }

  _handleContactRoleSelection = (contact, roleKey) => {
    //var role = this.state.roles.find((role) => role.key==role.roleKey)
    this.setState({
      permissions: this.state.permissions.map((user) => {
        if (user.email===contact.email) {
          user.role = roleKey;
        }
        return user;
      })
    });
  }

  _handleContactSelection = (contact) => {
    this.setState({
      users: this.state.users.map((user) => {
        if (user.email===contact.email) user.active = !user.active;
        return user;
      }),
      permissions: this.state.permissions.concat([{
        ...contact,
        role: "member"
      }])
    });
  }

  _resetContactSelection = () => {
    this.setState({
      users: this.state.users.map((user) => {
        return {
          ...user,
          active: false
        }
      })
    })
  }

  _handleMapTitleKeyPress = (e) => {
    this.setState({title: e.target.value});
  }

  // _handleMapDelete = () => {
  //   const { map, trashMap, toggleInfomapEditor } = this.props;
  //   this.setState({displayDelete: false});
  //   trashMap(map);
  //   toggleInfomapEditor();
  // }

  _handleMapDelete = () => {
    const { map, trashMap, toggleInfomapEditor, history, session: { tenant: { nomenclature: { partition: { singular: Partition, plural: Partitions }}}} } = this.props;
    confirmAlert({
      title: 'Confirm delete',
      message: `Are you sure you want to delete this ${Partition.toLowerCase()} and all of it's associated features, links, files, and data?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            trashMap(map);
            toggleInfomapEditor();
            history.push(`/desktop`);
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting deleteMap =>")}
        }
      ]
    })
  }


  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.map.permissions!=this.props.map.permissions) {
  //     console.log("Map Changed");
  //     this.setState({
  //       permissions: this.props.map.permissions
  //     })
  //   }
  // }

  componentDidMount() {
    const { map } = this.props;

    if (map) {
      this.setState({
        viewport: {
          ...this.state.viewport,
          latitude: map.default.lat,
          longitude: map.default.lng,
          zoom: map.default.zoom
        }
      })
    }
  }

  componentWillUnmount() {
    const { toggleHamburger } = this.props;
    toggleHamburger(true);
  }

  render() {
    const {
      //map,
      //updateMap,
      //resetMaps,
      getMaps,
      toggleInfomapEditor,
      //account,
      session: { tenant: { nomenclature: { partition: { singular: Partition, plural: Partitions }}}}
    } = this.props;
    const {
      viewport,
      title,
      searching,
      searchTerm,
      searchFail,
      users,
      roles,
      permissions,
      //displayDelete,
      mapTypes,
      selectedMapType,
    } = this.state;

    //const activeUsers = users.filter((user) => user.active);
    //const permissions = map.permissions.concat(activeUsers);
    //const secret = true;

    return (
      <div className='InfoMapSettings'>
        <div className='InfoMapSettings__content'>
          <div className='InfoMapSettings__content-header'>
            <img alt='Map Title' src={partitionIcon}/>
            <h3>{Partition} Settings</h3>
          </div>
          <div className='InfoMapSettings__content-item'>
            <h5>{Partition} title</h5>
            <div className='InfoMapSettings__content-item-input'>
              <input className='dark' type='text' value={title} onChange={this.handleMapTitleKeyPress} />
            </div>
          </div>
          {false && <div className='InfoMapSettings__content-item'>
            <h5>Map type</h5>
            <p>Each {Partition.toLowerCase()} has an underlying type that gives you certain features pertinent to the selected industry.</p>
            <div className='InfoMapSettings__content-item-right'>
              <select value={selectedMapType} onChange={(e) => this.handleMapTypeSelection(e.target.value)}>
                {mapTypes.map((mapType) => {
                  return <option key={mapType.key} value={mapType.key}>{mapType.title}</option>
                })}
              </select>
            </div>
          </div>}
          <div className='InfoMapSettings__content-item'>
            <h5>Add members</h5>
            <p>Add other Geotiller users as members (with editing rights) to this {Partition.toLowerCase()}</p>
            <div className='InfoMapSettings__content-item-input'>
              <div className='UserSelection'>
                <div className='UserSelection__search'>
                  <div className='UserSelection__search-container'>
                    <img alt='Search' src={searchIcon} />
                    <input placeholder='Search user by email' value={searchTerm} onChange={this.handleSearchTermKeyPress} onKeyPress={this.handleSearchTermKeyPress}/>
                    <button onClick={this.handleSearch}>Search</button>
                  </div>
                </div>
                <div className='UserSelection__content'>
                  <div className='UserSelection__content-items'>
                    {searching && searchFail && <div className='UserSelection__content-item--fail'>
                      <div className='UserSelection__content-item-name'>
                        <span>No user found.</span>
                      </div>
                      <div className='UserSelection__content-item-invite'>
                        <button onClick={this.handleInviteUser}>Invite {searchTerm}</button>
                      </div>
                    </div>}
                    {users.map((user, index) => {
                      return (
                        <div key={index} className='UserSelection__content-item' onClick={() => this.handleContactSelection(user)}>
                          <div className={user.active ? 'UserSelection__content-item-accessory UserSelection__content-item-accessory--active': 'UserSelection__content-item-accessory'}>
                          </div>
                          <div className='UserSelection__content-item-name'>
                            {false && <span>{user.firstName} {user.lastName}</span>}
                            <span>{user.email}</span>
                          </div>
                          <div className='UserSelection__content-item-title'>
                            <span>{user.status}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {permissions.filter(p => p.role!='follower').length > 0 && <div className='UserSelection__footer'>
                  <div className='UserSelection__footer-container'>
                    <div className='UserSelection__footer-header'>
                      <h3>{Partition} Members</h3>
                    </div>
                    <div className='UserSelection__footer-items'>
                      {permissions.filter(p => p.role!='follower').map((user, index) => {
                        return (
                          <div key={index} className='UserSelection__footer-item'>
                            <div className='UserSelection__footer-item-name'>
                              {false && <span>{user.firstName} {user.lastName}</span>}
                              <span>{user.email}</span>
                            </div>
                            {user.role!=='owner' && <div className='UserSelection__footer-item-select'>
                              <select value={user.role} onChange={(e) => this.handleContactRoleSelection(user, e.target.value)}>
                                {roles.map((role) => {
                                  return <option key={role.key} value={role.key}>{role.title}</option>
                                })}
                              </select>
                            </div>}
                            {user.role!=='owner' && <div className='UserSelection__footer-item-close' onClick={() => {
                              this.handleMapPermissionRemoval(user.email);
                              //this.handleUpdateMap();
                            }}>
                              <img alt='Close' src={closeIcon}/>
                            </div>}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
          {permissions.length > 0 && <InfoMapSettingsAddUser
            header="Add subscriber"
            subHeader={`Add subscribers to this ${Partition.toLowerCase()} with read-only privledges`}
            userType="Subscribers"
            users={permissions.filter(p => p.role=='follower')}
            onUserRemove={(user) => {
              console.log("InfoMapSettings: onUserRemovehandler");
              console.log({user});
              this.handleMapPermissionRemoval(user.email);
              //this.handleUpdateMap();
            }}
            onUserInvite={(user) => {
              console.log("InfoMapSettings: onUserInvite handler");
              console.log({user});
              this.setState({permissions: this.state.permissions.concat([user])});
              //setTimeout(() => this.handleUpdateMap(), 400);
            }}
            onUserConfirm={(user) => {
              console.log("InfoMapSettings: onUserConfirm handler");
              console.log({user});
              this.setState({permissions: this.state.permissions.map((permission) => {
                let updateUserPermission = permission;
                if (updateUserPermission.email===user.email) {
                  return {
                    ...updateUserPermission,
                    ...user
                  }
                }
                return updateUserPermission;
              })});
              //this.handleUpdateMap();
            }}
            onUserSelection={(user) => {
              console.log("InfoMapSettings: onUserSelection handler");
              console.log({user});
              this.setState({permissions: this.state.permissions.concat([user])});
              //this.handleUpdateMap();
            }}
          />}
          <div className='InfoMapSettings__content-item'>
            <h5>Delete {Partition}</h5>
            <p>Permanently delete this {Partition.toLowerCase()} from this account and all member accounts</p>
            <div className='InfoMapSettings__content-item-input'>
              <div className='button-box'>
                <a className='button vivid1' onClick={this.handleMapDelete}>Delete</a>
              </div>
            </div>
          </div>
        </div>
        <div className='InfoMapSettings__footer'>
          <div className='InfoMapSettings__footer-item--cta'>
            <div className='button-box'>
              <a className='button secondary' onClick={() => toggleInfomapEditor()}>Cancel</a>
              <a className='button primary' onClick={() => {
                this.handleUpdateMap({updatePermissions: true});
                toggleInfomapEditor();
              }}>Submit</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default InfoMapSettings
