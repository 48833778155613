export default {
  "dataTypes": [{
      "key": "for_sale_details",
      "title": "For Sale Details",
      "fields": [{
        "key": "ask_whisper_price",
        "title": "Ask/Whisper Price",
        "type ": "currency_without_decimal"
      },{
        "key": "price_psf_unit_key",
        "title": "Price PSF/Unit/Key",
        "type ": "currency_without_decimal"
      },{
        "key": "square_foot_Units_Keys",
        "title": "SF/Units/Keys",
        "type ": "number_with_comma"
      },{
        "key": "in_place_cap_rate",
        "title": "In-Place Cap Rate",
        "type ": "percentage_2_decimal"
      },{
        "key": "percentage_occupancy",
        "title": "Current Occupancy",
        "type ": "percentage_2_decimal"
      },{
        "key": "offer_price",
        "title": "Offer Price",
        "type ": "currency_with_decimal"
      },{
        "key": "stabilized_cap_rate_roc",
        "title": "Stabilized Cap Rate/ROC",
        "type ": "percentage_2_decimal"
      },{
        "key": "est_unlevered_irr",
        "title": "Est. Unlevered IRR",
        "type ": "percentage_2_decimal"
      },{
        "key": "est_levered_irr",
        "title": "Est. Levered IRR",
        "type ": "percentage_2_decimal"
      },{
        "key": "est_profit_multiple",
        "title": "Est. Profit Multiple",
        "type ": "string"
      },{
        "key": "winning_bid_price",
        "title": "Winning Bid Price",
        "type ": "currency_with_decimal"
      },{
        "key": "jv_partner",
        "title": "JV Partner",
        "type ": "string"
      },{
        "key": "sale_broker",
        "title": "Sale Broker",
        "type ": "string"
      },{
        "key": "deal_notes",
        "title": "Deal Notes",
        "type ": "textarea"
      }]
    },{
      "key": "for_lease_details",
      "title": "For Lease Details",
      "fields": [{
        "key": "date_available",
        "title": "Date Available",
        "type ": "string"
      }, {
        "key": "lease_sf",
        "title": "Lease Square Footage",
        "type ": "number_with_comma"
      }, {
        "key": "floor",
        "title": "Floor",
        "type ": "number_with_comma"
      }, {
        "key": "asking_rent_psf_mo",
        "title": "Asking Rent (psf/mo)",
        "type ": "currency_2_decimal"
      }, {
        "key": "reimbursement_method",
        "title": "Reimb. Method (e.g. NNN)",
        "type ": "string"
      }, {
        "key": "lease_term",
        "title": "Lease Term",
        "type ": "number_without_comma"
      },{
        "key": "direct_sublease",
        "title": "Direct/Sublease",
        "type ": "string"
      },{
        "key": "desired_use",
        "title": "Desired Use",
        "type ": "string"
      },{
        "key": "leasing_broker",
        "title": "Leasing Broker",
        "type ": "string"
      }, {
        "key": "lease_notes",
        "title": "Lease Notes",
        "type ": "textarea"
      }]
    },{
      "key": "investment_details",
      "title": "Investment Details",
      "fields": [{
        "key": "acquisition_year",
        "title": "Acquisition Year",
        "type ": "date_year"
      },{
        "key": "acquisition_price",
        "title": "Acquisition Price",
        "type ": "currency_without_decimal"
      },{
        "key": "acquisition_price_psf_unit_key",
        "title": "Acq. Price PSF/Unit/Key",
        "type ": "currency_without_decimal"
      },{
        "key": "going_in_cap_rate",
        "title": "Going-In Cap Rate",
        "type ": "percentage_2_decimal"
      },{
        "key": "stabilized_roc",
        "title": "Stabilized ROC",
        "type ": "percentage_2_decimal"
      },{
        "key": "all_in_price_psf_unit_key",
        "title": "All-in Basis PSF/Unit/Key",
        "type ": "currency_without_decimal"
      },{
        "key": "hold_period",
        "title": "Hold Period (years)",
        "type ": "number_2_decimal"
      },{
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      },{
        "key": "sale_price_psf_unit_key",
        "title": "Sale Price PSF/Unit/Key",
        "type ": "currency_without_decimal"
      },{
        "key": "unlevered_irr",
        "title": "Unlevered IRR",
        "type ": "percentage_2_decimal"
      },{
        "key": "levered_irr",
        "title": "Levered IRR",
        "type ": "percentage_2_decimal"
      },{
        "key": "profit_multiple",
        "title": "Profit Multiple",
        "type ": "string"
      },{
        "key": "investment_notes",
        "title": "Investment Notes",
        "type ": "textarea"
      }]
    },{
      "key": "building_name",
      "title": "Building/Location Name",
      "fields": [{
        "key": "building_name",
        "title": "Name",
        "type": "string"
      }]
    },{
      "key": "status",
      "title": "Status",
      "fields": [{
        "key": "status_selection",
        "title": "Status Selection",
        "type": "select",
        "choices": ["Target Property", "Owned", "Sold", "Acquisition", "Disposition", "Asset Managed", "Held for Sale", "Seller Rep", "Buyer Rep", "Dual Representation", "Landlord Rep", "Tenant Rep", "On Market", "Off Market Opportunity", "LOI", "Under Contract", "Dead Deal", "Sale Comps", "Lease Comps", "Competitive Properties", "For Lease", "Leased", "Local Amenities", "New Developments", "Employers"]
      }]
    },{
      "key": "lifecycle",
      "title": "Lifecycle",
      "fields": [{
        "key": "lifecycle_selection",
        "title": "Lifecycle Selection",
        "type": "select",
        "choices": ["Unentitled", "Entitlement Process", "Entitled", "Under Development", "Certificate of Occupancy", "Lease Up", "Repositioning", "Redevelopment", "Stabilized"]
      }]
    },{
      "key": "location_notes",
      "title": "Location Notes",
      "fields": [{
        "key": "note_entry",
        "title": "Note Entry",
        "type": "textarea"
      }]
    },{
      "key": "deal_rumors",
      "title": "Deal Rumors",
      "fields": [{
        "key": "deal_rumors",
        "title": "Rumor",
        "type": "textarea"
      }]
    },
    // {
    //   "key": "location_type",
    //   "title": "Location Type",
    //   "fields": [{
    //     "key": "type",
    //     "title": "Type",
    //     "type": "select",
    //     "choices": ["Retail", "Apartment", "Office", "Industrial", "Hotel", "Single-Family", "Land", "Healthcare", "Specialty", "Other"]
    //   }]
    // },
    {
      "key": "building_square_footage",
      "title": "Building Square Footage",
      "fields": [{
        "key": "building_square_footage",
        "title": "Square Feet",
        "type": "number_with_comma"
      }]
    },{
      "key": "building_class",
      "title": "Building Class (A,B,C)",
      "fields": [{
        "key": "building_class",
        "title": "Building Class",
        "type": "string"
      }]
    },{
      "key": "floors",
      "title": "Floors",
      "fields": [{
        "key": "floors",
        "title": "Floors",
        "type": "number_without_comma"
      }]
    },{
      "key": "occupancy",
      "title": "Occupancy",
      "fields": [{
        "key": "percent_occupied",
        "title": "% Occupied",
        "type": "percentage_2_decimal"
      }]
    },{
      "key": "net_operating_income",
      "title": "Annual Net Operating Income",
      "fields": [{
        "key": "noi",
        "title": "NOI",
        "type": "currency_without_decimal"
      }]
    },{
      "key": "year_of_construction",
      "title": "YOC/Renovation",
      "fields": [{
        "key": "yoc",
        "title": "Year",
        "type": "date_year"
      }]
    },{
      "key": "permit_issue_date",
      "title": "Permit Issue Date",
      "fields": [{
        "key": "permit_issue_date",
        "title": "Permit_Issue_Date",
        "type": "string"
      }]
    },{
      "key": "estimated_construction_cost",
      "title": "Est. Construction Cost",
      "fields": [{
        "key": "estimated_construction_cost",
        "title": "Est._Construction_Cost",
        "type": "currency_with_decimal"
      }]
    },{
      "key": "land_square_footage",
      "title": "Land Square Footage",
      "fields": [{
        "key": "land_square_footage",
        "title": "Square Feet",
        "type": "number_with_comma"
      }]
    },{
      "key": "land_use",
      "title": "Land Use",
      "fields": [{
        "key": "land_use",
        "title": "type",
        "type": "string"
      }]
    },{
      "key": "apn",
      "title": "Assessor Parcel Number",
      "fields": [{
        "key": "apn",
        "title": "APN",
        "type": "string"
      }]
    },{
      "key": "parking_stalls",
      "title": "Total Parking Stalls",
      "fields": [{
        "key": "parking",
        "title": "stalls",
        "type": "number_with_comma"
      }]
    },{
      "key": "key_tenants",
      "title": "Key Tenants",
      "fields": [{
        "key": "key_tenants",
        "title": "Tenants",
        "type": "string"
      }]
    },{
      "key": "owner",
      "title": "Owner",
      "fields": [{
        "key": "owner",
        "title": "Owner Name",
        "type": "string"
      }]
    }
  ]
}
