
import React, {Component} from 'react'

import MediaGallery from './../MediaGallery'


import './FeatureListClick.css';

import Gallery from 'react-photo-gallery';

//import { Field, reduxForm } from 'redux-form';

//import closeBtn from './../../assets/close-1.svg';
//import FlagIcon from './../../assets/red-flag.svg';

//const uuidv4 = require('uuid/v4');

class FeatureListClick extends Component {

  constructor() {
    super();

    this.state = {
      images: [],
      selectedPlace: null,
      nextPlace: null,
      currentImage: null
    }
    this.onGalleryClick = (e, photoSet) => this._onGalleryClick(e, photoSet);
  }

  componentDidMount() {
    console.log('ComponentDidMount => ', this.props);
  }

  _onGalleryClick(e, photoSet) {
    this.setState({
      currentImage: e.target.src,
      images: photoSet.map(photo => photo.src)
    })
    this.props.toggleMediaGallery();
  }

  render() {

    const {
      accountUUID,
      navigation,
      toggleMediaGallery,
      //feature,
      //features,
      //featuresByPlaceId,
      //maps,
      //mapsByUUID,
      //setFeature,
      //setFeatureAnimate,
      setMapUUID,
      setPlaceAnimate,
      //createFeature,
      //setSidebarCreateFeature,
      displayPhotos,
      history,
      parentView,
      match,
      places
    } = this.props;

    const {
      images,
      selectedPlace,
      currentImage
      //nextPlace,
    } = this.state;

    return (
      <div className='FeatureListClick'>
        {navigation.mediagallery && <MediaGallery currentImage={currentImage} images={images} toggleMediaGallery={toggleMediaGallery}/>}
        <div className='FeatureListClick__topPane'>
          {selectedPlace && <div className='FeatureListClick__topPaneFeature' onClick={() => {}}>
            <div className='FeatureListClick__featureItem'>
              <div><img alt='Place' src={selectedPlace.icon} /></div>
              <div className='title'><span>{selectedPlace.title}</span></div>
            </div>
          </div>}
        </div>
        <div>
          <ul className='feature-panel' style={{
            maxHeight: window.innerHeight-171
          }}>
            {places.map((place) => {
              const columns = (place.photoSet.length < 3) ? place.photoSet.length : 3;
              return (
                <li key={place.id}>
                  <div className='feature' onClick={() => {
                    console.log("FEATURE CLICK!");
                    setMapUUID(null); //reset mapUUID
                    if (parentView==='geodesktop') {
                      var view = match.params.view;
                      var path = `/desktop/${accountUUID}/${place.id}`;
                      if (view) path+=`/${view}`;
                      history.push(path);
                    }
                    setPlaceAnimate(place);
                  }}>
                    <div className='feature__item'>
                      <div><img alt='Place' src={place.icon} /></div>
                      <div className='title'><span>{place.name}</span></div>
                    </div>
                  </div>
                  {displayPhotos && <Gallery columns={columns} photos={place.photoSet} onClick={(e) => {
                    this.onGalleryClick(e, place.photoSet)
                  }}/>}
                  {false && displayPhotos && place.maps.map((map) => {
                    return (
                      <div key={map.uuid} className='Photos'>
                        {map.feature.photoRows.map((photoRow, photoRowIndex) => {
                          return (
                            <div key={photoRowIndex} className='Photos__PhotoRow'>
                              {photoRow.map((photo, index) => {
                                return (
                                  <div key={index} className='Photo'>
                                    <div className='Photo__container'>
                                      <img alt={photo.og.image} src={photo.og.image} />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
};

export default FeatureListClick
