import { withRouter } from 'react-router-dom';
import React, {Component} from 'react'
//import PropTypes from 'prop-types'

//import { Switch, Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux'

import './Public.css';

import CheckIcon from './../assets/green-check-border.svg'

import GeodesktopImage from './../assets/homepage/5.png';
import CreatingInfomapsImage from './../assets/homepage/0.png';
import PopulatingInfomapsImage from './../assets/homepage/1.png';
import AssetSpecificDataImage from './../assets/homepage/2.png';
import DataEntryImage from './../assets/homepage/3.png';
import SharingInfomapsImage from './../assets/homepage/4.png';

import SettingsIcon from './../assets/vertical-menu.svg';
import FlagIcon from './../assets/red-flag.svg';
import DrawerIcon from './../assets/zondicons/menu.svg';

import Logo from './../assets/logo-brown-beta.png'

import ScrollableAnchor from 'react-scrollable-anchor'

//import GlobeImage from './../assets/homepage/globe-small.png';


import VideoPlayer from '../components/VideoPlayer'

const DealMapVideoOption = {
  autoplay: false,
  controls: true,
  width: 720,
  fluid: true,
  poster: '/homepage/video-poster.png',
  sources: [{
    src: '/homepage/deal_map.mp4',
    type: 'video/mp4'
  }],
  controlBar: {
    fullscreenToggle: true
  }
}

const PipelineVideoOption = {
  autoplay: false,
  controls: true,
  width: 720,
  fluid: true,
  poster: '/homepage/video-poster.png',
  sources: [{
    src: '/homepage/deal_pipeline.mp4',
    type: 'video/mp4'
  }],
  controlBar: {
    fullscreenToggle: true
  }
}

const PortfolioOverviewVideoOption = {
  autoplay: false,
  controls: true,
  width: 720,
  fluid: true,
  poster: '/homepage/video-poster.png',
  sources: [{
    src: '/homepage/portfolio.mp4',
    type: 'video/mp4'
  }],
  controlBar: {
    fullscreenToggle: true
  }
}

const MarketTourVideoOption = {
  autoplay: false,
  controls: true,
  width: 720,
  fluid: true,
  poster: '/homepage/video-poster.png',
  sources: [{
    src: '/homepage/market_tours.mp4',
    type: 'video/mp4'
  }],
  controlBar: {
    fullscreenToggle: true
  }
}

const Content = () => (
  <div className="ContentWindow__column">
      {/* <div className='date'>
        <span>October 11, 2018</span>
      </div> */}
			<h1 className="title">
				What is Geotiller?
			</h1>
			<div className="body">
				<p><span style={{fontWeight: 400}}>Our project-based Infomaps allow commercial real estate professionals to add weblinks, files, photos and asset-specific data to properties and share with clients, partners & colleagues.</span></p>
        <h2>Why use Infomaps?</h2>
        <ul className="takeaway">
        <li><div className='bullet'><img src={CheckIcon} /><span>Easily build custom, content-rich maps</span></div></li>
        <li><div className='bullet'><img src={CheckIcon} /><span>Supplement existing deliverables (OMs, memos, pipelines, portfolio overviews)</span></div></li>
        <li><div className='bullet'><img src={CheckIcon} /><span>Tell a more compelling asset or market story</span></div></li>
        <li><div className='bullet'><img src={CheckIcon} /><span>Aggregate data on a map, not in random file folders</span></div></li>
        </ul>
        <h1 className='anchor'>The Basics</h1>
        <h2>Creating Infomaps</h2>
        <p>In your Infomap drawer (<img className='tiny' src={DrawerIcon} />), click the “Create an Infomap” button, name your map and you’re ready to go!  You can create as many maps as you’d like.</p>
        <img className='screencap' src={CreatingInfomapsImage} />
        <div className='divider'></div>
        <h2>Your Geodesktop</h2>
        <p>Geodesktop is where you build and view your Infomaps.</p>
        <img className='screencap' src={GeodesktopImage} />
        <div className='divider'></div>
        <h2>Populating Infomaps</h2>
        <p>With your desired Infomap selected, find a property on your geodesktop and plant a (<img className='tinyx2' src={FlagIcon} />).  You now have the ability to add weblinks, files, photos or asset-specific data to this property.  Set a property status or lifecycle to build specific map types (see <a href='#usecases'>Use Case videos</a>).</p>
        <img className='screencap' src={PopulatingInfomapsImage} />
        <div className='divider'></div>
        <h2>Adding Asset-Specific Data</h2>
        <p>When adding “Data,” first select a specific asset-type:</p>
        <img className='screencap' src={AssetSpecificDataImage} />
        <p>Select from a multitude of data types to add more detail to your selected property.</p>
        <img className='screencap' src={DataEntryImage} />
        <div className='divider'></div>
        <h2>Sharing Infomaps</h2>
        <p>Once your Infomap is built, you have the option to share it.  Access your Infomap’s settings (<img className='tiny' src={SettingsIcon} />) in your Infomap drawer and search the user’s email.  NOTE: to add someone to your Infomap they must be a Geotiller Beta user.</p>
        <img className='screencap' src={SharingInfomapsImage} />
        <ScrollableAnchor id={'usecases'}>
          <h1 className='anchor'>Infomap Use Cases</h1>
        </ScrollableAnchor>
        <ScrollableAnchor id={'dealmap'}>
          <div>
            <h2>Deal Map</h2>
            <p>If you're buying or selling a deal, use Infomaps to supplement an offering memorandum or investment committee memo</p>
            <p>An Infomap can enhance the asset & submarket story (e.g. local amenities, new developments, competitive properties) and allow your audience the opportunity to view your due diligence (e.g. articles, reports)</p>
            <div className='VideoBox'>
              <VideoPlayer { ...DealMapVideoOption } />
            </div>
          </div>
        </ScrollableAnchor>
        <div className='divider'></div>
        <ScrollableAnchor id={'pipeline'}>
          <div>
            <h2>Pipeline</h2>
            <p>If you're an investor searching for new deals or a broker helping a 1031 client, Infomaps can be a great way to track and present your deal pipeline</p>
            <p>Infomaps keep all your deals organized by status (on market, LOI, dead deal etc.) and allow you to store OMs and other due diligence items to share with clients, colleagues or partners</p>
            <div className='VideoBox'>
              <VideoPlayer { ...PipelineVideoOption } />
            </div>
          </div>
        </ScrollableAnchor>
        <div className='divider'></div>
        <ScrollableAnchor id={'portfoliooverview'}>
          <div>
            <h2>Portfolio Overview</h2>
            <p>Presenting your deal resume to potential investors or clients has never been more impactful than with an Infomap</p>
            <p>Portfolio property statuses (e.g. owned, sold, leased, held for sale) help you present a geospatial investment/transaction timeline</p>
            <div className='VideoBox'>
              <VideoPlayer { ...PortfolioOverviewVideoOption } />
            </div>
          </div>
        </ScrollableAnchor>
        <div className='divider'></div>
        <ScrollableAnchor id={'markettours'}>
          <div>
            <h2>Market Tours / Market Updates</h2>
            <p>Property tours and market updates will never be the same with the help of Infomaps</p>
            <p>Whether touring a client or an investor through a submarket or sending a quarterly market update, Infomaps can help your audience better visualize your market story</p>
            <div className='VideoBox'>
              <VideoPlayer { ...MarketTourVideoOption } />
            </div>
          </div>
        </ScrollableAnchor>
      </div>
  </div>
)

class PublicApp extends Component {

  render() {
    return (
      <div className='Homepage'>
        <div className='HomepageContainer'>
          <div className='HeaderBackdrop'>
          </div>
          <div className='HeaderWindow'>
            <div className='Navigation'>
              {false && <img src={Logo} />}
              <div className='Navigation__item'>
                <a className='login' href='users/sign_in'>Login</a>
                <a className='register' href='users/sign_up'>Register</a>
              </div>
            </div>
            <img src={Logo} />
          </div>
          <div className='ContentWindow'>
            {Content()}
            <footer className="footer">
              <div className="footer-inner">
                <p>Questions? Email us at <a href="mailto:support@geotiller.com">support@geotiller.com</a><a>
                </a></p><div className="footer-language-selector"><a>
                </a></div><a>
              </a></div><a>
            </a></footer>
          </div>
        </div>
      </div>
    )
  }
}

// class PublicApp extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       windowHeight: window.innerHeight
//     }
//     this.resize = (size) => this._resize(size);
//   }

//   componentDidMount() {
//     window.addEventListener('resize', this.resize);
//   }

//   _resize = (size) => {
//     this.setState({ windowHeight: window.innerHeight })
//   };

//   render() {
//     const { windowHeight } = this.state;

//     return (
//       <div className='Homepage'>
//         <div className='HomepageContainer'>
//           <div className='HeaderBackdrop' style={{
//             height: windowHeight+'px'
//           }}>
//           </div>
//           <div className='HeaderWindow'>
//             <div className='Navigation'>
//               {false && <img src={Logo} />}
//               <div className='Navigation__item'>
//                 <a className='login' href='users/sign_in'>Login</a>
//                 <a className='register' href='users/sign_up'>Register</a>
//               </div>
//             </div>
//             <img src={Logo} />
//           </div>
//           <div className='ResourceWindow'>
//             {/* <h3 className='blurb'>If data science is CRE's future, Geotiller is building the tools to get your organization’s data ready today.</h3> */}
//             <p><a href='https://s3.amazonaws.com/geotiller.com/002_CRE.pdf'>Learn More</a></p>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }


function mapStateToProps(state, ownProps) {
  return {
  };
}

const mapDispatchToProps = {
}

const PublicAppHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicApp);

export default withRouter(PublicAppHoc)
