
import React, {Component} from 'react'

//import MapGL from 'react-map-gl';

//import { Link } from 'react-router-dom';

//import searchIcon from './../assets/search.svg';
//import closeIcon from './../assets/close-1.svg';
//import colorMapIcon from './../assets/color-map.svg';
//import CloseIcon from './../assets/zondicons/close.svg';

import Lightbox from 'react-images';

//import axios from 'axios';

import './MediaGallery.css';

class MediaGallery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0
    }
    this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  gotoPrevious () {
		this.setState({
			currentImageIndex: this.state.currentImageIndex - 1,
		});
	}
	gotoNext () {
		this.setState({
			currentImageIndex: this.state.currentImageIndex + 1,
		});
	}

  componentDidMount() {
    if (this.props.currentImage && this.props.images) {
      this.props.images.forEach((image, index) => {
        if (image===this.props.currentImage) {
          this.setState({currentImageIndex: index})
        }
      })
    }
  }

  render() {

    const { images, toggleMediaGallery } = this.props;

    const { currentImageIndex } = this.state;

    const lightboxImages = images.map((image) => {
      return {src: image}
    });

    return (
      <div className='MediaGallery'>
        <div className='MediaGallery__header'>
        </div>
        <div className='MediaGallery__content'>
          <Lightbox
            currentImage={currentImageIndex}
            images={lightboxImages}
            isOpen={true}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClose={toggleMediaGallery}
          />
        </div>
        <div className='MediaGallery__footer'>
          <div className='MediaGallery__footer-item'>
            <div className='MediaGallery__footer-item-map'>
            </div>
          </div>
          <div className='MediaGallery__footer-item'>
            <div className='MediaGallery__footer-item-map'>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MediaGallery
