import React, {Component} from 'react'
//import PropTypes from 'prop-types'
//import { connect } from 'react-redux'

import MapGL, {Marker, Popup} from 'react-map-gl';

import SplitPane from 'react-split-pane';

import FeatureItemFeedClickHoc from './../../containers/FeatureItemFeedClickHoc'
import PropertyPopup from './../PropertyPopup'
import { Link } from 'react-router-dom';
import Logo from './../../assets/geotiller.gif';

import './InfoMap.css';


class InfoMap extends Component {

  componentDidMount() {

    console.log(this.props.match.params);
    const { accountUUID, mapUUID } = this.props.match.params
    const { setAccountUUID, getAccount, setMapUUID, exclusiveSelectMap } = this.props;

    if (accountUUID) setAccountUUID(accountUUID);
    if (accountUUID) getAccount(accountUUID, {selectMaps: false});
    //if (mapUUID) setMapUUID(mapUUID);

    // this.props.fetchFeatures(mapUUID);
    // this.props.resetDocuments(mapUUID);
    //
    // this.props.resetMaps(this.props.match.params.accountUUID);
    if (mapUUID) exclusiveSelectMap({uuid: mapUUID})

    //const { map, navigation } = this.props;

    // this.props.updateInfomapViewport({
    //   ...navigation.infomap.viewport,
    //   zoom: this.props.map.default.zoom,
    //   latitude: this.props.map.default.lat,
    //   longitude: this.props.map.default.lng
    // })

    window.addEventListener('resize', this._resize);
    this._resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize);
  }

  _resize = () => {
    if (this.props.map) {
      var viewport = {
        zoom: this.props.map.default.zoom,
        latitude: this.props.map.default.lat,
        longitude: this.props.map.default.lng
      }
    } else {
      var viewport = {};
    }
    viewport = {
      ...viewport,
      ...this.props.navigation.infomap.viewport,
      height: this.props.navigation.infomap.height,
      width: window.innerWidth
    };
    this.props.updateInfomapViewport(viewport)
  };

  render() {

    const {
      feature,
      map,
      history,
      //maps,
      //filteredFeatures,
      setInfomapHeight,
      setFeatureUUID,
      //setFeatureAnimateInfomap,
      setPlaceAnimateInfomap,
      unsetFeatureUUID,
      navigation,
      setDocPaneListView,
      place,
      places
    } = this.props;

    const AddURL = "/desktop";

    return (
      <div>
        <nav className="FeedNav">
          <div className='FeedNav__container'>
            {map &&
              <div className='FeedNav__infomap'>
                <h2>{map.title}</h2>
                <h5>{map.accountUUID}</h5>
              </div>
            }
            <div className='FeedNav__import'>
              {false && <div className='button-box'>
                <Link className='button primary' to={AddURL}>Add</Link>
              </div>}
            </div>
            <div className='FeedNav__logo' onClick={() => {
              history.push('/desktop');
            }}>
              <img alt='Logo' src={Logo}/>
            </div>
          </div>
        </nav>
        <SplitPane onChange={(size) => {
          console.log(size);
          setInfomapHeight(size);
          this._resize();
        }} split="horizontal" minSize={50} defaultSize={navigation.infomap.height}>

          <div className="map_container">
            {navigation.infomap.viewport.latitude && <MapGL
              {...navigation.infomap.viewport}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              onViewportChange={(viewport) => {
                this.props.updateInfomapViewport({
                  ...viewport,
                  height: navigation.infomap.viewport.height
                })
              }}
              mapboxApiAccessToken='pk.eyJ1IjoiYWR2M2N0dXMiLCJhIjoiY2pkNmticjZrNTB5dTJ3bzF2dnQzZXA1MSJ9.sHLvu8If1_NerQ3ZXKixgA'
            >
              {(false && feature && feature.uuid) &&
                <Popup tipSize={5}
                  anchor="left"
                  longitude={feature.location.lng}
                  latitude={feature.location.lat}
                  offsetLeft={40}
                  offsetTop={30}
                  closeButton={false}>
                  <div>
                    <button className='mapboxgl-popup-close-button' onClick={() => unsetFeatureUUID()}>x</button>
                  </div>
                  <PropertyPopup
                    feature={feature}
                    setDocPaneListView={setDocPaneListView}
                    setFeatureUUID={setFeatureUUID}
                    place={place}
                    viewButton={false}
                    statsPanel={false}
                  />
                </Popup>
              }
              {places.map((place) => (
                  <Marker key={place.location.place_id} latitude={place.location.lat} longitude={place.location.lng} offsetLeft={place.marker.offsetLeft} offsetTop={place.marker.offsetTop}>
                    <img alt='Marker' style={{width: `${place.marker.width}%`}} src={place.marker.icon}/>
                  </Marker>
                )
              )}
            </MapGL>}
          </div>

          <article className="Feed" style={{height: navigation.infomap.feedHeight}}>
            {false && <header className="Feed__mapName">
              <h1 className="Feed__mapTitle"></h1>
            </header>}
            <div className="Feed__contents">
              <FeatureItemFeedClickHoc parentView='infomap' displayPhotos={true} setPlaceAnimate={setPlaceAnimateInfomap} />
            </div>
          </article>

        </SplitPane>

      </div>
    );
  }
}

export default InfoMap;
