
import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';


import './LinkPublisher.css';

class LinkPublisher extends Component {

  constructor(props) {
    super(props);
    this.state = {
      snapshotAt: moment()
    };
    this.onUrlChange = (e) => this._onUrlChange(e);
  }

  _onUrlChange(e) {
    //console.log(e.target.value);
    const { handleSubmit } = this.props;
    const { snapshotAt } = this.state;

    handleSubmit(values =>
      this.props.onSubmit({
        snapshotAt,
        values: {
          ...values,
          url: e.target.value
        }
      })
    )();
  }

  render() {
    return (
      <div className='LinkPublisher'>
        <form autoComplete="off" onSubmit={(e) => {
          e.preventDefault();
          console.log("LinkPublisher onSubmit")
          this.props.handlePublish();
          return false;
        }}>
          <div>
            <label>URL</label>
            <div>
              <Field
                name="url"
                component="input"
                type="text"
                placeholder="URL"
                onChange={this.onUrlChange}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
};

export default reduxForm({
  form: 'LinkPublisher', // a unique identifier for this form
})(LinkPublisher);
