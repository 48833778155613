import thunkMiddleware from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import { createMemoryHistory } from 'history'
import { createStore , applyMiddleware, compose, combineReducers  } from 'redux'
import { routerMiddleware } from 'react-router-redux'

import {
  appendMaps
} from '../actions/console';

import {
  getMaps
} from '../graphql/getMaps';

import reducer from '../reducers'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

const rootPersistConfig = {
  key: 'geotiller',
  storage,
  blacklist: ['session'],
}

const sessionPersistConfig = {
  key: 'session',
  storage,
  blacklist: ['tenant'],
}

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, reducer.session),
  console: reducer.console,
  bookmarks: reducer.bookmarks,
  mapTypes: reducer.mapTypes,
  form: reducer.form,
  router: reducer.router,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const domExists = (typeof window !== 'undefined' && window.document && window.document.createElement);

export const history = domExists?createHistory():createMemoryHistory();
const rMiddleware = routerMiddleware(history)

const initMiddleware = store => next => action => {
  if (action.type === 'RESET_ACCOUNT') {
    if (action.account && action.account.uuid) {

      store.dispatch(getMaps(action.account.uuid));


      // var accountUUIDs = [action.account.uuid];
      // action.account.maps.forEach((map) => {
      //   if (!accountUUIDs.includes(map.accountUUID)) {
      //     accountUUIDs.push(map.accountUUID)
      //   }
      // });
      //
      //
      // (accountUUIDs || []).forEach((accountUUID) => {
      //   store.dispatch(appendMaps(accountUUID));
      // })
    }
    return next(action)
  } else {
    return next(action)
  }
}

const middleware = applyMiddleware(
  thunkMiddleware,
  rMiddleware,
  initMiddleware
)

const composeEnhancers =
	typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__:compose;

export const store = createStore(
	persistedReducer,
	composeEnhancers(middleware)
);


if (!window.__geotiller__) {
  window.__geotiller__ = {};
}
window.__geotiller__['store'] = store;


// export const store = createStore(
// 	reducer,
// 	composeEnhancers(middleware)
// );

export const persistor = persistStore(store)
