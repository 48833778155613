import React, {Component} from 'react'
//import PropTypes from 'prop-types'

import './MiniCardDeck.css';

class MiniCardDeck extends Component {

  constructor(props) {
    super(props);
    this.state = {
      size: (props.size) ? props.size : 'md' //sm, md, lg
    }
  }

  render() {

    const {
      maps,
      writableMaps,
      mapUUID,
      setMapUUID
    } = this.props;

    const {
      size
    } = this.state;

    const writableMapUUIDs = writableMaps.map((map) => map.uuid);

    return (
      <div>
        {writableMaps.length!==0 && <div className={'MiniCardDeck ' + size}>
          {maps.map((m) => {
            if (writableMapUUIDs.includes(m.uuid)) {
              return (
                <div key={m.uuid} className={(mapUUID===m.uuid) ? 'MiniCardDeck__item MiniCardDeck__item--selected' : 'MiniCardDeck__item'} onClick={(e) => setMapUUID(m.uuid)}>
                  <div className='title'>{m.title}</div>
                </div>
              )
            } else {
              return (
                <div key={m.uuid} className='MiniCardDeck__item MiniCardDeck__item--disabled'>
                  <div className='title'>{m.title}</div>
                </div>
              )
            }
          })}
        </div>}
      </div>
    );
  }
}


export default MiniCardDeck;
