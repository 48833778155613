import React, { Component } from 'react';

import './App.css';
import './Materialize.css';
import './Hamburger.css'

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux'
import { Route, Switch } from 'react-router-dom'
import { store, history } from './util/Store'

import PublicHoc from './containers/PublicHoc'
import PublicAppHoc from './containers/PublicAppHoc'
import ProtectedAppHoc from './containers/ProtectedAppHoc'

//const Spinner = require('react-spinkit');

const LoadingPage = () => (
  <div className='LoadingPage'>
  </div>
)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/loading" component={LoadingPage}/>
            <Route path="/home" component={ProtectedAppHoc}/>
            <Route path="/desktop" component={ProtectedAppHoc}/>
            <Route path='/desktop/accept/:mapAccountUUID/:mapUUID/:token' component={ProtectedAppHoc} />
            <Route path="/infomap" component={ProtectedAppHoc}/>
            <Route path="/map" component={ProtectedAppHoc}/>
            <Route path="/publisher" component={ProtectedAppHoc}/>
            <Route path="/users/sign_in" component={ProtectedAppHoc}/>
            <Route path="/users/sign_up/:referringAccountUUID?/:referringMapUUID?" component={ProtectedAppHoc}/>
            <Route path="/users/sign_out" component={ProtectedAppHoc}/>
            <Route path="/users/password" component={ProtectedAppHoc}/>
            <Route path="/reset" component={ProtectedAppHoc}/>
            <Route path="/001_CRE.pdf" component={PublicHoc}/>
            <Route path="/geodesktop" component={PublicHoc}/>
            <Route path="/" component={PublicHoc}/>
            <Route path="*" component={()=>(<div>NoMatch</div>)} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
