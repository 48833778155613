export default {
  "dataTypes": [{
      "key": "industrial_type",
      "title": "Industrial Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Warehouse/Dist.", "Manufacturing", "Flex", "R&D", "Cold Storage"]
      }]
    },
    {
      "key": "average_industrial_rent",
      "title": "Average Industrial Rent (psf/mo NNN)",
      "fields": [{
        "key": "rent",
        "title": "Average Rent (psf/mo NNN)",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "lease_comp",
      "title": "Lease Comp",
      "fields": [{
        "key": "tenant",
        "title": "Tenant Name",
        "type ": "string"
      }, {
        "key": "lease_date",
        "title": "Lease Date",
        "type": "string"
      }, {
        "key": "lease_square_footage",
        "title": "Lease Square Footage",
        "type ": "number_with_comma"
      }, {
        "key": "lease_type",
        "title": "Lease Type (New, Renewal)",
        "type ": "string"
      }, {
        "key": "lease_rate",
        "title": "Lease Rate (psf/mo)",
        "type": "currency_with_decimal"
      }, {
        "key": "reimbursement_method",
        "title": "Reimb. Method (e.g. NNN)",
        "type": "string"
      }, {
        "key": "lease_term",
        "title": "Lease Term (months)",
        "type ": "number_with_comma"
      }, {
        "key": "rent_escalations",
        "title": "Rent Escalations",
        "type ": "percentage_2_decimal"
      }, {
        "key": "rent_abatement",
        "title": "Rent Abatement (months)",
        "type ": "number_with_comma"
      }, {
        "key": "ti_allowance",
        "title": "TI Allowance (PSF)",
        "type ": "currency_without_decimal"
      }, {
        "key": "cams",
        "title": "CAMs (psf/mo)",
        "type ": "currency_with_decimal"
      }, {
        "key": "tenant_broker",
        "title": "Tenant Broker",
        "type ": "string"
      }, {
        "key": "landlord_broker",
        "title": "Landlord Broker",
        "type ": "string"
      }, {
        "key": "lease_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      }, {
        "key": "sale_price_psf",
        "title": "Price PSF",
        "type ": "currency_without_decimal"
      }, {
        "key": "cap_rate",
        "title": "Cap Rate",
        "type ": "percentage_2_decimal"
      }, {
        "key": "percent_occupied",
        "title": "Occupancy at Sale",
        "type ": "percentage_2_decimal"
      }, {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string "
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
