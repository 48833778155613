import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify';

import { persistor } from './../../util/Store'


class SignOut extends Component {

  componentDidMount() {
    const { history, resetSession, resetConsole } = this.props;
    const signout = Auth.signOut();
    persistor.purge().then((data) => {
      console.log('State purged data=>', data);
      resetSession();
      resetConsole();
      history.push('/');
      //window.location.reload();
    })
  }

  render () {
    return <div></div>
  }
}

export default SignOut
