import React, {Component} from 'react'

import { Switch, Route } from 'react-router-dom';

import './Accept.css';

class Accept extends Component {

  constructor(props) {
    super(props);
  }

  //http://localhost:3001/desktop/accept/16c8ba31-52c9-44f3-a7fd-68f22c2ce31f/2e9f267d-7a92-4b3b-bf3d-53a285783e8c/TOKEN
  componentDidMount() {
    const {
      match,
      account,
      history,
      activateAccountMap
    } = this.props;
    console.log({match, account});

    const accountUUID = account.uuid;
    const {
      mapAccountUUID,
      mapUUID,
      token
    } = match.params;

    console.log({mapAccountUUID, mapUUID, accountUUID, token});
    console.log(activateAccountMap);

    // activateAccountMap({
    //   uuid:
    // })
    history.push('/desktop');
  }

  render() {
    const { match, history } = this.props;
    return (
      <div className='acceptcontainer'>
      </div>
    )
  }
}

export default Accept;
