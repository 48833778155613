
import React, {Component} from 'react';

import InfoMapCardDeck from './InfoMapCardDeck'
import BookmarkDeck from './BookmarkDeck/BookmarkDeck'
import { Link } from 'react-router-dom';

//import Logo from './../../assets/geotiller.gif';

import './DesktopNav.css';
import WhiteAddIcon from './../../assets/white-add.svg';
import BlueAddIcon from './../../assets/blue-add.svg';
//import hamburger from './../../assets/hamburger.svg';
import bookmarkIcon from './../../assets/bookmark.svg';
import UserIcon from './../../assets/zondicons/user-solid-circle.svg'
import { Auth } from 'aws-amplify';

import appConfig from './../../config'

import { persistor } from './../../util/Store'

class DesktopNav extends Component {

  constructor(props) {
    super(props);

    this.handleProfile = this._handleProfile.bind(this);
  }

	handleLogout = async event => {
    const { history, resetSession, resetConsole, toggleUserDropdown } = this.props;
    await Auth.signOut();
    persistor.purge().then((data) => {
      console.log('State purged data=>', data);
      this.props.resetSession();
      this.props.resetConsole();
      history.push('/');
      window.location.reload();
    })
    // toggleUserDropdown();
		// await Auth.signOut();
    // history.push('/');
    // window.location.reload();
    // resetSession();
    // resetConsole();
	};

  _handleProfile(event) {
    const { toggleProfile } = this.props;
    toggleProfile();
  }

  render () {
    const {
      setError,
      setSubnav,
      toggleSubnav,
      toggleBookmarkView,
      toggleInfomapEditor,
      mapUUID,
      mapUUIDs,
      navigation,
      filteredMaps,
      deselectMap,
      createMap,
      //resetMaps,
      getMaps,
      updateMap,
      buildMap,
      setMapUUID,
      selectMap,
      selectedMap,
      setCardDeckTab,
      accountUUID,
      setBookmarkViewHeight,
      updateBookmarkViewViewport,
      feature,
      unsetFeatureUUID,
      //filteredFeatures,
      setFeatureUUID,
      resetBookmarks,
      filteredBookmarks,
      activeBookmarks,
      toggleBookmark,
      setFeatureAnimateBookmarkView,
      account,
      toggleUserDropdown,
      toggleHamburger,
      trashMap,
      history,
      togglePitchAndBearing,
      isRegularAccountType,
      //toggleProfile,
      session,
      session: { tenant: { nomenclature: { partition: { singular: Partition, plural: Partitions }}}}
    } = this.props;

    const newSeedURL = "/publisher/all/" + accountUUID + '/' + mapUUID;

    return (
      <div>
        <nav className="DesktopNav">
          <div className="DesktopNav_container">
            <div className='left'>
              {false && navigation.hamburger && <div className='menu'>
                <button className={(navigation.subnav||navigation.bookmarkView.display) ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse'} type="button" onClick={toggleSubnav}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </div>}
              {false && <div className='maps'>
                <a onClick={toggleSubnav}>
                  <span>Infomaps</span>
                </a>
              </div>}
              {false && <div className='new'>
                <Link to={newSeedURL}>
                  <img alt='Add' src={BlueAddIcon}/>
                  <span>New {Partition}</span>
                </Link>
              </div>}
            </div>
            {isRegularAccountType && <div className='right'>
              <div className='new'>
                <Link to={newSeedURL}>
                  <img alt='Add' style={{color: '#FFF'}} src={WhiteAddIcon}/>
                  <span>New {Partition}</span>
                </Link>
              </div>
              {false && <div className='bookmark'>
                <img alt='bookmark' src={bookmarkIcon} onClick={toggleBookmarkView}/>
              </div>}
              <div className='user' onClick={(e) => {
                e.stopPropagation();
                toggleUserDropdown()
              }}>
                <div className='user-container'>
                  <img alt='User' src={UserIcon}/>
                </div>
              </div>
              <ul className={(navigation.userdropdown) ? 'DesktopNav__user-dropdown DesktopNav__user-dropdown--active': 'DesktopNav__user-dropdown'}>
                <li onClick={togglePitchAndBearing}><h5>{appConfig.AppVersion}</h5></li>
                {false && <li><a className='button' onClick={this.handleProfile}>Edit Profile</a></li>}
                <li><a className='button SignOut' onClick={this.handleLogout}>Sign out</a></li>
              </ul>
            </div>}
          </div>
        </nav>
        {navigation.bookmarkView.display && <nav className={navigation.bookmarkView.display ? 'DesktopBookmarkView open': 'DesktopBookmarkView close'}>
          <div className="DesktopBookmarkView_container">
            <div className="NavDeck">
              <BookmarkDeck
                account={account}
                selectedMap={selectedMap}
                buildMap={buildMap}
                filteredMaps={filteredMaps}
                mapUUIDs={mapUUIDs}
                deselectMap={deselectMap}
                selectMap={selectMap}
                navigation={navigation}
                setCardDeckTab={setCardDeckTab}
                accountUUID={accountUUID}
                toggleInfomapEditor={toggleInfomapEditor}
                setMapUUID={setMapUUID}
                updateMap={updateMap}
                //resetMaps={resetMaps}
                getMaps={getMaps}
                setBookmarkViewHeight={setBookmarkViewHeight}
                updateBookmarkViewViewport={updateBookmarkViewViewport}
                feature={feature}
                unsetFeatureUUID={unsetFeatureUUID}
                setFeatureUUID={setFeatureUUID}
                resetBookmarks={resetBookmarks}
                filteredBookmarks={filteredBookmarks}
                activeBookmarks={activeBookmarks}
                toggleBookmark={toggleBookmark}
                setFeatureAnimateBookmarkView={setFeatureAnimateBookmarkView}
                toggleBookmarkView={toggleBookmarkView}
                setError={setError}
                toggleHamburger={toggleHamburger}
                setSubnav={setSubnav}
              />
            </div>
          </div>
          {false && <div className="DesktopSubNav_footer" onClick={toggleSubnav}>
          </div>}
        </nav>}
        {navigation.subnav && <nav id='DesktopSubNavID' className={navigation.subnav ? 'DesktopSubNav open': 'DesktopSubNav close'} onClick={(e) => {
          //if user isn't clicking inside the InfoMapCardDeck, then close the subnav
          if (e.target.id==='DesktopSubNavID') {
            setSubnav(false)
          }
        }}>
          <div className="DesktopSubNav_container">
            <div className="NavDeck">
              <InfoMapCardDeck
                selectedMap={selectedMap}
                createMap={createMap}
                filteredMaps={filteredMaps}
                mapUUIDs={mapUUIDs}
                deselectMap={deselectMap}
                selectMap={selectMap}
                navigation={navigation}
                setCardDeckTab={setCardDeckTab}
                accountUUID={accountUUID}
                toggleInfomapEditor={toggleInfomapEditor}
                setMapUUID={setMapUUID}
                updateMap={updateMap}
                //resetMaps={resetMaps}
                getMaps={getMaps}
                buildMap={buildMap}
                setError={setError}
                account={account}
                toggleHamburger={toggleHamburger}
                setSubnav={setSubnav}
                trashMap={trashMap}
                history={history}
                isRegularAccountType={isRegularAccountType}
                session={session}
              />
            </div>
          </div>
          {false && <div className="DesktopSubNav_footer" onClick={toggleSubnav}>
          </div>}
        </nav>}
      </div>
    );
  }
};

export default DesktopNav
