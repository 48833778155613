import React, { Component } from 'react'
import { Auth } from 'aws-amplify';

class AutoSignIn extends Component {
  constructor (props) {
    super(props);
  }

  componentDidMount() {
    const { setAccountType, setAccountUUID, setToken } = this.props;

		Auth.signIn("public@geotiller.com", "ffee2dd7-f4cd-4b26-8a2e-77c8e2c5788d")
      .then(user => {
        console.log(user);
        setAccountUUID(user.username);//user.username);
        setAccountType('limited');
        Auth.currentAuthenticatedUser()
          .then(authenticateUser => {
            setToken(authenticateUser.signInUserSession);
             window.location.reload();         
          })
      })

  }

  render () {
    return null
  }
}

export default AutoSignIn
