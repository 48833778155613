
import axios from 'axios';
//import { store } from '../util/Store';
import { NotificationManager } from 'react-notifications';
import { setLoading, setError } from './console';

//const uuidv4 = require('uuid/v4');

// export const addBookmark = (doc) => ({
//   type: 'ADD_BOOKMARK',
//   doc
// })
//
// export const removeBookmark = (doc) => ({
//   type: 'REMOVE_BOOKMARK',
//   doc
// })

export const toggleBookmark = (bookmark) => ({
  type: 'TOGGLE_BOOKMARK',
  bookmark
})

export const resetBookmarks = (accountUUID) => {
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/bookmarks/${accountUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      dispatch({
        type: 'RESET_BOOKMARKS',
        bookmarks: response.data.bookmarks
      })
    })
  }
}

export const createBookmark = (bookmark) => {
  console.log('createBookmark bookmark => ', bookmark);
  return (dispatch, getState) => {

    //@TODO check to see if bookmark already exists
    const currentBookmark = [];
    if (currentBookmark.length===0 && bookmark.accountUUID) {
      axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/bookmarks/${bookmark.accountUUID}`, bookmark)
      .then(response => {
        console.log(response.data);
        console.log(response.body)
        dispatch({
          type: 'CREATE_BOOKMARK',
          submitedAt: Date.now(),
        })
        setTimeout(() => {
          NotificationManager.success('Bookmark created', 'New Bookmark');
          dispatch( resetBookmarks(bookmark.accountUUID) )
          dispatch( setLoading( { blocking: false } ) )
        }, 500);
      })
      .catch(err => {
        NotificationManager.error('Error', 'Create Bookmark Error A128', 100000);
      })
    } else {
      dispatch( setError({message: "Bookmark already exists"}) )
      NotificationManager.error('Error', 'Create Bookmark Error A129', 100000);
    }
  }
}
