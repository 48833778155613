
import axios from 'axios';
//import { store } from '../util/Store';
import { NotificationManager } from 'react-notifications';
import ReactMapGL, {LinearInterpolator, FlyToInterpolator} from 'react-map-gl';

import appConfig from './../config'

// import {
//   selectMap
// } from './console'

const d3 = require('d3-ease');
//const uuidv4 = require('uuid/v4');


/**
**
** TENANTS
**
***/

export const setTenant = tenantIdentifier => ({
  type: 'SET_TENANT',
  tenantIdentifier,
})


/**
**
** ACCOUNTS
**
***/

export const resetSession = () => ({
  type: 'RESET_SESSION'
})


export const createAccount = (account) => {
  return dispatch => {
    axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/accounts`, account)
    .then(response => {
      dispatch({
        type: 'SUBMIT_ACCOUNT',
        submitedAt: Date.now(),
      })
    })
    .catch(err => {
      NotificationManager.error('Error', 'Create Account Error A128', 100000);
    })
  }
}

// set a map in account.maps to active, used when geotiller user accepts invitiation to collaborate
export const activateAccountMap = (map) => {
  return (dispatch, getState) => {
    let account = getState().session.account
    if (
      account &&
      account.uuid &&
      account.firstName &&
      account.lastName &&
      account.email &&
      account.contacts &&
      account.maps &&
      account.maps.filter(m => m.status==='pending').map(m => m.uuid).includes(map.uuid) &&
      map &&
      map.uuid &&
      map.accountUUID &&
      map.role==='follower' &&
      map.status==='pending'
    ) {
      let maps = account.maps.map(m => {
        if (m.uuid===map.uuid && m.status==='pending') {
          m.status='active'
        }
        return m;
      });
      axios.put(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/accounts/${account.uuid}`, {
        ...account,
        maps
      })
      .then(response => {
        dispatch({
          type: 'APPEND_ACCOUNT_MAPS',
          maps
        })
      })
      .catch(err => {
        NotificationManager.error('Error', 'Update Account Error A128', 100000);
      })
    }
  }
}

//options: selectMaps true/false
export const resetAccount = (accountUUID, options) => {
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/accounts/${accountUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      //console.log(window.FS);
      if (window && window.FS) {
        window.FS.identify(response.data.uuid, {
          email: response.data.email,
          version: appConfig.AppVersion
        });
      }
      if (window && window.mixpanel) {
        window.mixpanel.identify(accountUUID);
        window.mixpanel.track("resetAccount", {"accountUUID": accountUUID, version: appConfig.AppVersion});
      }
      dispatch({
        type: 'RESET_ACCOUNT',
        receivedAt: Date.now(),
        account: response.data,
        options
      })
    })
  }
}

export const setAccountUUID = (accountUUID) => {
  return dispatch => {
    if (window && window.FS) {
      window.FS.identify(accountUUID);
    }
    if (window && window.mixpanel) {
      window.mixpanel.identify(accountUUID);
      window.mixpanel.track("setAccountUUID", {"accountUUID": accountUUID, version: appConfig.AppVersion});
    }
    dispatch({
      type: 'SET_ACCOUNT_UUID',
      accountUUID
    })
  }
}

export const setToken = (user) => ({
  type: 'SET_TOKEN',
  ...user
})

export const setAccountType = accountType => ({
  type: 'SET_ACCOUNT_TYPE',
  accountType
})

