import { withRouter } from 'react-router-dom';

import FeatureItemFeed from '../components/FeatureItemFeed/FeatureItemFeed'

import { connect } from 'react-redux'

import {
  //getFeedPlaces,
  getFeedPlacesByStatus,
  getFilteredDocuments,
  //getFeatureDocuments,
  getFeature,
  //getFilteredFeatures,
  getFeedFeaturesByPlaceId,
  getFeedFeatures,
  //getFilteredMaps,
  getSelectedMaps,
  //getSelectedMap,
  getMapsByUUID,
  //getFeatures,
  //getDocumentsByMapAndFeature
} from '../selectors'

import {
  setPlaceId,
  //resetMaps,
  //selectMap,
  //deselectMap,
  //fetchFeatures,
  //resetDocuments,
  //updateViewport,
  //toggleSubnav,
  //setFeature,
  //setFeatureAnimate,
  //toggleDocumentAnalysis,
  //updateDocumentFileType,
  //updateDocumentAssetType,
  //viewDocument,
  //trashDocument,
  //setDocPaneMapView,
  //setDocPaneListView,
  //setSidebarWidth,
  //unsetFeature,
  toggleAssetTypeFilter,
  toggleFileTypeFilter,
  //toggleSeedPublisher,
  //setSeedPublisherTab,
  setSidebarTab,
  //setCardDeckTab,
  createFeature,
  setSidebarCreateFeature,
  //onFilePublish,
  //setAccountUUID,
  //createMap,
  setMapUUID,
  setFeatureUUID,
  //unsetFeatureUUID,
  //setMapSearchResults
} from '../actions/console';

import {
  setAccountUUID
} from '../actions/session'

function mapStateToProps(state) {
  return {
    //active: state.active,
    //console: state.console,
    navigation: state.console.navigation,
    accountUUID: state.session.account.uuid,
    mapUUID: state.console.mapUUID,
    featureUUID: state.console.featureUUID,
    documents: getFilteredDocuments(state),
    //featureDocuments: getFeatureDocuments(state),
    feature: getFeature(state),
    features: getFeedFeatures(state),
    featuresByPlaceId: getFeedFeaturesByPlaceId(state),
    //places: getFeedPlaces(state),
    places: getFeedPlacesByStatus(state),
    placeId: state.console.placeId,
    //filteredMaps: getFilteredMaps(state),
    maps: getSelectedMaps(state),
    //selectedMap: getSelectedMap(state),
    mapsByUUID: getMapsByUUID(state),
    assetTypeTopics: state.console.assetTypeTopics,
    fileTypeTopics: state.console.fileTypeTopics
  };
}

const mapDispatchToProps = {
  setPlaceId,
  //resetMaps,
  //selectMap,
  //deselectMap,
  //fetchFeatures,
  //resetDocuments,
  //updateViewport,
  //toggleSubnav,
  //setFeature,
  //setFeatureAnimate,
  //toggleDocumentAnalysis,
  //toggleSeedPublisher,
  //setSeedPublisherTab,
  //updateDocumentFileType,
  //updateDocumentAssetType,
  //viewDocument,
  //trashDocument,
  //setDocPaneMapView,
  //setDocPaneListView,
  //setSidebarWidth,
  //unsetFeature,
  toggleAssetTypeFilter,
  toggleFileTypeFilter,
  setSidebarTab,
  //setCardDeckTab,
  createFeature,
  setSidebarCreateFeature,
  //onFilePublish,
  setAccountUUID,
  //createMap,
  setMapUUID,
  setFeatureUUID,
  //unsetFeatureUUID,
  //setMapSearchResults
}

const FeatureItemFeedHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureItemFeed);

export default withRouter(FeatureItemFeedHoc)
