import { combineReducers } from 'redux'
import session from './session'
import console from './console'
import bookmarks from './bookmarks'
import mapTypes from './mapTypes'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'

// const geotillerApp = combineReducers({
//   session,
//   console,
//   bookmarks,
//   mapTypes,
//   form: formReducer,
//   router: routerReducer
// })

// export default geotillerApp

export default {
  session,
  console,
  bookmarks,
  mapTypes,
  form: formReducer,
  router: routerReducer
}