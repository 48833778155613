export default {
  "dataTypes": [{
      "key": "condo_building_type",
      "title": "Condo Building Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["High-Rise", "Mid-Rise", "Low-Rise", "Podium", "Garden", "Detached", "Co-Op", "Townhome"]
      }]
    },
    {
      "key": "unit_count",
      "title": "Unit Count",
      "fields": [{
        "key": "unit_count",
        "title": "Units",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "average_unit_size",
      "title": "Average Unit Size",
      "fields": [{
        "key": "unit_size",
        "title": "Average Unit Size",
        "type": "number_with_comma"
      }]
    },{
      "key": "density",
      "title": "Density per Acre",
      "fields": [{
        "key": "unit_density",
        "title": "Density per Acre",
        "type": "number_with_comma"
      }]
    },{
      "key": "average_condo_sale_psf",
      "title": "Avg. Condo Sale PSF",
      "fields": [{
        "key": "average_condo_sale_psf",
        "title": "Avg. Condo Sale PSF",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price (gross)",
        "type ": "currency_with_decimal"
      }, {
        "key": "sale_price_psf",
        "title": "Sale Price PSF",
        "type ": "currency_with_decimal"
      },  {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string"
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "buyer_agent",
        "title": "Buyer Agent",
        "type ": "string"
      }, {
        "key": "seller_agent",
        "title": "Seller Agent",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
