import { withRouter } from 'react-router-dom';

import Accept from '../components/Accept/Accept'

import { connect } from 'react-redux'

import {
  activateAccountMap
} from '../actions/session'

function mapStateToProps(state, ownProps) {
  return {
    console: state.console,
    account: state.session.account,
    accountUUID: state.session.account.uuid
  };
}

const mapDispatchToProps = {
  activateAccountMap
}

const AcceptHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(Accept);

export default withRouter(AcceptHoc)
