export default {
  "dataTypes": [{
      "key": "land_type",
      "title": "Land Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Single-Family Residential", "Multi-Family", "Retail", "Office", "Industrial", "Hotel", "Other"]
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      }, {
        "key": "sale_price_per_land_sf",
        "title": "Price per Land SF",
        "type ": "currency_without_decimal"
      }, {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string"
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
