
import React, {Component} from 'react'
//import PropTypes from 'prop-types'
import './Bookmark.css';

//import { Link } from 'react-router-dom';

//import eye from './../../../assets/eye.svg';

//const uuidv4 = require('uuid/v4');


class Bookmark extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { bookmark, toggleBookmark, setFeatureAnimateBookmarkView } = this.props;

    return (
      <div className='Bookmark' onClick={() => {
          if (!bookmark.active) setFeatureAnimateBookmarkView(bookmark.feature);
          toggleBookmark(bookmark);
        }}>
        <div className='Bookmark__status'>
          <div className={bookmark.active ? 'Bookmark__status-icon Bookmark__status-icon--active': 'Bookmark__status-icon'}>
          </div>
        </div>
        <div className='Bookmark__details'>
          <h5>{bookmark.document.title}</h5>
          <h6>{bookmark.feature.title}</h6>
        </div>
      </div>
    );
  }
};

export default Bookmark
