import { withRouter } from 'react-router-dom';

import InfoMap from '../components/InfoMap/InfoMap'

import { history } from './../util/Store'

import { connect } from 'react-redux'

import {
  //getMap,
  getPlace,
  getFeedPlaces,
  //getDocuments,
  getSelectedMap,
  getSelectedMaps,
  getFilteredDocuments,
  getFeature,
  getFilteredFeatures
} from '../selectors'

import {
  fetchDocuments,
  resetDocuments,
  //resetMaps,
  selectMap,
  exclusiveSelectMap,
  setMapUUID,
  updateInfomapViewport,
  setInfomapHeight,
  setFeatureUUID,
  //setAccountUUID,
  setFeatureAnimateInfomap,
  setPlaceAnimateInfomap,
  setPlaceAnimate,
  unsetFeatureUUID,
  fetchFeatures,
  setDocPaneListView
} from '../actions/console';

import {
  getMaps
} from '../graphql/getMaps'

import {
  //resetAccount,
  setAccountUUID
} from '../actions/session'

import {
  getAccount
} from '../graphql/getAccount'

function mapStateToProps(state) {
  return {
    active: state.active,
    console: state.console,
    navigation: state.console.navigation,
    accountUUID: state.session.account.uuid,
    feature: getFeature(state),
    maps: getSelectedMaps(state),
    map: getSelectedMap(state),
    place: getPlace(state),
    places: getFeedPlaces(state),
    filteredDocuments: getFilteredDocuments(state),
    filteredFeatures: getFilteredFeatures(state),
  };
}

const mapDispatchToProps = {
  history,
  //resetAccount,
  getAccount,
  //resetMaps,
  getMaps,
  fetchDocuments,
  resetDocuments,
  selectMap,
  exclusiveSelectMap,
  setMapUUID,
  updateInfomapViewport,
  setInfomapHeight,
  setFeatureUUID,
  setAccountUUID,
  setFeatureAnimateInfomap,
  setPlaceAnimateInfomap,
  setPlaceAnimate,
  unsetFeatureUUID,
  fetchFeatures,
  setDocPaneListView
}

const InfoMapHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoMap);

export default withRouter(InfoMapHoc)
