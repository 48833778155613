import { Auth, API, graphqlOperation } from "aws-amplify";

import appConfig from './../config'

export const getMaps = (accountUUID) => {
  return (dispatch, getState) => {

    const GetMaps = `query GetMaps($accountUUID: String!) {
      getMaps(accountUUID: $accountUUID) {
        uuid
        accountUUID
        mapType
        secret
        title
        default {
          lat
          lng
          zoom
        }
        permissions {
          accountUUID
          email
          firstName
          lastName
          role
        }
      }
    }`;

    // console.log("%c GetMaps", 'color: orange; font-weight: bold');
    // console.log({accountUUID});
    Auth.currentAuthenticatedUser().then(currentSession => {
     if (currentSession) {
        API.graphql( graphqlOperation(GetMaps, {accountUUID}) ).then((response) => {
          // console.log('GetMaps => ', {response});

          dispatch({
            type: 'RESET_MAPS',
            receivedAt: Date.now(),
            maps: response.data.getMaps
          })

        }).catch((err) => {
          console.error('getMaps => err => ', err);
        })
      }
    }).catch(e => {
      console.log(e, typeof e);
    });

  }
}

export default getMaps
