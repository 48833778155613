
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import ReactMapGL, {LinearInterpolator, FlyToInterpolator} from 'react-map-gl';
import { point, circle } from '@turf/turf';


import moment from 'moment';

// import {
//   resetAccount
// } from './session'

import {
  getAccount,
} from './../graphql/getAccount'

import {
  getDocuments,
} from './../graphql/getDocuments'

const d3 = require('d3-ease');
const uuidv4 = require('uuid/v4');

export const resetConsole = () => ({
  type: 'RESET_CONSOLE'
})

export const setError = (error) => ({
  type: 'SET_ERROR',
  error
})

export const setLoading = (loading) => ({
  type: 'SET_LOADING',
  loading
})

export const setDesktopOpacity = (desktopOpacity) => ({
  type: 'SET_DESKTOP_OPACITY',
  desktopOpacity
})

export const setFetchingProgress = (fetchingProgress) => ({
  type: 'SET_FETCHING_PROGRESS',
  fetchingProgress
})

export const toggleHamburger = (status) => ({
  type: 'TOGGLE_HAMBURGER',
  status
})

export const toggleSubnav = () => ({
  type: 'TOGGLE_SUBNAV'
})

export const toggleProfile = () => ({
  type: 'TOGGLE_PROFILE'
})

export const hideProfile = () => ({
  type: 'HIDE_PROFILE'
})

export const toggleMediaGallery = () => ({
  type: 'TOGGLE_MEDIA_GALLERY'
})

export const setPropertyStatusOrder = (statuses) => {
  return (dispatch, getState) => {
    var mapUUID = getState().console.mapUUID;
    var map = getState().console.maps.find((map) => map.uuid===mapUUID)
    if(map) {
      console.log(map);

      const propertyStatuses = statuses.map((status, index) => ({index: index+1,status}))

      dispatch(updateMap({
        ...map,
        propertyStatuses
      }))
      dispatch({
        type: 'SET_PROPERTY_STATUS_ORDER',
        propertyStatuses
      })
    }
  }
}

export const setSubnav = (display) => {
  return dispatch => {
    dispatch({type: 'SET_SUBNAV', display})
    dispatch({type: 'SET_DESKTOP_OPACITY', desktopOpacity: (display ? 0.20 : 1)})
  }
}

export const toggleUserDropdown = () => ({
  type: 'TOGGLE_USER_DROPDOWN'
})

export const hideUserDropdown = () => ({
  type: 'HIDE_USER_DROPDOWN'
})

export const toggleBookmarkView = () => {
  return dispatch => {
    dispatch(setSubnav(false));
    dispatch({
      type: 'TOGGLE_BOOKMARK_VIEW'
    })
  }
}

export const toggleSeedPublisher = () => ({
  type: 'TOGGLE_SEED_PUBLISHER'
})

export const toggleInfomapEditor = () => ({
  type: 'TOGGLE_INFOMAP_EDITOR'
})

export const setDocPaneMapView = (accountUUID=null) => {
  return dispatch => {
    dispatch({ type: 'RESET_DOCUMENT_ANALYSIS' })
    dispatch({ type: 'SET_DOCPANE_MAP_VIEW' })
    //dispatch(getUserLocation())
  }
}

export const setDocPaneListView = (accountUUID=null) => {
  return dispatch => {
    dispatch({ type: 'RESET_DOCUMENT_ANALYSIS' })
    dispatch({ type: 'SET_DOCPANE_LIST_VIEW' })
  }
}

export const setPlaceMapHeight = (height) => ({
  type: 'SET_PLACEMAP_HEIGHT',
  height
})

export const setInfomapHeight = (height) => ({
  type: 'SET_INFOMAP_HEIGHT',
  height
})

export const setBookmarkViewHeight = (height) => ({
  type: 'SET_BOOKMARK_VIEW_HEIGHT',
  height
})

export const setSidebarWidth = (width) => ({
  type: 'SET_SIDEBAR_WIDTH',
  width
})

export const toggleAssetTypeFilter = (topics) => ({
  type: 'TOGGLE_ASSET_TYPE_FILTER',
  topics
})

export const toggleFileTypeFilter = (topics) => ({
  type: 'TOGGLE_FILE_TYPE_FILTER',
  topics
})

export const setSidebarTab = (tabcontainer) => ({
  type: 'SET_SIDEBAR_TABCONTAINER',
  tabcontainer
})

export const setSeedPublisherTab = (tabcontainer) => ({
  type: 'SET_SEED_PUBLISHER_TABCONTAINER',
  tabcontainer
})

export const setCardDeckTab = (tabcontainer) => ({
  type: 'SET_CARDDECK_TABCONTAINER',
  tabcontainer
})

export const setSidebarCreateFeature = (status) => ({
  type: 'SET_SIDEBAR_CREATE_FEATURE',
  status
})

export const setSidebarCreateDocument = (status) => ({
  type: 'SET_SIDEBAR_CREATE_DOCUMENT',
  status
})

export const setSidebarCustomizePropertyStatus = (status) => ({
  type: 'SET_SIDEBAR_CUSTOMIZE_PROPERTY_STATUS',
  status
})

export const toggleDocumentConfigDropwdown = (document) => ({
  type: 'TOGGLE_DOCUMENT_CONFIG_DROPDOWN',
  document
})

export const setPublisherMapHeight = (height) => ({
  type: 'SET_PUBLISHER_MAP_HEIGHT',
  height
})

export const updatePublisherMapViewport = (viewport) => ({
  type: 'UPDATE_PUBLISHER_MAP_VIEWPORT',
  viewport
})

export const setSeedPublisherProgress = (loading) => ({
  type: 'SET_SEED_PUBLISHER_PROGRESS',
  loading
})

export const setSeedPublisherFileSuccess = () => ({
  type: 'SET_SEED_PUBLISHER_FILE_SUCCESS'
})

export const unsetSeedPublisherFileSuccess = () => ({
  type: 'UNSET_SEED_PUBLISHER_FILE_SUCCESS'
})



/**
* MAP TYPES
*/
export const setMapTypeKey = (mapTypeKey) => ({
  type: 'SET_MAP_TYPE_KEY',
  mapTypeKey
})
export const setLocationTypeKey = (locationTypeKey) => ({
  type: 'SET_LOCATION_TYPE_KEY',
  locationTypeKey
})
export const setDataTypeKey = (dataTypeKey) => ({
  type: 'SET_DATA_TYPE_KEY',
  dataTypeKey
})


/**
**
** MAPS
**
***/

// export const getMap = (accountUUID, mapUUID) => {
//   return dispatch => {
//     axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${accountUUID}/${mapUUID}`,{
//       responseType: 'json'
//     })
//     .then(response => {
//       dispatch({
//         type: 'RESET_MAPS',
//         receivedAt: Date.now(),
//         maps: response.data.maps
//       })
//     })
//   }
// }

export const getUserLocation = () => {
  return dispatch => {
    navigator.geolocation.getCurrentPosition(position => {
      //console.log(position);
      dispatch(updateViewport({
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        zoom: 12
      }))
    });
  }
}

export const appendMaps = (accountUUID) => {
  return (dispatch, getState) => {
    const account = getState().session.account;
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${accountUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      //check to make sure this account has some form of permission on the map
      //@fixme keying off email in addition to account uuid because a permission record can be added to a map for a given email before that user has registered
      var maps = response.data.maps.filter(map => map.permissions && map.permissions.filter((p) => {
        return (p.accountUUID===account.uuid || p.email===account.email)
      }).length!==0);
      dispatch({
        type: 'APPEND_MAPS',
        receivedAt: Date.now(),
        maps
      })
    })
  }
}

export const resetMaps = (accountUUID) => {
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${accountUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      dispatch({
        type: 'RESET_MAPS',
        receivedAt: Date.now(),
        maps: response.data.maps
      })
    })
  }
}

export const trashMap = (map) => {
  console.log("action.trashMap map => ", map);
  return (dispatch, getState) => {
    if (map && map.accountUUID && map.uuid) {
      axios.delete(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${map.accountUUID}`, { data: {
        uuid: map.uuid,
        accountUUID: map.accountUUID
      }})
      .then(response => {
        NotificationManager.warning('Map deleted', 'Delete Map');
        dispatch({
          type: 'DELETE_MAP',
          submitedAt: Date.now(),
          map
        })
        getState().console.features.filter(feature => feature.mapUUID===map.uuid).forEach((feature) => {
          dispatch( deleteFeature( {
            ...feature,
            featureUUID: feature.uuid
          } ));
          getState().console.documents.filter(doc => doc.featureUUID===feature.featureUUID).forEach((doc) => {
            dispatch( trashDocument( doc ) );
          })
        })
      })
      .catch(err => {
        NotificationManager.error('Error', 'Delete Map Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Delete Map Error A129', 100000);
    }
  }
}

/* used for dashboard - open a map, appending additional documents */

export const openMap = (map) => {
  return dispatch => {
    dispatch({
      type: 'SELECT_MAP',
      map
    })
    dispatch(appendFeatures(map.uuid));
    dispatch(appendDocuments(map.uuid));
  }
}

export const selectMap = (map) => {
  return (dispatch, getState) => {

    const mapMultiselect = getState().session.tenant.mapMultiselect;

    // ability to select more than one map
    if (mapMultiselect) {
      dispatch({ type: 'SELECT_MAP', map });
      dispatch(appendFeatures(map.uuid));
      dispatch(appendDocuments(map.uuid));
      //dispatch(getDocuments(map.uuid));

      if (map && map.title) {
        NotificationManager.info(`${map.title} and all the associated documents will now show on your geodesktop`);
      }

    /* selecting one map at a time, reset documents */
    } else {
      dispatch(deselectAllMaps());
      dispatch({ type: 'SELECT_MAP', map });
      dispatch(resetFeatures(map.uuid));
      dispatch(resetDocuments(map.uuid));

      if (map && map.title) {
        NotificationManager.info(`${map.title} and all the associated documents will now show on your geodesktop`);
      }
    }
    // //dispatch(setDocPaneMapView()); //prevent state where open place view is no longer available on the newly selected map
  }
}

export const exclusiveSelectMap = (map) => ({
  type: 'EXCLUSIVE_SELECT_MAP',
  map
})

export const deselectAllMaps = () => {
  return dispatch => {
    dispatch({
      type: 'DESELECT_ALL_MAPS'
    })
  }
}

export const deselectMap = (map) => {
  return dispatch => {
    NotificationManager.info(`${map.title} and all the associated documents will no longer show on your geodesktop`);
    dispatch({
      type: 'DESELECT_MAP',
      map
    })
  }
}

export const unsetMapUUID = (mapUUID) => ({
  type: 'UNSET_MAP_UUID',
  mapUUID
})

export const setMapUUID = (mapUUID) => ({
  type: 'SET_MAP_UUID',
  mapUUID
})

export const setMapSearchResults = (results) => ({
  type: 'SET_MAP_SEARCH_RESULTS',
  results
})

export const updateViewport = (viewport) => ({
  type: 'UPDATE_VIEWPORT',
  viewport
})

export const togglePitchAndBearing= () => {
  return (dispatch, getState) => {
    if (getState().console.viewport.pitch===0 && getState().console.viewport.bearing===0) {
      dispatch({
        type: 'UPDATE_VIEWPORT',
        viewport: {
          pitch: 59,
          bearing: 45
        }
      });
    } else {
      dispatch({
        type: 'UPDATE_VIEWPORT',
        viewport: {
          pitch: 0,
          bearing: 0
        }
      });
    }
  }
}

export const updateMapStyle = (mapStyle) => ({
  type: 'UPDATE_MAP_STYLE',
  mapStyle
})

export const updateInfomapViewport = (viewport) => ({
  type: 'UPDATE_INFOMAP_VIEWPORT',
  viewport
})

export const updateBookmarkViewViewport = (viewport) => ({
  type: 'UPDATE_BOOKMARK_VIEW_VIEWPORT',
  viewport
})

export const buildMap = (map) => {
  return dispatch => {
    dispatch( setLoading( { blocking: true } ) )
    dispatch( setFetchingProgress(0) );
    if (map && map.accountUUID) {
      axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/builder/maps`, map)
      .then(response => {
        console.log({map});
        // dispatch({
        //   type: 'APPEND_MAPS',
        //   receivedAt: Date.now(),
        //   maps: [
        //     {
        //       uuid: map.uuid,
        //       accountUUID: map.accountUUID,
        //       title: map.title,
        //       mapType: map.mapType,
        //       permissions: map.permissions,
        //       propertyStatuses: [],
        //       secret: map.secret,
        //       default: {
        //         lat: map.lat,
        //         lng: map.lng,
        //         zoom: map.zoom
        //       }
        //     }
        //   ]
        // })
        dispatch({
          type: 'SUBMIT_MAP',
          submitedAt: Date.now(),
        })
        dispatch( setLoading( { blocking: true } ) )
        setTimeout(() => {
          //dispatch( resetAccount(map.accountUUID) )
          dispatch( getAccount(map.accountUUID) )
          dispatch( toggleBookmarkView() );
          dispatch( toggleSubnav() );
          dispatch( setLoading( { blocking: false } ) )
        }, 500)
      })
      .catch(err => {
        NotificationManager.error('Error', 'Build Map Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Build Map Error A129', 100000);
    }
  }
}

export const createMap = (map) => {
  return dispatch => {
    if (map && map.accountUUID) {
      axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${map.accountUUID}`, map)
      .then(response => {
        dispatch({
          type: 'PREPEND_MAPS',
          receivedAt: Date.now(),
          maps: [
            {
              uuid: map.uuid,
              accountUUID: map.accountUUID,
              title: map.title,
              mapType: map.mapType,
              permissions: map.permissions,
              propertyStatuses: [],
              secret: map.secret,
              default: {
                lat: map.lat,
                lng: map.lng,
                zoom: map.zoom
              }
            }
          ]
        })
        dispatch({
          type: 'SUBMIT_MAP',
          submitedAt: Date.now(),
        })
        dispatch( setLoading( { blocking: true } ) )
        setTimeout(() => {
          dispatch({
            type: 'APPEND_ACCOUNT_MAPS',
            receivedAt: Date.now(),
            maps: [{
              uuid: map.uuid,
              accountUUID: map.accountUUID,
              role: "owner",
              status: ""
            }]
          })
          //dispatch( resetMaps( map.accountUUID ) )
          dispatch( selectMap( map ) )
          dispatch( setLoading( { blocking: false } ) )
        }, 500)
      })
      .catch(err => {
        NotificationManager.error('Error', 'Create Map Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Create Map Error A129', 100000);
    }
  }
}

export const updateMap = (map) => {
  console.log("action.updateMap map=> ", map);
  return dispatch => {
    if (map && map.accountUUID) {
      axios.put(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/maps/${map.accountUUID}`, map)
      .then(response => {
        dispatch({
          type: 'UPDATE_MAP',
          submitedAt: Date.now(),
          map
        })
        NotificationManager.success('Map updated', 'Update Map');
        // setTimeout(() => {
        //   //dispatch( resetMaps(map.accountUUID) );
        //   //dispatch( appendMaps(map.accountUUID) );
        //   NotificationManager.success('Map updated', 'Update Map');
        // }, 500)
      })
      .catch(err => {
        NotificationManager.error('Error', 'Update Map Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Update Map Error A129', 100000);
    }
  }
}


/**
**
** PLACES
**
***/

export const unsetPlaceId = () => ({
  type: 'UNSET_PLACE_ID'
})
export const setPlaceId = (placeId) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_PLACE_ID',
      placeId
    })
    const mapUUID = getState().console.mapUUID;
    const features = getState().console.features
      .filter((feature) => feature.location&&feature.location.place_id===placeId&&feature.mapUUID===mapUUID)
      .map(feature => feature)
    if (features.length>0) {
      dispatch(setFeatureUUID(features[0].uuid))
    }
    const featureUUIDs = features.map(feature => feature.uuid);
    const documents = getState().console.documents
      .filter((document) => document.type==='DATA'&&featureUUIDs.includes(document.featureUUID))
      .map(document => document)

    var documentsData = [];
    documents.forEach((record) => {
      Object.keys(record.dataTable).forEach((dataTypeKey) => {
        documentsData.push({
          locationTypeKey: record.dataTable[dataTypeKey][0].locationTypeKey,
          snapshotAt: record.dataTable[dataTypeKey][0].snapshotAt,
          snapshotAtDateTime: new Date(record.dataTable[dataTypeKey][0].snapshotAt),
          ...record
        })
      })
    });

    var locationTypeKey = 'Generic'
    documentsData = documentsData.sort((a,b) => {
      return new Date(a.snapshotAtDateTime) - new Date(b.snapshotAtDateTime);
    })
    if (documentsData.length>0) {
      locationTypeKey = documentsData[documentsData.length-1].locationTypeKey;
    }
    dispatch(setLocationTypeKey(locationTypeKey))
    // const documents = getState().console.documents
    //   .filter((document) => document.type=='DATA'&&Object.keys(document.dataTable).includes('status'))
    //   .map(document => document)
    // if (documents.length>0) {
    //   dispatch(setLocationTypeKey(documents[0].dataTable.status[0].locationTypeKey))
    // }
  }
}

/**
**
** FEATURES
**
***/

// export const setPlaceholderFeatureUUID = (featureUUID) => ({
//   type: 'SET_PLACEHOLDER_FEATURE_UUID',
//   featureUUID
// })
export const unsetFeatureUUID = () => ({
  type: 'UNSET_FEATURE_UUID'
})
export const setFeatureUUID = (featureUUID) => ({
  type: 'SET_FEATURE_UUID',
  featureUUID
})
export const setFeature = (feature) => ({
  type: 'SET_FEATURE',
  feature
})
export const setFeatureAnimate = (feature) => {
  return dispatch => {
    dispatch(setFeatureUUID(feature.uuid))
    dispatch(updateViewport({
      longitude: feature.location.lng,
      latitude: feature.location.lat,
      zoom: 12,
      // transitionDuration: 5000,
      // transitionInterpolator: new FlyToInterpolator(),
      // transitionEasing: d3.easeCubic
    }))
  }
}

export const setPlaceAnimate = (place, fly=false) => {
  return (dispatch, getState) => {
    dispatch(setPlaceId(place.id))
    if (place.location && place.location.lat && place.location.lng) {
      const currentViewport = getState().console.viewport;
      var viewport = {
        longitude: place.location.lng,
        latitude: place.location.lat,
        zoom: ((currentViewport.zoom > 15) ? currentViewport.zoom : 15)
      };
      // if (fly) {
      //   viewport = {
      //     ...viewport,
      //     transitionDuration: 5000,
      //     transitionInterpolator: new FlyToInterpolator(),
      //     transitionEasing: d3.easeCubic
      //   }
      // }
      dispatch(updateViewport(viewport))
    }
  }
}

export const setFeatureAnimateInfomap = (feature) => {
  return dispatch => {
    dispatch(setFeatureUUID(feature.uuid))
    dispatch(updateInfomapViewport({
      longitude: feature.location.lng,
      latitude: feature.location.lat,
      zoom: 12,
      // transitionDuration: 5000,
      // transitionInterpolator: new FlyToInterpolator(),
      // transitionEasing: d3.easeCubic
    }))
  }
}

export const setPlaceAnimateInfomap = (place) => {
  return dispatch => {
    dispatch(setPlaceId(place.id))
    if (place.location && place.location.lat && place.location.lng) {
      dispatch(updateInfomapViewport({
        longitude: place.location.lng,
        latitude: place.location.lat,
        zoom: 12,
        // transitionDuration: 5000,
        // transitionInterpolator: new FlyToInterpolator(),
        // transitionEasing: d3.easeCubic
      }))
    }
  }
}

export const setFeatureAnimateBookmarkView = (feature) => {
  return dispatch => {
    dispatch(setFeatureUUID(feature.uuid))
    dispatch(updateBookmarkViewViewport({
      longitude: feature.location.lng,
      latitude: feature.location.lat,
      zoom: 12,
      // transitionDuration: 5000,
      // transitionInterpolator: new FlyToInterpolator(),
      // transitionEasing: d3.easeCubic
    }))
  }
}

export const setPlaceAnimateBookmarkView = (place) => {
  return dispatch => {
    dispatch(setPlaceId(place.id))
    dispatch(updateBookmarkViewViewport({
      longitude: place.location.lng,
      latitude: place.location.lat,
      zoom: 12,
      // transitionDuration: 5000,
      // transitionInterpolator: new FlyToInterpolator(),
      // transitionEasing: d3.easeCubic
    }))
  }
}

export const setFeatureAnimatePublisherMap = (feature) => {
  return dispatch => {
    dispatch(setFeatureUUID(feature.uuid))
    dispatch(updatePublisherMapViewport({
      longitude: feature.location.lng,
      latitude: feature.location.lat,
      zoom: 12,
      // transitionDuration: 5000,
      // transitionInterpolator: new FlyToInterpolator(),
      // transitionEasing: d3.easeCubic
    }))
  }
}

export const setPlaceAnimatePublisherMap = (place) => {
  return dispatch => {
    dispatch(setPlaceId(place.id))
    dispatch(updatePublisherMapViewport({
      longitude: place.location.lng,
      latitude: place.location.lat,
      zoom: 12,
      // transitionDuration: 5000,
      // transitionInterpolator: new FlyToInterpolator(),
      // transitionEasing: d3.easeCubic
    }))
  }
}

export const unsetFeature = () => ({
  type: 'UNSET_FEATURE'
})

export const resetFeatures = (mapUUID) => {
  //console.log("action.appendFeatures mapUUID =>", mapUUID);
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/features/${mapUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      dispatch({
        type: 'RESET_FEATURES',
        //features: response.data.features
        features: response.data.features.map((feature) => ({
          ...feature,
          polygon: circle([feature.location.lng, feature.location.lat], 0.03, {steps: 10, units: 'miles'})
        }))
      })
    })
  }
}

export const appendFeatures = (mapUUID) => {
  //console.log("action.appendFeatures mapUUID =>", mapUUID);
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/features/${mapUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      dispatch({
        type: 'APPEND_FEATURES',
        features: response.data.features.map((feature) => ({
          ...feature,
          polygon: circle([feature.location.lng, feature.location.lat], 0.03, {steps: 10, units: 'miles'})
        }))
        // features: response.data.features
        // features: response.data.features.map((feature) => ({
        //   ...feature,
        //   point: point([feature.location.lat,feature.location.lng])
        // }))
      })
    })
  }
}

export const fetchFeatures = (mapUUID) => {
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/features/${mapUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      dispatch({
        type: 'FETCH_FEATURES',
        features: response.data.features.map((feature) => ({
          ...feature,
          polygon: circle([feature.location.lng, feature.location.lat], 0.03, {steps: 10, units: 'miles'})
        }))
        // features: response.data.features
        // features: response.data.features.map((feature) => ({
        //   ...feature,
        //   point: point([feature.location.lat,feature.location.lng])
        // }))
      })
    })
  }
}


export const createFeature = (feature) => {
  console.log('action.createFeature feature => ', feature);
  return (dispatch, getState) => {
    //const state = getState();
    const currentFeature = [];//state.console.features.filter((f) => f.location).filter((f) => f.location.place_id==feature.placeID)
    if (currentFeature.length===0 && feature && feature.mapUUID) {
      axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/features/${feature.mapUUID}`, feature)
      .then(response => {
        dispatch({
          type: 'CREATE_FEATURE',
          submitedAt: Date.now(),
        })
        dispatch( setLoading( { blocking: true } ) )
        setTimeout(() => {
          dispatch( appendFeatures(feature.mapUUID) )
          dispatch( setLoading( { blocking: false } ) )
          dispatch( setFeatureAnimate({
            ...feature,
            location: {
              lat: feature.lat,
              lng: feature.lng
            }
          }) )
          dispatch( setFeatureAnimatePublisherMap({
            ...feature,
            location: {
              lat: feature.lat,
              lng: feature.lng
            }
          }) )
        }, 500)
      })
      .catch(err => {
        NotificationManager.error('Error', 'Create Feature Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Create Feature Error A129', 100000);
      dispatch( setError({message: "Feature already exists"}) )
    }
  }
}

export const deleteFeature = (feature, options={notification: true}) => {
  console.log("action.deleteFeature feature => ", feature);
  return (dispatch, getState) => {
    if (feature && feature.featureUUID && feature.mapUUID) {
      axios.delete(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/features/${feature.mapUUID}`, { data: {
        uuid: feature.featureUUID,
        mapUUID: feature.mapUUID
      }})
      .then(response => {
        if (options.notification) {
          NotificationManager.warning('Feature deleted', 'Delete Feature');
        }
        dispatch({
          type: 'DELETE_FEATURE',
          submitedAt: Date.now(),
          feature: {
            ...feature,
            uuid: feature.featureUUID
          }
        })
        getState().console.documents.filter(doc => doc.featureUUID===feature.featureUUID).forEach((doc) => {
          dispatch( trashDocument( doc ) );
        })
        // setTimeout(() => {
        //   NotificationManager.warning('Feature deleted', 'Delete Feature');
        //   dispatch( resetAccount( getState().session.account.uuid, { resetFeatures: true } ) );
        // }, 500)
      })
      .catch(err => {
        NotificationManager.error('Error', 'Delete Feature Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Delete Feature Error A129', 100000);
    }
  }
}


/**
**
** SEEDS
**
***/

// onSubmit={(values) => {
//   console.log(values);
//   this.props.createDocument({
//     documentType: "LINK",
//     accountUUID: this.props.match.params.accountUUID,
//     mapUUID: this.props.match.params.mapUUID,
//     featureUUID: this.props.console.feature.uuid,
//     uuid: uuidv4(),
//     title: values.title,
//     url: values.url
//   });
//   store.dispatch({type: 'CANCEL_LINK'});
// }}

export const onDataPublish = ({mapType, locationType, locationTypeKey, dataType, snapshotAt, values}) => {

  // console.log('mapType => ', mapType);
  // console.log('locationType => ', locationType);
  // console.log('locationTypeKey => ', locationTypeKey);
  // console.log('dataType => ', dataType);
  // console.log('snapshotAt => ', snapshotAt);
  // console.log('onDataPublish => ', values);
  return (dispatch, getState) => {

    var account = getState().session.account;
    var mapUUID = getState().console.mapUUID;
    var featureUUID = getState().console.featureUUID;

    dispatch(setSeedPublisherProgress({blocking: true, progress: 50}));

    console.log(dataType);
     console.log(values);

    var dataTable = {};
    dataTable[dataType.key] = Object.keys(values).map((key) => {
      let field = dataType.fields.find((field) => field.key===key)
      return {
        key: field.key,
        title: field.title,
        value: values[key],
        snapshotAt,
        locationTypeKey
      }
    })

    //dispatch({type: 'CANCEL_FILE'});
    // dispatch( setLoading( { blocking: true } ) )
    // dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: 0});
    dispatch(createDocument({
      documentType: "DATA",
      author: {
        uuid: account.uuid,
        firstName: account.firstName,
        lastName: account.lastName
      },
      //accountUUID,
      mapUUID,
      featureUUID,
      uuid: uuidv4(),
      title: dataType.title,
      createdAt: moment().utc(),
      //url: values.url
      dataTable
    }))
    //dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: 100});

    dispatch(setSeedPublisherProgress({blocking: false, progress: 0}));
  }
}

export const onLinkPublish = (values) => {
  return (dispatch, getState) => {

    var account = getState().session.account;
    var mapUUID = getState().console.mapUUID;
    var featureUUID = getState().console.featureUUID;

    //dispatch({type: 'CANCEL_FILE'});
    //dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: 0});
    dispatch(setSeedPublisherProgress({blocking: true, progress: 50}));
    dispatch(createDocument({
      documentType: "LINK",
      author: {
        uuid: account.uuid,
        firstName: account.firstName,
        lastName: account.lastName
      },
      //accountUUID: accountUUID,
      mapUUID: mapUUID,
      featureUUID: featureUUID,
      uuid: uuidv4(),
      title: values.url,
      createdAt: moment().utc(),
      url: values.url
    }))
    //dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: 100});
    dispatch(setSeedPublisherProgress({blocking: false, progress: 0}));
  }
}

export const onFilePublish = (files) => {
  return (dispatch, getState) => {
    //dispatch({type: 'CANCEL_FILE'});
    dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: 0});

    var completeCount = 0;
    var account = getState().session.account;
    var mapUUID = getState().console.mapUUID;
    var featureUUID = getState().console.featureUUID;

    files.forEach((file) => {
      console.log('SeedPublisher file => ', file);
      completeCount += 1;
      //dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: ((completeCount/files.length*2)*100)});
      dispatch(setSeedPublisherProgress({blocking: true, progress: 50}));

      var documentUUID = uuidv4();
      var key = `${mapUUID}*${documentUUID}`;
      //console.log(key);

      axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/authorize`, {
        operation: 'putObject',
        key: key
      }).then(auth => {
        // console.log(auth.data.url);
        // console.log(JSON.stringify(file));
        // console.log("onFilePublish => ", file);
        var fileName = encodeURI(file.name);
        fileName = fileName.replace(/%20/g, "_");
        fileName = fileName.replace(/&/g, "and");
        fileName = fileName.replace(/'/g, "");
        fileName = fileName.replace(/./g, "");
        fileName = fileName.replace(/#/g, "");
        console.log(fileName);
        var headers = {
          'Content-Type': file.type,
          'x-amz-tagging': `name=${fileName}&size=${file.size}&mapUUID=${mapUUID}&documentUUID=${documentUUID}&lastModified=${file.lastModified}`,
          //'x-amz-content-sha256': 'foo'
        }
        //params[''] = crypto.createHash('sha256').update('Apple').digest("hex");
        console.log("Push is authorized => ", auth.data.url);
        axios.put(auth.data.url, file, {
          headers
        }).then(response => {
          console.log("onFilePublish created response code => ", response.status);
          // console.log("onFilePublish created account => ", account);
          // console.log("onFilePublish created mapUUID => ", mapUUID);
          // console.log("onFilePublish created featureUUID => ", featureUUID);
          // console.log("onFilePublish created documentUUID => ", documentUUID);
          // console.log("onFilePublish created key => ", key);
          dispatch(createDocument({
            documentType: file.type,
            author: {
              uuid: account.uuid,
              firstName: account.firstName,
              lastName: account.lastName
            },
            mapUUID: mapUUID,
            featureUUID: featureUUID,
            uuid: documentUUID,
            title: ((file && file.name) ? file.name : key),
            createdAt: moment().utc(),
            url: key
          }))
          completeCount += 1;
          //dispatch({type: 'SET_FETCHING_PROGRESS', fetchingProgress: ((completeCount/files.length*2)*100)});
          dispatch(setSeedPublisherProgress({blocking: true, progress: 100}));
          dispatch(setSeedPublisherProgress({blocking: false, progress: 0}));
          dispatch(setSeedPublisherFileSuccess());
        }).catch(function (err) {
          console.log("SeedPublisher file put failed");
          console.log(err);
        });
      })
    })
  }
}

export const toggleDocumentAnalysis = (document) => ({
  type: 'TOGGLE_DOCUMENT_ANALYSIS',
  document
})

export const authorizeDocument = (document) => {
  return dispatch => {
    axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/authorize`, {
      operation: 'getObject',
      key: `${document.mapUUID}*${document.uuid}`
    }).then(auth => {
      //console.log(auth.data.url);
    });
  }
}

export const viewDocument = (document) => {
  const openNewTabTypes = ['application/pdf','PDF','image/jpg','image/jpeg','image/png'];
  if (openNewTabTypes.includes(document.type)) {
    var windowRef = window.open('https://www.geotiller.com/loading','_blank');
  }

  return dispatch => {
    axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/authorize`, {
      operation: 'getObject',
      key: `${document.mapUUID}*${document.uuid}`
    }).then(auth => {
      console.log(auth.data.url);
      if (openNewTabTypes.includes(document.type)) {
        windowRef.location = auth.data.url;
      } else {
        window.location = auth.data.url;
        //window.open(auth.data.url, "_blank");
      }
    });
  }
}

export const fetchDocuments = (mapUUID) => {
  console.log("action.fetchDocuments mapUUID =>", mapUUID);
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/documents/${mapUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      console.log(response.data);
      if (response.data && response.data.documents) {
        dispatch({
          type: 'RESET_DOCUMENTS',
          documents: response.data.documents
        })
      }
    })
  }
}

export const appendDocuments = (mapUUID) => {
  console.log("action.appendDocuments mapUUID =>", mapUUID);
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/documents/${mapUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      //console.log("appendDocuments response =>", response);
      if (response.data && response.data.documents) {
        dispatch({
          type: 'APPEND_DOCUMENTS',
          documents: response.data.documents
        })
      }
    })
  }
}

export const resetDocuments = (mapUUID) => {
  console.log("action.resetDocuments mapUUID =>", mapUUID);
  return dispatch => {
    axios.get(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/documents/${mapUUID}`,{
      responseType: 'json'
    })
    .then(response => {
      console.log(response.data);
      if (response.data && response.data.documents) {
        dispatch({
          type: 'RESET_DOCUMENTS',
          documents: response.data.documents
        })
      }
    })
  }
}

export const createDocument = (document) => {
  console.log("action.createDocument document => ", document);
  return (dispatch, getState) => {
    if (document && document.mapUUID && document.featureUUID) {
      axios.post(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/documents/${document.mapUUID}/${document.featureUUID}`, document)
      .then(response => {
        console.log({document});
        dispatch({
          type: 'APPEND_DOCUMENTS',
          documents: [
            {
              uuid: document.uuid,
              type: document.documentType,
              author: document.author,
              mapUUID: document.mapUUID,
              featureUUID: document.featureUUID,
              title: document.title,
              isTrashed: false,
              url: document.url,
              createdAt: document.createdAt,
              dataTable: document.dataTable,
              metadata: {},
              og: {
                image: "",
                site_name: ""
              },
              addressTopics: [],
              fileTypeTopics: [],
              assetTypeTopics: [],
              feature: {}
            }
          ]
        })
        dispatch({
          type: 'SUBMIT_DOCUMENT',
          submitedAt: Date.now(),
          document: {
            ...document,
            type: document.documentType,
            isTrashed: false,
            dataTable: {},
            metadata: {},
            og: {image: "", site_name: ""},
            addressTopics: [],
            fileTypeTopics: [],
            assetTypeTopics: [],
            feature: {}
          }
        })
        // setTimeout(() => {
        //   dispatch(resetDocuments(document.mapUUID));
        // }, 500)
      })
      .catch(err => {
        NotificationManager.error('Error', 'Create Document Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Create Document Error A129', 100000);
    }
  }
}

export const trashDocument = (document, reset=true) => {
  console.log("trashDocument document => ", document);
  return (dispatch, getState) => {
    if (document && document.mapUUID && document.featureUUID) {
      axios.delete(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/documents/${document.mapUUID}/${document.featureUUID}`, { data: document })
      .then(response => {
        NotificationManager.warning('Document deleted', 'Delete Document');
        dispatch({
          type: 'TRASH_DOCUMENT',
          submitedAt: Date.now(),
          document
        })
      })
      .catch(err => {
        NotificationManager.error('Error', 'Trash Document Error A128', 100000);
      })
    } else {
      NotificationManager.error('Error', 'Trash Document Error A129', 100000);
    }
  }
}


export const updateDocumentTitle = (document, title) => {
  return dispatch => {
    dispatch(updateDocument({
      ...document,
      updatedAt: "updatedAt",
      title
    }))
  }
}

export const updateDocument = (document) => {
  console.log("action.updateDocument document => ", document);
  return dispatch => {
    axios.put(`https://9hkfyjpje6.execute-api.us-west-2.amazonaws.com/prod/documents/${document.mapUUID}`, document)
    .then(response => {
      dispatch({
        type: 'UPDATE_DOCUMENT',
        submitedAt: Date.now(),
        document
      })
    })
  }
}
