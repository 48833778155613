import React, {Component} from 'react'
//import PropTypes from 'prop-types'

import LocationIcon from './../assets/zondicons/location.svg';

import './PropertyMiniPopup.css';

class PropertyMiniPopup extends Component {

  render() {

    const {
      place
    } = this.props;


    return (
      <div className='PropertyMiniPopup'>
        <div className='PropertyMiniPopup__header'>
          <h2>{place.name}</h2>
          <h5><img alt='Location' src={LocationIcon} /> {place.title}</h5>
        </div>
      </div>
    );
  }
}


export default PropertyMiniPopup;
