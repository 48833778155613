import { withRouter } from 'react-router-dom';
import React, {Component} from 'react'

import { Switch, Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux'

import AcceptHoc from './AcceptHoc'
import LoginHoc from './LoginHoc'
//import ConsoleHoc from './ConsoleHoc'
import ProfileHoc from './ProfileHoc'
import InfoMapHoc from './InfoMapHoc'
import GeoDesktopHoc from './GeoDesktopHoc'
import DashboardHoc from './DashboardHoc'
import SeedPublisherHoc from './SeedPublisherHoc'

//import { withAuthenticator } from 'aws-amplify-react';
import Amplify, {Auth} from 'aws-amplify';

import {
  setToken,
  setAccountUUID,
  //resetAccount,
  setTenant,
} from '../actions/session';

import {
  getAccount
} from '../graphql/getAccount';

import appConfig from './../config'

Amplify.configure({
  Auth: {
    identityPoolId: 'us-west-2:95a756c8-9e12-4952-9f53-adc00ff23e37', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-west-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-west-2_vtGJfSQTS', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '7ko7ln4tunafrup1vstdbo0dej', //OPTIONAL - Amazon Cognito Web Client ID
  },
  aws_appsync_graphqlEndpoint: appConfig.GraphQLEndpoint,
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

// const PrivateRoute = ({ path, component: Component, props}) => (
//   <Route path={path} render={({match}) => {
//     return (
//       props.token.accessToken.jwtToken
//         ? <Component {...props} match={match} />
//         : <Redirect to='/users/sign_in' />
//     )
//   }} />
// )

class ProtectedApp extends Component {

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      accessToken: null
    }
    this.handleMount = (authenticateUser) => this._handleMount(authenticateUser);
  }

  componentDidMount() {
    this._isMounted = true;

    // look for subdomain
    const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : 'default';

    this.props.setTenant(subdomain);

    Auth.currentAuthenticatedUser().then(
      this.handleMount
    ).catch((err) => {
      console.log(err);
      this.setState({
        accessToken: 'login'
      })
    })
  }

  _handleMount(authenticateUser) {
    if (!this._isMounted || !authenticateUser) return

    this.setState({
      accessToken: authenticateUser.signInUserSession.accessToken.jwtToken
    })
    if (authenticateUser.username) {
      this.props.setAccountUUID(authenticateUser.username);//user.username);
      this.props.setToken(authenticateUser.signInUserSession)
      //this.props.resetAccount(authenticateUser.username, {selectMaps: true});
      //this.props.getAccount(authenticateUser.username, {selectMaps: true})
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    const { accessToken } = this.state;

    return (
      (accessToken) ? (
        (accessToken==='login') ?
          <Switch>
            <Route path="*" component={LoginHoc}/>
          </Switch>
        :
        <Switch>
          <Route exact path='/'>
            <Redirect to={'/desktop'} />
          </Route>
          <Route exact path='/users/sign_up/:referringAccountUUID?/:referringMapUUID?'>
            <Redirect to={'/desktop'} />
          </Route>
          <Route exact path='/users/sign_in'>
            <Redirect to={'/desktop'} />
          </Route>
          <Route exact path='/desktop/accept/:mapAccountUUID/:mapUUID/:token' component={AcceptHoc} props={this.props}/>
          <Route exact path='/users/sign_out' component={LoginHoc} />
          <Route exact path='/reset' component={LoginHoc} />
          <Route exact path='/account/:accountUUID?' component={ProfileHoc} />
          <Route path="/home/:mapUUID?/:featureUUID?" component={DashboardHoc} props={this.props}/>
          <Route path="/map/:mapUUID" component={GeoDesktopHoc} props={this.props}/>
          <Route path="/desktop/:accountUUID?/:placeId?/:view?" component={GeoDesktopHoc} props={this.props}/>
          <Route path="/publisher/:flagType/:accountUUID?/:mapUUID?/:placeId?" component={SeedPublisherHoc} props={this.props}/>
          <Route path="/infomap/:accountUUID?/:mapUUID?" component={InfoMapHoc} props={this.props}/>
        </Switch>
      ) : <div></div>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
    console: state.console,
    session: state.session,
    token: state.session.token
  };
}

const mapDispatchToProps = {
  setToken,
  setAccountUUID,
  //resetAccount,
  getAccount,
  setTenant,
}

const ProtectedAppHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedApp);

//export default withAuthenticator(withRouter(ProtectedAppHoc))

export default withRouter(ProtectedAppHoc)
