export default {
  "dataTypes": [{
      "key": "hotel_type",
      "title": "Hotel Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Full Service", "Limited Service", "Boutique", "Suite", "Extended-Stay", "Hostel"]
      }]
    },
    {
      "key": "hotel_rank",
      "title": "Hotel Rank",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Luxury", "Upper-Upscale", "Upscale", "Upper-MidScale", "Mid-Scale", "Economy"]
      }]
    },
    {
      "key": "key_count",
      "title": "Key Count",
      "fields": [{
        "key": "key_count",
        "title": "Key Count",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "hotel_brand",
      "title": "Hotel Brand",
      "fields": [{
        "key": "hotel_brand",
        "title": "Hotel Brand",
        "type": "string"
      }]
    },
    {
      "key": "ADR",
      "title": "ADR",
      "fields": [{
        "key": "ADR",
        "title": "ADR",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "revpar",
      "title": "RevPAR",
      "fields": [{
        "key": "revpar",
        "title": "RevPAR",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      }, {
        "key": "sale_price_per_key",
        "title": "Price per Key",
        "type ": "currency_without_decimal"
      }, {
        "key": "cap_rate",
        "title": "Cap Rate",
        "type ": "percentage_2_decimal"
      }, {
        "key": "percent_occupied",
        "title": "Occupancy at Sale",
        "type ": "percentage_2_decimal"
      }, {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string "
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
