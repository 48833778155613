export default {
  "dataTypes": [{
      "key": "mixeduse_type",
      "title": "Mixed-Use Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Commercial", "Commercial/Residential", "Commercial/Hotel", "Residential/Hotel", "Commercial/Residential/Hotel"]
      }]
    },
    {
      "key": "apartment_unit_count",
      "title": "Apt. Unit Count",
      "fields": [{
        "key": "unit_count",
        "title": "Apt. Units",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "apartment_square_footage",
      "title": "Apt. Square Footage",
      "fields": [{
        "key": "apt_square_footage",
        "title": "Apt. Square Footage",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "average_apt_unit_size",
      "title": "Average Apt. Unit Size",
      "fields": [{
        "key": "unit_size",
        "title": "Average Apt. Unit Size",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "apartment_average_rent",
      "title": "Average Rent (gross)",
      "fields": [{
        "key": "average_unit_rent",
        "title": "Average Rent per Unit",
        "type": "currency_without_decimal"
      }]
    },
    {
      "key": "apt_lease_comp",
      "title": "Apt. Lease Comp",
      "fields": [{
        "key": "apt_lease_date",
        "title": "Apt. Lease Date",
        "type ": "string"
      }, {
        "key": "apt_unit_type",
        "title": "Apt. Unit Type",
        "fields": [{
          "key": "type",
          "title": "Apt. Unit Type",
          "type": "select",
          "choices": ["0x1", "1x1", "2x1", "2x2", "3x2", "3x3", "4+"]
        }]
      }, {
        "key": "apt_lease_rent",
        "title": "Apt Lease Rent (gross)",
        "type": "currency_without_decimal"
      }, {
        "key": "lease_square_footage",
        "title": "Lease Square Footage",
        "type ": "number_with_comma"
      }, {
        "key": "lease_rent_psf",
        "title": "Lease Rent PSF",
        "type ": "currency_with_decimal"
      }, {
        "key": "lease_term",
        "title": "Lease Term (months)",
        "type ": "number_with_comma"
      }, {
        "key": "rent_concession",
        "title": "Rent Concession (months)",
        "type ": "number_with_comma"
      }, {
        "key": "lease_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    },
    {
      "key": "office_square_footage",
      "title": "Office Square Footage",
      "fields": [{
        "key": "office_square_footage",
        "title": "Office Square Footage",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "average_office_rent",
      "title": "Average Office Rent (psf/mo FSG)",
      "fields": [{
        "key": "rent",
        "title": "Average Rent (psf/mo FSG)",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "office_lease_comp",
      "title": "Office Lease Comp",
      "fields": [{
        "key": "tenant",
        "title": "Tenant Name",
        "type ": "string"
      }, {
        "key": "lease_date",
        "title": "Lease Date",
        "type": "string"
      }, {
        "key": "lease_square_footage",
        "title": "Lease Square Footage",
        "type ": "number_with_comma"
      }, {
        "key": "lease_type",
        "title": "Lease Type (New, Renewal)",
        "type ": "string"
      }, {
        "key": "lease_rate",
        "title": "Lease Rate (psf/mo)",
        "type": "currency_with_decimal"
      }, {
        "key": "reimbursement_method",
        "title": "Reimb. Method (e.g. FSG)",
        "type": "currency_with_decimal"
      }, {
        "key": "lease_term",
        "title": "Lease Term (months)",
        "type ": "number_with_comma"
      }, {
        "key": "rent_escalations",
        "title": "Rent Escalations",
        "type ": "percentage_2_decimal"
      }, {
        "key": "rent_abatement",
        "title": "Rent Abatement (months)",
        "type ": "number_with_comma"
      }, {
        "key": "ti_allowance",
        "title": "TI Allowance (PSF)",
        "type ": "currency_without_decimal"
      }, {
        "key": "cams",
        "title": "CAMs (psf/mo)",
        "type ": "currency_with_decimal"
      }, {
        "key": "tenant_broker",
        "title": "Tenant Broker",
        "type ": "string"
      }, {
        "key": "landlord_broker",
        "title": "Landlord Broker",
        "type ": "string"
      }, {
        "key": "lease_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    },
     {
      "key": "retail_square_footage",
      "title": "Retail Square Footage",
      "fields": [{
        "key": "retail_square_footage",
        "title": "Retail Square Footage",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "average_retail_rent",
      "title": "Average Retail Rent (psf/mo NNN)",
      "fields": [{
        "key": "rent",
        "title": "Average Rent (psf/mo NNN)",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "retail_lease_comp",
      "title": "Retail Lease Comp",
      "fields": [{
        "key": "tenant",
        "title": "Tenant Name",
        "type ": "string"
      }, {
        "key": "lease_date",
        "title": "Lease Date",
        "type": "string"
      }, {
        "key": "lease_square_footage",
        "title": "Lease Square Footage",
        "type ": "number_with_comma"
      }, {
        "key": "lease_type",
        "title": "Lease Type (New, Renewal)",
        "type ": "string"
      }, {
        "key": "lease_rate",
        "title": "Lease Rate (psf/mo)",
        "type": "currency_with_decimal"
      }, {
        "key": "reimbursement_method",
        "title": "Reimb. Method (e.g. NNN)",
        "type": "string"
      }, {
        "key": "lease_term",
        "title": "Lease Term (months)",
        "type ": "number_with_comma"
      }, {
        "key": "rent_escalations",
        "title": "Rent Escalations",
        "type ": "percentage_2_decimal"
      }, {
        "key": "percentage_rent",
        "title": "Percentage Rent",
        "type ": "percentage_2_decimal"
      }, {
        "key": "rent_abatement",
        "title": "Rent Abatement (months)",
        "type ": "number_with_comma"
      }, {
        "key": "ti_allowance",
        "title": "TI Allowance (PSF)",
        "type ": "currency_without_decimal"
      }, {
        "key": "cams",
        "title": "CAMs (psf/mo)",
        "type ": "currency_with_decimal"
      }, {
        "key": "tenant_broker",
        "title": "Tenant Broker",
        "type ": "string"
      }, {
        "key": "landlord_broker",
        "title": "Landlord Broker",
        "type ": "string"
      }, {
        "key": "lease_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    },
    {
      "key": "hotel_key_count",
      "title": "Hotel Key Count",
      "fields": [{
        "key": "hotel_key_count",
        "title": "Hotel Key Count",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "hotel_brand",
      "title": "Hotel Brand",
      "fields": [{
        "key": "hotel_brand",
        "title": "Hotel Brand",
        "type": "string"
      }]
    },
    {
      "key": "hotel_ADR",
      "title": "Hotel ADR",
      "fields": [{
        "key": "hotel_ADR",
        "title": "Hotel ADR",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "hotel_occupancy",
      "title": "Hotel Occupancy",
      "fields": [{
        "key": "hotel_occupancy",
        "title": "Hotel Occupancy",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "hotel_revpar",
      "title": "Hotel RevPAR",
      "fields": [{
        "key": "hotel_revpar",
        "title": "Hotel RevPAR",
        "type": "currency_with_decimal"
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      }, {
        "key": "cap_rate",
        "title": "Cap Rate",
        "type ": "percentage_2_decimal"
      }, {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string "
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
