import React, {Component} from 'react'

import LocationIcon from './../../assets/zondicons/location.svg';
import RightChevron from './../../assets/zondicons/cheveron-right.svg';

class FeatureList extends Component {

  componentDidMount() {
    const { map, filteredFeatures, setFeatureUUID } = this.props;
    console.log('FeatureList map =>', map);
  }

  render() {
    const { map, filteredFeatures, setFeatureUUID } = this.props;
    return (
      <div className='Home__mainPanel'>
        <div className='Home__placePane'>
          <div className='GeoSeeds__header-title'>
            <h5>{map.title}</h5>
          </div>
        </div>
        <div className="Home__placePane">
          <table>
            <thead></thead>
            <tbody>
              {filteredFeatures.map(feature => (
                <tr className='MapItem' key={feature.uuid} onClick={() => {
                  setFeatureUUID(feature.uuid);
                }}>
                  <td>
                    <div className='cell'>
                      <h5>{feature.title}</h5>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default FeatureList;
