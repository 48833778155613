export default {
  "dataTypes": [{
      "key": "location_notes",
      "title": "Location Notes",
      "fields": [{
        "key": "note_entry",
        "title": "Note Entry",
        "type": "textarea"
      }]
    },{
      "key": "lifecycle",
      "title": "Lifecycle",
      "fields": [{
        "key": "lifecycle_selection",
        "title": "Lifecycle Selection",
        "type": "select",
        "choices": [""]
      }]
    },{
      "key": "status",
      "title": "Status",
      "fields": [{
        "key": "status_selection",
        "title": "Status Selection",
        "type": "select",
        "choices": ["Interested", "Visited"]
      }]
    },{
      "key": "building_name",
      "title": "Location Name",
      "fields": [{
        "key": "building_name",
        "title": "Name",
        "type": "string"
      }]
    },
    {
      "key": "owner",
      "title": "Owner",
      "fields": [{
        "key": "owner",
        "title": "Owner Name",
        "type": "string"
      }]
    },
    {
      "key": "year_of_construction",
      "title": "Year of Construction/Renovation",
      "fields": [{
        "key": "yoc",
        "title": "Year",
        "type": "date_year"
      }]
    },
    {
      "key": "occupancy",
      "title": "Occupancy",
      "fields": [{
        "key": "percent_occupied",
        "title": "% Occupied",
        "type": "percentage_2_decimal"
      }]
    }
  ]
}
