export default {
  "title": null,

  // background image on login screen
  "homepageBackgroundImg": "/homepage/panoramic_view.jpg",
  
  "callToAction": {
    "backgroundColor": "#4098D7",
  },

  // various labels for models
  "nomenclature": {
    "partition": {
      "singular": "Project",
      "plural": "Projects"
    }
  },

  // enable ability to select more than one map
  "mapMultiselect": false,

  // enable map icon clusters
  "mapClusters": false,
}
