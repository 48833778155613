import React, {Component} from 'react'
//import PropTypes from 'prop-types'

import LocationIcon from './../assets/zondicons/location.svg';

import './PropertyPopup.css';

class PropertyPopup extends Component {

  constructor(props) {
    super(props);
    const { viewButton, statsPanel } = this.props;
    this.state = {
      viewButton: viewButton ? true : false,
      statsPanel: statsPanel ? true : false
    };
  }

  render() {

    const {
      //feature,
      //setDocPaneListView,
      //setFeatureUUID,
      place,
      onViewClick,
      isRegularAccountType,
    } = this.props;

    const {
      viewButton,
      statsPanel
    } = this.state;

    return (
      <div className='PropertyPopup'>
        <div className='PropertyPopup__header'>
          <div className='PropertyPopup__header-title'>
            <h2>{place.name}</h2>
            {viewButton && <div className='PropertyPopup__cta'>
              <button onClick={onViewClick}>View</button>
            </div>}
          </div>
          <h5><img alt='Location' src={LocationIcon} /> {place.title}</h5>
        </div>
        <div className='PropertyPopup__content'>
          {false && <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>}
        </div>
        {statsPanel && !isRegularAccountType && <div className='PropertyPopup__footer'>
          <div className='PropertyPopup__footer-item'>
            <h5 className='key'>Jobs</h5>
            <h2 className='val'>{(place.jobs) ? place.jobs.length : 0}</h2>
          </div>
        </div>}
        {statsPanel && isRegularAccountType && <div className='PropertyPopup__footer'>
          {/* {false && <div className='PropertyPopup__footer-item'>
            <h5 className='key'>Maps</h5>
            <h2 className='val'>{(place.maps) ? place.maps.length : 0}</h2>
          </div>} */}
          <div className='PropertyPopup__footer-item'>
            <h5 className='key'>Files</h5>
            <h2 className='val'>{(place.files) ? place.files.length : 0}</h2>
          </div>
          <div className='PropertyPopup__footer-item'>
            <h5 className='key'>Data</h5>
            <h2 className='val'>{(place.data) ? place.data.length : 0}</h2>
          </div>
          <div className='PropertyPopup__footer-item'>
            <h5 className='key'>Links</h5>
            <h2 className='val'>{(place.links) ? place.links.length : 0}</h2>
          </div>
          <div className='PropertyPopup__footer-item'>
            <h5 className='key'>Photos</h5>
            <h2 className='val'>{(place.photos) ? place.photos.length : 0}</h2>
          </div>
        </div>}
      </div>
    );
  }
}


export default PropertyPopup;
