import { withRouter } from 'react-router-dom';

import Dashboard from '../components/Dashboard/Dashboard'

import { connect } from 'react-redux'

import {
  getFeedPlacesByMapUUID,
  getFilteredDocuments,
  getFeatureDocuments,
  //getFeatureDocumentsByType,
  getPlaceDocumentsByType,
  getFeature,
  getPlace,
  getFilteredFeatures,
  getFilteredFeaturesByPlaceId,
  getFilteredMaps,
  getSelectedMaps,
  getSelectedMap,
  getMapsByUUID,
  getWritableMaps,
  getFilteredBookmarks,
  getActiveBookmarks,
  getFeedPlaces,
  getMapType,
  getStatusDataType,
  getLifecycleDataType,
  getLocationNameDataType,
  getPropertyStatusesForMap
  //getFeatures,
  //getDocumentsByMapAndFeature
} from '../selectors'

import {
  openMap,
  deselectAllMaps,
  setPlaceId,
  unsetPlaceId,
  appendMaps,
  appendFeatures,
  appendDocuments,
  setError,
  buildMap,
  //resetMaps,
  selectMap,
  deselectMap,
  updateMap,
  fetchFeatures,
  resetConsole,
  fetchDocuments,
  resetDocuments,
  updateViewport,
  setSubnav,
  toggleProfile,
  hideProfile,
  toggleSubnav,
  toggleBookmarkView,
  //setFeature,
  setPlaceAnimate,
  setFeatureAnimate,
  toggleDocumentAnalysis,
  //updateDocumentFileType,
  //updateDocumentAssetType,
  viewDocument,
  trashDocument,
  setDocPaneMapView,
  setDocPaneListView,
  setSidebarWidth,
  //unsetFeature,
  toggleAssetTypeFilter,
  toggleFileTypeFilter,
  toggleSeedPublisher,
  toggleInfomapEditor,
  //setSeedPublisherTab,
  setSidebarTab,
  setCardDeckTab,
  createFeature,
  setSidebarCreateFeature,
  onFilePublish,
  //setAccountUUID,
  createMap,
  setMapUUID,
  unsetMapUUID,
  setFeatureUUID,
  unsetFeatureUUID,
  setMapSearchResults,
  toggleDocumentConfigDropwdown,
  setInfomapHeight,
  updateInfomapViewport,
  setBookmarkViewHeight,
  updateBookmarkViewViewport,
  setFeatureAnimateBookmarkView,
  toggleUserDropdown,
  hideUserDropdown,
  toggleHamburger,
  getUserLocation,
  setLoading,
  setDesktopOpacity,
  updateMapStyle,
  toggleMediaGallery,
  updateDocumentTitle,
  deleteFeature,
  trashMap,
  onDataPublish,
  setPropertyStatusOrder,
  setSidebarCustomizePropertyStatus,
  togglePitchAndBearing,
  setPlaceMapHeight
} from '../actions/console';

import {
  getMaps
} from '../graphql/getMaps'

import {
  //resetAccount,
  resetSession,
  setAccountUUID
} from '../actions/session'

import {
  getAccount
} from '../graphql/getAccount'

import {
  createBookmark,
  resetBookmarks,
  toggleBookmark
} from '../actions/bookmark';

function mapStateToProps(state, ownProps) {
  return {
    //active: state.active,
    console: state.console,
    mapStyle: state.console.mapStyle,
    invertedMapStyle: state.console.invertedMapStyle,
    navigation: state.console.navigation,
    //feature: state.console.feature,
    account: state.session.account,
    accountUUID: state.session.account.uuid,
    mapUUID: state.console.mapUUID,
    featureUUID: state.console.featureUUID,
    filteredDocuments: getFilteredDocuments(state),
    featureDocuments: getFeatureDocuments(state),
    //featureDocumentsByType: getFeatureDocumentsByType(state),
    placeDocumentsByType: getPlaceDocumentsByType(state),
    feature: getFeature(state),
    place: getPlace(state),
    places: getFeedPlacesByMapUUID(state),
    placeId: state.console.placeId,
    filteredFeatures: getFilteredFeatures(state),
    filteredFeaturesByPlaceId: getFilteredFeaturesByPlaceId(state),
    filteredMaps: getFilteredMaps(state),
    selectedMaps: getSelectedMaps(state),
    selectedMap: getSelectedMap(state),
    mapType: getMapType(state),
    mapTypeKey: state.console.mapTypeKey,
    statusDataType: getStatusDataType(state),
    lifecycleDataType: getLifecycleDataType(state),
    locationNameDataType: getLocationNameDataType(state),
    mapsByUUID: getMapsByUUID(state),
    writableMaps: getWritableMaps(state),
    filteredBookmarks: getFilteredBookmarks(state),
    activeBookmarks: getActiveBookmarks(state),
    assetTypeTopics: state.console.assetTypeTopics,
    fileTypeTopics: state.console.fileTypeTopics,
    propertyStatuses: getPropertyStatusesForMap(state)
  };
}

const mapDispatchToProps = {
  openMap,
  deselectAllMaps,
  onDataPublish,
  updateDocumentTitle,
  setLoading,
  setPlaceId,
  unsetPlaceId,
  appendMaps,
  appendFeatures,
  appendDocuments,
  //resetAccount,
  getAccount,
  setError,
  buildMap,
  //resetMaps,
  getMaps,
  selectMap,
  deselectMap,
  updateMap,
  fetchFeatures,
  resetSession,
  resetConsole,
  fetchDocuments,
  resetDocuments,
  updateViewport,
  setSubnav,
  toggleProfile,
  hideProfile,
  toggleSubnav,
  toggleBookmarkView,
  //setFeature,
  setPlaceAnimate,
  setFeatureAnimate,
  toggleDocumentAnalysis,
  toggleSeedPublisher,
  toggleInfomapEditor,
  //setSeedPublisherTab,
  //updateDocumentFileType,
  //updateDocumentAssetType,
  viewDocument,
  trashDocument,
  setDocPaneMapView,
  setDocPaneListView,
  setSidebarWidth,
  //unsetFeature,
  toggleAssetTypeFilter,
  toggleFileTypeFilter,
  setSidebarTab,
  setCardDeckTab,
  createFeature,
  setSidebarCreateFeature,
  onFilePublish,
  setAccountUUID,
  createMap,
  setMapUUID,
  unsetMapUUID,
  setFeatureUUID,
  unsetFeatureUUID,
  setMapSearchResults,
  createBookmark,
  resetBookmarks,
  toggleDocumentConfigDropwdown,
  setInfomapHeight,
  updateInfomapViewport,
  toggleBookmark,
  setBookmarkViewHeight,
  updateBookmarkViewViewport,
  setFeatureAnimateBookmarkView,
  toggleUserDropdown,
  hideUserDropdown,
  toggleHamburger,
  getUserLocation,
  setDesktopOpacity,
  updateMapStyle,
  toggleMediaGallery,
  deleteFeature,
  trashMap,
  setPropertyStatusOrder,
  setSidebarCustomizePropertyStatus,
  togglePitchAndBearing,
  setPlaceMapHeight
}

const DashboardHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default withRouter(DashboardHoc)
