
import React, {Component} from 'react'

import './FeatureList.css';

import PlaceList from './PlaceList'



class FeatureList extends Component {

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    // if (prevProps.places !== this.props.places) {
    //   this.forceUpdate();
    // }
  }

  render() {

    const {
      accountUUID,
      //navigation,
      places,
      placeId,
      //feature,
      //features,
      //featuresByPlaceId,
      //maps,
      //mapsByUUID,
      //setMapUUID,
      //setFeature,
      //setFeatureAnimate,
      setPlaceAnimate,
      //createFeature,
      setSidebarCreateFeature,
      displayPhotos,
      history,
      parentView,
      match
    } = this.props;


    //console.log("FeatureList => ", places);

    return (
      <div>
        {false && <div className='desktop-list-callout'>
          <div>
            <a onClick={() => setSidebarCreateFeature(true)}>New property</a>
          </div>
        </div>}
        {false && <div className='FeatureInfo'>
          <span>{places.length} locations</span>
        </div>}
        {Object.keys(places).filter(statusKey => statusKey !== 'Unsorted').map((statusKey) => {
            return (
              <div className='FeatureList__LifecyleGroup' key={statusKey}>
                <div className='FeatureList__LifecyleGroupTitle'>{statusKey}</div>
                  <PlaceList accountUUID={accountUUID} placeId={placeId} places={places[statusKey]} setPlaceAnimate={setPlaceAnimate} displayPhotos={displayPhotos} history={history} parentView={parentView} match={match} />
            </div>
          )})}

          {Object.keys(places).filter(statusKey => statusKey === 'Unsorted').map((statusKey) => {
              return (
                <div className='FeatureList__LifecyleGroup' key={statusKey}>
                    <PlaceList accountUUID={accountUUID} placeId={placeId} places={places[statusKey]} setPlaceAnimate={setPlaceAnimate} displayPhotos={displayPhotos} history={history} parentView={parentView} match={match} />
              </div>
            )})}
      </div>
    )
  }
};

export default FeatureList
