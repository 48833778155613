
import React, {Component} from 'react'

//import { Field, reduxForm } from 'redux-form';

//import closeBtn from './../../assets/close-1.svg';
//import FlagIcon from './../../assets/red-flag.svg';

//const uuidv4 = require('uuid/v4');


class PlaceList extends Component {

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    // console.log(prevProps.places);
    // console.log(this.props.places)
    // if (prevProps.places !== this.props.places) {
    //   //this.forceUpdate();
    // }
  }


  render() {

    const {
      accountUUID,
      placeId,
      //navigation,
      places,
      //feature,
      //features,
      //featuresByPlaceId,
      //maps,
      //mapsByUUID,
      //setMapUUID,
      //setFeature,
      //setFeatureAnimate,
      setPlaceAnimate,
      //createFeature,
      //setSidebarCreateFeature,
      displayPhotos,
      history,
      parentView,
      match
    } = this.props;

    //console.log('PlaceList => ', places);
    return (
      <ul className='feature-panel'>
      {places.map((place) => (
              <li key={place.id} onClick={() => {
                if (parentView==='geodesktop') {
                  var view = match.params.view;
                  var path = `/desktop/${accountUUID}/${place.id}`;
                  if (view) path+=`/${view}`;
                  history.push(path);
                }
                setPlaceAnimate(place, true);
              }}>
                <div className={(placeId===place.id)?'feature selected':'feature'}>
                  {false && <div className='feature__avatar'>
                    <a>
                      <img alt='Avatar' src='https://scontent-lax3-2.cdninstagram.com/vp/632a32e82b9def73d02802052161c55b/5B5AB898/t51.2885-19/s150x150/12501835_555039001323633_164077479_a.jpg' />
                    </a>
                  </div>}
                  <div className='feature__item'>
                    <div><img alt='Place' src={place.icon} /></div>
                    <div className='title'>
                      <span>{place.name}</span>
                      {place.lifecycleSelections.length>0 && <div className='lifecycle'><span>{place.lifecycleSelections[place.lifecycleSelections.length-1]}</span></div>}
                    </div>
                    {false && place.maps.map((map, index) => <div key={index} className='coords'><span>{map.feature.documents.length} flags on {place.maps.length} maps</span></div>)}
                  </div>
                </div>
                {displayPhotos && place.maps.map((map) => {
                  return (
                    <div key={map.uuid} className='Photos'>
                      {map.feature.photoRows.map((photoRow, photoRowIndex) => {
                        return (
                          <div key={photoRowIndex} className='Photos__PhotoRow'>
                            {photoRow.map((photo, index) => {
                              return (
                                <div key={index} className='Photo'>
                                  <div className='Photo__container'>
                                    <img alt={photo.og.image} src={photo.og.image} />
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </li>
            ))}
            </ul>
    )
  }
};

export default PlaceList
