import React, {Component} from 'react'
//import PropTypes from 'prop-types'

import './MapSearch.css';

//import GlobeIcon from './../../assets/zondicons/globe.svg'
import LocationCurrentIcon from './../../assets/zondicons/location-current.svg'

//const uuidv4 = require('uuid/v4');

const geocoder = require('geocoder');


class MapSearch extends Component {

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.handleKeyUp = (e) => this._handleKeyUp(e);
    this.handleResultSelection = (e) => this._handleResultSelection(e);
    this.handleClickOutside = (e) => this._handleClickOutside(e);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  _handleClickOutside = (e) => {
    const { setMapSearchResults } = this.props;

    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      setMapSearchResults([]);
    }
  }

  _handleResultSelection = (result) => {
    const { setMapSearchResults, setFeatureAnimate, handleNewPlace } = this.props;
    setFeatureAnimate({
      title: result.formatted_address,
      featureType: 'PROPERTY',
      formattedAddress: result.formatted_address,
      placeID: result.place_id,
      location: {
        lat: result.geometry.location.lat,
        lng: result.geometry.location.lng
      }
    })
    setMapSearchResults([]);
    handleNewPlace(result);
  }

  _handleKeyUp = (e) => {
    const { setMapSearchResults} = this.props;
    //let results = [];
    geocoder.geocode(e.target.value, ( err, data ) => {
      if (data && data.results) {
        setMapSearchResults(data.results);
      }
    }, {key: 'AIzaSyA-ZsQ_cNv0oH_v_1EqCGHnCJFNNe784Uk'})
  }

  render() {

    const {
      //maps,
      //map,
      //mapUUID,
      //setMapSearchResults,
      mapSearchResults,
      //navigation,
      getUserLocation
    } = this.props;

    return (
      <div className='MapSearch' ref={this.setWrapperRef}>
        <div className='MapSearch__container'>
          <div className='MapSearch__accessory' onClick={getUserLocation}>
            <img alt='Current Location' src={LocationCurrentIcon}/>
          </div>
          <div className='MapSearch__search'>
            <input type='text' placeholder='Find Property' onKeyUp={this.handleKeyUp}/>
          </div>
        </div>
        <div className='MapSearch__results'>
          <ul>
            {mapSearchResults.map((result) => {
              return (
                <li key={result.place_id} onClick={() => this.handleResultSelection(result)}>
                  <div key={result.place_id}>
                    {result.formatted_address}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }
}


export default MapSearch;
