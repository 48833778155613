
import React, {Component} from 'react'
import Dropzone from 'react-dropzone';

import DatabaseIcon from './../../assets/database.svg';
import GreenCheckIcon from './../../assets/green-checked.svg';

import { fileTypes } from './../../config';

import './FilePublisher.css';

class FilePublisher extends Component {

  render() {

    const { onFilePublish, onFilePublishSuccess } = this.props;

    return (
      <div className='FilePublisher'>
        {(onFilePublishSuccess) ? (
          <div className='FilePublisher__complete'>
            <img alt='Complete' src={GreenCheckIcon} />
          </div>
        ) : (
          <form>
            <div>
              <Dropzone accept={fileTypes} onDrop={onFilePublish} className="dropzone">
                <div className="panel">
                  <img alt='Upload' src={DatabaseIcon}></img>
                  <h3>Drag & drop your files here</h3>
                  <p>or</p>
                  <div className='button-box'>
                    <a className='button primary'>Browse Files</a>
                  </div>
                </div>
              </Dropzone>
            </div>
          </form>
        )}
      </div>
    )
  }
};

export default FilePublisher
