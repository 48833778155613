import { Auth, API, graphqlOperation } from "aws-amplify";

import appConfig from './../config'

const uuidv4 = require('uuid/v4');

export const getAccount = (uuid, options) => {
  return (dispatch, getState) => {

    const GetAccount = `query GetAccount($uuid: String!) {
      getAccount(uuid: $uuid) {
        uuid
        email
        firstName
        lastName
        maps {
          uuid
          accountUUID
          status
          role
        }
      }
    }`;

    // console.log("%c GetAccount", 'color: orange; font-weight: bold');
    // console.log({uuid});
    Auth.currentAuthenticatedUser().then(currentSession => {
     if (currentSession) {
        API.graphql( graphqlOperation(GetAccount, {uuid}) ).then((response) => {
          // console.log('GetAccount => ', {response});
          const uuid = uuidv4();
          if (window && window.FS) {
            window.FS.identify(response.data.uuid, {
              email: response.data.getAccount.email + uuid,
              version: appConfig.AppVersion
            });
            if (window && window.mixpanel) {
              window.mixpanel.identify(uuid);
              window.mixpanel.track("getAccount", {"accountUUID": uuid, version: appConfig.AppVersion});
            }
          }
          dispatch({
            type: 'RESET_ACCOUNT',
            receivedAt: Date.now(),
            account: response.data.getAccount,
            options
          })

        }).catch((err) => {
          console.error('GetAccount => err => ', err);
        })
      }
    }).catch(e => {
      console.log(e, typeof e);
    });

  }
}

export default getAccount
