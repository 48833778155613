
import React, {Component} from 'react'

import Select from 'react-select';
//import 'react-select/dist/react-select.css';

import FeatureList from './FeatureList'

import './FeatureItemFeed.css';

class FeatureItemFeed extends Component {

  render() {
    const assetTypeOptions = [
      { label: 'Office', value: 'Office' },
      { label: 'Retail', value: 'Retail' },
      { label: 'Industrial', value: 'Industrial' },
      { label: 'Multifamily', value: 'Multifamily' },
      { label: 'Hotel', value: 'Hotel' }
    ];
    const fileTypeOptions = [
      { label: "Leases & Abstracts", value: "Leases & Abstracts" },
      { label: "Offering Memorandum/Sale Flyer", value: "Offering Memorandum/Sale Flyer"},
      { label: "Operating Agreements", value: "Operating Agreements"},
      { label: "Leasing Flyer", value: "Leasing Flyer"},
      { label: "Legal", value: "Legal"},
      { label: "Letter of Intent", value: "Letter of Intent"},
      { label: "Property Reports", value: "Property Reports"},
      { label: "Rent Roll", value: "Rent Roll"},
      { label: "Financials", value: "Financials"},
      { label: "Sale Comps", value: "Sale Comps"},
      { label: "Lease Comps", value: "Lease Comps"},
      { label: "Loan", value: "Loan"},
      { label: "Investment Memo", value: "Investment Memo"},
      { label: "Plans", value: "Plans"},
      { label: "Stacking Plan", value: "Stacking Plan"},
      { label: "Permit/Zoning", value: "Permit/Zoning"},
      { label: "Title/Survey", value: "Title/Survey"},
      { label: "Research", value: "Research"},
      { label: "Articles", value: "Articles"}
    ]
    const {
      accountUUID,
      placeId,
      setPlaceId,
      places,
      feature,
      features,
      featuresByPlaceId,
      //documents,
      maps,
      mapsByUUID,
      //setSidebarTab,
      //setFeature,
      setMapUUID,
      setFeatureUUID,
      setFeatureAnimate,
      setPlaceAnimate,
      createFeature,
      assetTypeTopics,
      toggleAssetTypeFilter,
      fileTypeTopics,
      toggleFileTypeFilter,
      navigation,
      setSidebarCreateFeature,
      displayPhotos,
      history,
      parentView,
      match
    } = this.props;

    return (
      <div className={navigation.sidebar.full ? 'FeatureItemFeed FeatureItemFeed--full' : 'FeatureItemFeed'}>
        <div className='FeatureItemFeed__filter'>
          <div className='FeatureItemFeed__filter-container'>
            <div className='filter-box'>
              <Select
                multi
                joinValues
                closeOnSelect={true}
                onChange={(e) => toggleAssetTypeFilter(e.map((topic) => {return {assetType: topic.value}})) }
                options={assetTypeOptions}
                placeholder="Asset Type"
                value={assetTypeTopics.map((topic) => topic.assetType)}
              />
            </div>
            <div className='filter-box'>
              <Select
                multi
                joinValues
                closeOnSelect={true}
                onChange={(e) => toggleFileTypeFilter(e.map((topic) => {return {fileType: topic.value}})) }
                options={fileTypeOptions}
                placeholder="File Type"
                value={fileTypeTopics.map((topic) => topic.fileType)}
              />
            </div>
          </div>
        </div>
        <div className='FeatureItemFeed__container' style={{
          maxHeight: window.innerHeight-150
        }}>
          {navigation.sidebar.tabcontainer.features && <FeatureList
            places={places}
            navigation={navigation}
            feature={feature}
            features={features}
            featuresByPlaceId={featuresByPlaceId}
            maps={maps}
            mapsByUUID={mapsByUUID}
            setMapUUID={setMapUUID}
            setPlaceId={setPlaceId}
            setFeatureUUID={setFeatureUUID}
            setFeatureAnimate={setFeatureAnimate}
            setPlaceAnimate={setPlaceAnimate}
            createFeature={createFeature}
            setSidebarCreateFeature={setSidebarCreateFeature}
            displayPhotos={displayPhotos}
            history={history}
            accountUUID={accountUUID}
            parentView={parentView}
            match={match}
            placeId={placeId}
          />}
          {navigation.sidebar.tabcontainer.documents && <div>Nadda</div>}
        </div>
      </div>
    )
  }
};

export default FeatureItemFeed
