
import React, {Component} from 'react'
//import PropTypes from 'prop-types'
import './BookmarkDeck.css';

import Bookmark from './Bookmark';
import BookmarkSearch from './BookmarkSearch';

import MapGL, {Marker} from 'react-map-gl';

import SplitPane from 'react-split-pane';

//import { Link } from 'react-router-dom';

//import eye from './../../../assets/eye.svg';

//const uuidv4 = require('uuid/v4');


class BookmarkDeck extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mapName: null
    };

    const { toggleHamburger } = this.props;
    toggleHamburger(false);

    this.resize = () => this._resize();
    this.handleMapNameKeyUp = (e) => this._handleMapNameKeyUp(e);
    this.handleCreateMap = () => this._handleCreateMap();
    this.state = {
      filteredBookmarks: this.props.filteredBookmarks.filter(b => (b.feature && b.feature.location && b.feature.location.lat && b.feature.location.lng)),
      activeBookmarks: this.props.activeBookmarks.filter(b => (b.feature && b.feature.location && b.feature.location.lat && b.feature.location.lng))
    }

  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    const { toggleHamburger } = this.props;
    toggleHamburger(true);
    window.removeEventListener('resize', this.resize);
  }

  _handleMapNameKeyUp = (e) => {
    this.setState({mapName: e.target.value});
  }

  _handleCreateMap = () => {
    const { account, accountUUID, buildMap, setError } = this.props;
    const { activeBookmarks, mapName } = this.state;
    console.log(mapName);
    console.log(activeBookmarks);
    if (activeBookmarks.length!==0) {
      //var mapUUID = uuidv4();
      buildMap({
        accountUUID: accountUUID,
        account: {
          uuid: account.uuid,
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email
        },
        //uuid: mapUUID,
        title: mapName,
        mapType: "Real Estate",
        lat: 39.017437,
        lng: -97.815787,
        zoom: 1,
        bookmarks: activeBookmarks
      })
    } else {
      setError({
        message: "No bookmarks selected"
      })
    }
  }

  _resize = () => {
    let viewport = {
      ...this.props.navigation.bookmarkView.viewport,
      height: this.props.navigation.bookmarkView.height,
      width: window.innerWidth
    };
    if (this.props.selectedMap) {
      viewport = {
        ...viewport,
        zoom: this.props.selectedMap.default.zoom,
        latitude: this.props.selectedMap.default.lat,
        longitude: this.props.selectedMap.default.lng
      }
    }
    this.props.updateBookmarkViewViewport(viewport)
  };

  render() {
    // const {
    //   //creatingMap,
    //   //importingMap
    // } = this.state;

    const {
      setSubnav,
      setBookmarkViewHeight,
      //updateBookmarkViewViewport,
      //selectedMap,
      //feature,
      //unsetFeatureUUID,
      //setFeatureUUID,
      //resetBookmarks,
      //filteredBookmarks,
      //activeBookmarks,
      toggleBookmark,
      setFeatureAnimateBookmarkView,
      navigation,
      //accountUUID,
      toggleBookmarkView,
      //toggleHamburger
    } = this.props;

    const {
      filteredBookmarks,
      activeBookmarks
    } = this.state;

    //const CancelURL = `/desktop/${accountUUID}`;

    return (
      <div className='BookmarkView'>
            <SplitPane onChange={(size) => {
              console.log(size);
              setBookmarkViewHeight(size);
              this.resize();
            }} split="horizontal" minSize={50} defaultSize={navigation.bookmarkView.height}>

              <div className="map_container">
                {navigation.bookmarkView.viewport.latitude && <MapGL
                  {...navigation.bookmarkView.viewport}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                  onViewportChange={(viewport) => {
                    this.props.updateBookmarkViewViewport({
                      ...viewport,
                      height: navigation.bookmarkView.viewport.height
                    })
                  }}
                  mapboxApiAccessToken='pk.eyJ1IjoiYWR2M2N0dXMiLCJhIjoiY2pkNmticjZrNTB5dTJ3bzF2dnQzZXA1MSJ9.sHLvu8If1_NerQ3ZXKixgA'
                >
                  {activeBookmarks.map((bookmark) => {
                    return (
                      <Marker key={bookmark.uuid} latitude={bookmark.feature.location.lat} longitude={bookmark.feature.location.lng} offsetLeft={bookmark.feature.marker.offsetLeft} offsetTop={bookmark.feature.marker.offsetTop}>
                        <img alt='Marker' className={bookmark.feature.marker.zoomClass} src={bookmark.feature.marker.icon}/>
                      </Marker>
                    )
                  })}
                </MapGL>}
              </div>

              <article className="BookmarkFeed" style={{height: navigation.bookmarkView.feedHeight}}>
                <header className="BookmarkFeed__mapName">
                  <h1 className="BookmarkFeed__mapTitle">Bookmarks</h1>
                </header>
                <BookmarkSearch />
                <div className="BookmarkFeed__contents">
                  {filteredBookmarks.map((bookmark) => {
                    return <Bookmark key={bookmark.uuid} bookmark={bookmark} toggleBookmark={toggleBookmark} setFeatureAnimateBookmarkView={setFeatureAnimateBookmarkView}/>
                  })}
                </div>
              </article>

            </SplitPane>


        <div className='BookmarkView__footer'>
          <div className='BookmarkView__footer-item--desc'>
            <label>
              {activeBookmarks.length>0 ? (activeBookmarks.length + " bookmarks selected. Specify map name and create info map from bookmarks."): "Select one or many bookmarks to create an infomap"}
            </label>
          </div>
          <div className='BookmarkView__footer-item--cta'>
            <div className='button-box'>
              <a className='button cancel' onClick={() => {
                setSubnav(false);
                toggleBookmarkView();
              }}>Cancel</a>
              <input className='inline-input' type='test' placeholder='Infomap name' onKeyUp={this.handleMapNameKeyUp}/>
              <a className='button primary' onClick={this.handleCreateMap}>Create</a>
            </div>
          </div>
        </div>
        </div>
    );
  }
};

export default BookmarkDeck
