import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom';
import AutoSignIn from './AutoSignIn';
import { Auth } from 'aws-amplify';
import Logo from './../../assets/logo-brown-beta.png'


class AutoLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight, // window.innerHeight
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize()
  }

  resize = size => {
    this.setState({ windowHeight: window.innerHeight })
  };

  render() {
    const { loginMessage, loginException, setToken, history, location, setAccountType, setAccountUUID, session, createAccount, resetSession, resetConsole } = this.props;
    const { windowHeight } = this.state;

    console.log({windowHeight})

    return (
      <div className='Homepage'>
        <div className='HomepageContainer'>
          <div className='HeaderBackdrop' style={{
            height: windowHeight+'px'
          }}>
          </div>
          <div className='HeaderWindow'>
            {/* <div className='Navigation'>
              {false && <img src={Logo} />}
              <div className='Navigation__item'>
                <a className='login' href='users/sign_in'>Login</a>
                <a className='register' href='users/sign_up'>Register</a>
              </div>
            </div> */}
            <img src={Logo} />
          </div>
          <div className='ResourceWindow'>
            <h3 className='blurb'>
              We're compiling Los Angeles-area job openings from employer websites and putting them on an easy-to-navigate map for job seekers. Our goal is to make it easier for those impacted financially from this crisis to see who is hiring close to where they live. We're adding new jobs everyday! Job searching works best on a computer versus a mobile phone.
            </h3>
            <button className='cta' onClick={() => {
              Auth.signIn("public@geotiller.com", "ffee2dd7-f4cd-4b26-8a2e-77c8e2c5788d")
                .then(user => {
                  console.log(user);
                  setAccountUUID(user.username);//user.username);
                  setAccountType('limited');
                  Auth.currentAuthenticatedUser()
                    .then(authenticateUser => {
                      setToken(authenticateUser.signInUserSession);
                      window.location.reload();
                    })
                })
            }}>Explore Jobs</button>
          </div>
          {/* <Switch>
            <Route render={() => <AutoSignIn
              session={session}
              setAccountType={setAccountType}
              setAccountUUID={setAccountUUID}
              setToken={setToken}
              history={history}
              location={location}
            />} />
          </Switch> */}
        </div>
        <div className='Footer'>
          <h5 className='smallblurb'>
            If you’re an employer and want to add job opportunities free of charge, please email <a href="mailto:info@geotiller.com" target="_top">info@geotiller.com</a>
          </h5>
        </div>
      </div>
    )
  }
}

export default AutoLogin;
