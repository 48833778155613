import { withRouter } from 'react-router-dom';

import Login from '../components/Login/Login'

import { connect } from 'react-redux'
import { history } from './../util/Store'

import {
  createAccount,
  setAccountType,
  setAccountUUID,
  setToken,
  resetSession
} from '../actions/session';

import {
  resetConsole
} from '../actions/console';

function mapStateToProps(state) {
  return {
    console: state.console,
    session: state.session,
    token: state.session.token
  };
}

const mapDispatchToProps = {
  resetSession,
  resetConsole,
  setAccountType,
  createAccount,
  setAccountUUID,
  setToken,
  history
}

const LoginHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default withRouter(LoginHoc)
