import { withRouter } from 'react-router-dom';

import SeedPublisher from '../components/SeedPublisher/SeedPublisher'

import { connect } from 'react-redux'

import { history } from './../util/Store'

import {
  getFilteredDocuments,
  getFeaturesMapByPlaceId,
  getFilteredFeatures,
  getFilteredFeaturesByPlaceId,
  getFilteredMaps,
  getSelectedMap,
  getSelectedMaps,
  getMapsByUUID,
  //getFeature,
  //getFeatureWithMarker,
  //getFeatures,
  getDocumentsByMapAndFeature,
  getLocationTypes,
  getDataTypes,
  getMapType,
  getLocationType,
  getDataType,
  getPlace,
  getFeatureByMapAndPlace,
  getWritableMaps,
  //getPlaceholderFeature
} from '../selectors'

import {
  //resetMaps,
  createMap,
  selectMap,
  //deselectMap,
  fetchFeatures,
  //resetDocuments,
  //updateViewport,
  //toggleSubnav,
  //setFeature,
  //setFeatureAnimate,
  //toggleDocumentAnalysis,
  //updateDocumentFileType,
  //updateDocumentAssetType,
  //viewDocument,
  //setDocPaneMapView,
  setDocPaneListView,
  //setSidebarWidth,
  //unsetFeature,
  //toggleAssetTypeFilter,
  //toggleFileTypeFilter,
  toggleSeedPublisher,
  setSeedPublisherTab,
  //setSidebarTab,
  //setCardDeckTab,
  createFeature,
  deleteFeature,
  //setSidebarCreateFeature,
  onDataPublish,
  onFilePublish,
  onLinkPublish,
  //setAccountUUID,
  setPlaceId,
  unsetPlaceId,
  setMapUUID,
  unsetMapUUID,
  unsetFeatureUUID,
  setFeatureUUID,
  //setPlaceholderFeatureUUID,
  setMapTypeKey,
  setLocationTypeKey,
  setDataTypeKey,
  setPublisherMapHeight,
  updatePublisherMapViewport,
  setMapSearchResults,
  setSeedPublisherProgress,
  unsetSeedPublisherFileSuccess,
  setLoading
} from '../actions/console';

import {
  getMaps
} from '../graphql/getMaps'

import {
  //resetAccount,
  setAccountUUID
} from '../actions/session'

import {
  getAccount
} from '../graphql/getAccount'

function mapStateToProps(state) {
  return {
    active: state.active,
    console: state.console,
    navigation: state.console.navigation,
    //feature: getFeatureWithMarker(state),
    account: state.session.account,
    accountUUID: state.session.account.uuid,
    mapUUID: state.console.mapUUID,
    featureUUID: state.console.featureUUID,
    placeId: state.console.placeId,
    featuresByPlaceId: getFeaturesMapByPlaceId(state),
    filteredDocuments: getFilteredDocuments(state),
    featureDocuments: getDocumentsByMapAndFeature(state),
    filteredFeatures: getFilteredFeatures(state),
    filteredFeaturesByPlaceId: getFilteredFeaturesByPlaceId(state),
    filteredMaps: getFilteredMaps(state),
    selectedMaps: getSelectedMaps(state),
    maps: getSelectedMaps(state),
    mapsByUUID: getMapsByUUID(state),
    map: getSelectedMap(state),
    place: getPlace(state),
    feature: getFeatureByMapAndPlace(state),
    assetTypeTopics: state.console.assetTypeTopics,
    fileTypeTopics: state.console.fileTypeTopics,
    locationTypes: getLocationTypes(state),
    mapType: getMapType(state),
    mapTypeKey: state.console.mapTypeKey,
    locationType: getLocationType(state),
    locationTypeKey: state.console.locationTypeKey,
    dataTypes: getDataTypes(state),
    dataType: getDataType(state),
    dataTypeKey: state.console.dataTypeKey,
    writableMaps: getWritableMaps(state),
    mapSearchResults: state.console.mapSearchResults,
    onFilePublishSuccess: state.console.navigation.seedpublisher.onFilePublishSuccess
  //placeholderFeature: getPlaceholderFeature(state)
  };
}


const mapDispatchToProps = {
  history,
  setLoading,
  createMap,
  setPublisherMapHeight,
  updatePublisherMapViewport,
  //resetAccount,
  getAccount,
  //resetMaps,
  getMaps,
  selectMap,
  //deselectMap,
  fetchFeatures,
  //resetDocuments,
  //updateViewport,
  //toggleSubnav,
  //setFeature,
  //setFeatureAnimate,
  //toggleDocumentAnalysis,
  toggleSeedPublisher,
  setSeedPublisherTab,
  //updateDocumentFileType,
  //updateDocumentAssetType,
  //viewDocument,
  //setDocPaneMapView,
  setDocPaneListView,
  //setSidebarWidth,
  //unsetFeature,
  //toggleAssetTypeFilter,
  //toggleFileTypeFilter,
  //setSidebarTab,
  //setCardDeckTab,
  createFeature,
  deleteFeature,
  //setSidebarCreateFeature,
  onDataPublish,
  onFilePublish,
  onLinkPublish,
  setPlaceId,
  unsetPlaceId,
  setAccountUUID,
  setMapUUID,
  unsetMapUUID,
  unsetFeatureUUID,
  setFeatureUUID,
  //setPlaceholderFeatureUUID,
  setMapTypeKey,
  setLocationTypeKey,
  setDataTypeKey,
  setMapSearchResults,
  setSeedPublisherProgress,
  unsetSeedPublisherFileSuccess
}

const SeedPublisherHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(SeedPublisher);

export default withRouter(SeedPublisherHoc)
