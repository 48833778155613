
import React, {Component} from 'react'
//import ReactDOM from 'react-dom'

//import AddressItem from './AddressItem'
//import AssetTypeItem from './AssetTypeItem'
//import FileTypeItem from './FileTypeItem'

import ellipsis from './../../../assets/ellipsis.svg';

import { confirmAlert } from 'react-confirm-alert'; // Import

const uuidv4 = require('uuid/v4');

class LinkItem extends Component {

  constructor(props) {
    super(props);
    this.handleViewSelection = (doc) => this._handleViewSelection(doc);
    this.confirmTrashDocument = (doc) => this._confirmTrashDocument(doc);
    this.confirmTitleUpdate = (doc) => this._confirmTitleUpdate(doc);

    this.state = {
      linkTitle: this.props.doc.title,
      linkDisplay: true
    };
  }

  _handleViewSelection = (doc) => {
    console.log(doc);
    if (doc.type==='LINK') {
      window.open(doc.url, "_blank");
    } else {
      console.log("Nothing to show!");
    }
  }


  _confirmTrashDocument = (doc) => {
    const { trashDocument } = this.props;
    confirmAlert({
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this link?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {trashDocument(doc)}
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting trashDocument =>", doc)}
        }
      ]
    })
  }

  _confirmTitleUpdate = () => {
    const { doc, updateDocumentTitle } = this.props;
    const { linkTitle } = this.state;
    confirmAlert({
      title: 'Confirm update',
      message: 'Are you sure you want to change the title of this link?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            updateDocumentTitle(doc, linkTitle);
            this.setState({linkDisplay: true})
          }
        },
        {
          label: 'No',
          onClick: () => {
            console.log("Aborting updateDocumentTitle =>", doc);
            this.setState({linkTitle: doc.title, linkDisplay: true})
          }
        }
      ]
    })
  }

  render() {
    const {
      account,
      document,
      doc,
      toggleDocumentAnalysis,
      accountUUID,
      createBookmark,
      documentUUID,
      onDetailDropdownClick,
      isRegularAccountType,
      ctaText,
    } = this.props;

    const {
      linkTitle,
      linkDisplay
    } = this.state;

    return (
      <div key={doc.uuid} className={(document.uuid===doc.uuid) ? 'GeoSeed__item GeoSeed__item--detail' : 'GeoSeed__item'}>
        <div className='GeoSeed__item-wrapper'>
            <div className='GeoSeed__item-content'>
              {linkDisplay && <div className='GeoSeed__item-content-display' style={{
                maxWidth: (window.innerWidth/3)
              }}>
                <span>{linkTitle}</span>
              </div>}
              {!linkDisplay && <div className='GeoSeed__item-content-edit'>
                <input onChange={e => this.setState({linkTitle: e.target.value})} value={linkTitle} />
              </div>}
            </div>
            {!linkDisplay && <div className='GeoSeed__cta' onClick={e => this.confirmTitleUpdate()}>
              <a>Update</a>
            </div>}
            {linkDisplay && <div className='GeoSeed__cta' onClick={() => this.handleViewSelection(doc)}>
              <a>{ctaText || 'View'}</a>
            </div>}
            {linkDisplay && isRegularAccountType && <div className={doc.author.uuid===accountUUID ? 'GeoSeed__config' : 'GeoSeed__config disabled'} onClick={() => {
              if (doc.author.uuid===accountUUID) {
                onDetailDropdownClick(doc.uuid);
              }
            }}>
              <a>
                <img alt='Settings' src={ellipsis} style={{width: 20}}/>
              </a>
            </div>}
            <div className='GeoSeed__config-dropdown-container'>
            <ul className={(documentUUID===doc.uuid) ? 'GeoSeed__config-dropdown GeoSeed__config-dropdown--active': 'GeoSeed__config-dropdown'}>
              <li><a onClick={() => createBookmark({
                uuid: uuidv4(),
                account: {
                  uuid: account.uuid,
                  firstName: account.firstName,
                  lastName: account.lastName,
                  email: account.email
                },
                accountUUID: accountUUID,
                document: doc,
                documentUUID: doc.uuid,
                feature: doc.feature,
                featureUUID: doc.feature.uuid,
                map: {
                  uuid: doc.mapUUID
                }
              }) }>Bookmark</a></li>
              {(doc.author.uuid===accountUUID) && <li><a onClick={e => this.setState({linkDisplay: !linkDisplay})}>Rename</a></li>}
              {false && (doc.author.uuid===accountUUID) && <li><a>Move</a></li>}
              {false && (doc.author.uuid===accountUUID) && <li><a onClick={() => toggleDocumentAnalysis(doc) }>Edit</a></li>}
              {(doc.author.uuid===accountUUID) && <li className='DeleteLink' onClick={() => this.confirmTrashDocument(doc) }><a>Delete</a></li>}
            </ul>
            </div>
        </div>
      </div>
    )
  }

};

export default LinkItem
