import React, {Component} from 'react'

class MapList extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { setMapUUID, filteredMaps, setCardDeckTab, navigation, history } = this.props;
    return (
      <div className='Home__mainPanel'>

        <div className='Home__tabContainer'>
          <div className={navigation.carddeck.tabcontainer.all ? 'Home__tabContainerItem--selected' : 'Home__tabContainerItem'} onClick={() => setCardDeckTab({all: true, owned: false, member: false, following: false}) }>
            <div className='card-deck-tab-name'>
              <span>All {filteredMaps.maps.length}</span>
            </div>
          </div>
          <div className={navigation.carddeck.tabcontainer.owned ? 'Home__tabContainerItem--selected' : 'Home__tabContainerItem'} onClick={() => setCardDeckTab({all: false, owned: true, member: false, following: false}) }>
            <div className='card-deck-tab-name'>
              <span>My Maps {filteredMaps.ownedMaps.length}</span>
            </div>
          </div>
          <div className={navigation.carddeck.tabcontainer.member ? 'Home__tabContainerItem--selected' : 'Home__tabContainerItem'} onClick={() => setCardDeckTab({all: false, owned: false, member: true, following: false}) }>
            <div className='card-deck-tab-name'>
              <span>Member {filteredMaps.memberMaps.length}</span>
            </div>
          </div>
          <div className={navigation.carddeck.tabcontainer.following ? 'Home__tabContainerItem--selected' : 'Home__tabContainerItem'} onClick={() => setCardDeckTab({all: false, owned: false, member: false, following: true}) }>
            <div className='card-deck-tab-name'>
              <span>Following {filteredMaps.followingMaps.length}</span>
            </div>
          </div>
        </div>

        <div className="Home__masterPane">
          <table>
            <thead></thead>
            <tbody>
              {filteredMaps.filteredMaps.map((map) => (
                <tr className='MapItem' key={map.uuid} onClick={() => {
                  history.push(`home/${map.uuid}`);
                }}>
                  <td></td>
                  <td>
                    <div className='cell'>
                      <h5>{map.title}</h5>
                    </div>
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

export default MapList;
