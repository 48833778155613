
export const fileTypes = [
  'PDF',
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'text/csv',
  'text/plain',
  'application/xls',
  'application/xlsx',
  'application/excel',
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/docx'
]

export default {
  Region: 'us-east-1',
  IdentityPoolId: 'us-east-1:76cc5d04-ece2-4cd8-8b66-25e9b7aeddb1 ',
  UserPoolId: 'us-east-1_SaPGRaLVf',
  ClientId: '51s0lh4aestaimjeioainh9ma4',
  MapBox: 'pk.eyJ1IjoiYWR2M2N0dXMiLCJhIjoiY2pkNmticjZrNTB5dTJ3bzF2dnQzZXA1MSJ9.sHLvu8If1_NerQ3ZXKixgA',
  GraphQLEndpoint: 'https://2n2qptz7tzcktmdlajtemq6exu.appsync-api.us-west-2.amazonaws.com/graphql',
  AppVersion: '1.6.5'
}
