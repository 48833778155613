import React, {Component} from 'react'

import SignIn from './SignIn';
import SignUp from './SignUp';
import SignOut from './SignOut';
import ForgotPassword from './ForgotPassword';
import { Switch, Route } from 'react-router-dom';
import styled, { css } from 'styled-components'

import './Login.css';

const LoginContainer = styled.div`
  min-height: 100vh;
  background-size: cover;
  align-items: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: auto;

  ${({ session: { tenant: { homepageBackgroundImg="/homepage/panoramic_view.jpg" } } }) => css`
    background: radial-gradient(transparent, #00241C), #FFF url(${homepageBackgroundImg}) no-repeat center center fixed;
    background-size: cover;
  `}
`;

class Login extends Component {

  render() {
    const { loginMessage, loginException, setToken, history, location, setAccountType, setAccountUUID, session, createAccount, resetSession, resetConsole } = this.props;
    return (
      <LoginContainer session={session}>
        <div className='loginsubcontainer'>
          <div className='loginform'>
            {loginMessage && <div className={"loginMessage" + (loginException?' isError':'')}>
            <i className={(loginException?'fal fa-exclamation-triangle':'fal fa-check-circle')} />&nbsp;{loginMessage}</div>}
            <Switch>
              <Route path="/users/password" render={() => <ForgotPassword session={session} setAccountUUID={setAccountUUID} setToken={setToken} history={history} createAccount={createAccount}/>} />
              <Route path="/users/sign_up/:referringAccountUUID?/:referringMapUUID?/:referringToken?" render={({match}) => <SignUp session={session} setAccountUUID={setAccountUUID} setToken={setToken} history={history} createAccount={createAccount} match={match}/>}/>
              <Route path="/users/sign_out" render={() => <SignOut session={session} resetSession={resetSession} resetConsole={resetConsole} history={history}/>} />
              <Route path="/reset" render={() => <SignOut session={session} resetSession={resetSession} resetConsole={resetConsole} history={history}/>} />
              <Route render={() => <SignIn session={session} setAccountType={setAccountType} setAccountUUID={setAccountUUID} setToken={setToken} history={history} location={location}/>} />
            </Switch>
          </div>
        </div>
      </LoginContainer>
    )
  }
}

export default Login;
