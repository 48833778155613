
import React, { Component } from 'react'

import './DocumentList.css';

//import AddressItem from './AddressItem'
//import AssetTypeItem from './AssetTypeItem'
//import FileTypeItem from './FileTypeItem'

import FileItem from './FileItem'
import LinkItem from './LinkItem'
import DataItem from './DataItem'
import moment from 'moment';


import MediaGallery from './../../MediaGallery'

import PlaceMap from './PlaceMap'

import { confirmAlert } from 'react-confirm-alert'; // Import

import { Link } from 'react-router-dom';

import SplitPane from 'react-split-pane';

import addIcon from './../../../assets/black-add.svg';
import linkIcon from './../../../assets/zondicons/link.svg';
import layersIcon from './../../../assets/zondicons/layers.svg';
import documentIcon from './../../../assets/zondicons/document.svg';
import LocationIcon from './../../../assets/zondicons/location.svg';
import PhotoIcon from './../../../assets/zondicons/photo.svg';
import ArrowRight from './../../../assets/zondicons/arrow-outline-right.svg';
import FullScreenIcon from './../../../assets/zondicons/screen-full.svg';


//import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

const Spinner = require('react-spinkit');


class DocumentList extends Component {

  constructor(props) {
    super(props);
    this.onDetailDropdownClick = (doc) => this._onDetailDropdownClick(doc);
    this.confirmDeleteFeature = (feature) => this._confirmDeleteFeature(feature);
    this.handleDeleteMapSelect = (event) => this._handleDeleteMapSelect(event);
    this.handleStatusSelect = (event) => this._handleStatusSelect(event);
    this.handleLifecycleSelect = (event) => this._handleLifecycleSelect(event);
    this.handlePlaceNameChange = (event) => this._handlePlaceNameChange(event);
    this.submitPlaceNameChange = () => this._submitPlaceNameChange();
    this.resize = () => this._resize();

    var features = [];
    if (this.props.place && this.props.place.maps && this.props.place.maps.length > 0 && this.props.place.features && this.props.place.features.length > 0) {
      features = this.props.place.features.filter(feature => feature.mapUUID === this.props.place.maps[0].uuid);
    }

    this.editors = ['owner', 'member'];


    this.state = {
      documentUUID: null,
      deleting: false,
      deleteMapUUID: (features.length > 0) ? features[0].mapUUID : null,
      deleteFeatureUUID: (features.length > 0) ? features[0].uuid : null,
      selectedStatus: this.props.placeDocumentsByType.selectedStatus,
      selectedLifecycle: this.props.placeDocumentsByType.selectedLifecycle,
      editingPlaceName: false,
      selectedPlaceName: this.props.place.name
    }
  }

  componentDidMount() {
    const { setPlaceMapHeight, updateViewport, viewport } = this.props;

    setPlaceMapHeight((window.innerHeight / 3));
    window.addEventListener('resize', this.resize);
    this.resize();
    updateViewport({
      ...viewport,
      zoom: 12
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize);
  }

  componentDidUpdate(prevProps) {

    if (
      (prevProps.place.features !== this.props.place.features)
      || (prevProps.place.maps !== this.props.place.maps)
    ) {
      var features = [];
      if (this.props.place && this.props.place.maps && this.props.place.maps.length > 0 && this.props.place.features && this.props.place.features.length > 0) {
        features = this.props.place.features.filter(feature => feature.mapUUID === this.props.place.maps[0].uuid);
      }
      // var statusSelections = this.props.placeDocumentsByType.dataByField.filter((field) => field.key=='Status Selection').map((field) => field).sort((a,b) => {
      //   return new Date(b.snapshotAt) - new Date(a.snapshotAt);
      // });
      // var lifecycleSelections = this.props.placeDocumentsByType.dataByField.filter((field) => field.key=='Lifecycle Selection').map((field) => field).sort((a,b) => {
      //   return new Date(b.snapshotAt) - new Date(a.snapshotAt);
      // });
      this.setState({
        deleteMapUUID: (features.length > 0) ? features[0].mapUUID : null,
        deleteFeatureUUID: (features.length > 0) ? features[0].uuid : null,
        selectedStatus: this.props.placeDocumentsByType.selectedStatus,
        selectedLifecycle: this.props.placeDocumentsByType.selectedLifecycle,
        selectedPlaceName: this.props.place.name
      })
    }

  }

  _resize = () => {
    const { viewport } = this.props;
    this.props.updateViewport({
      ...viewport,
      height: this.props.navigation.docpane.placeMapHeight,
      width: window.innerWidth - 350
    })
  };

  _confirmDeleteFeature = (feature) => {
    const { deleteFeature, accountUUID, history } = this.props;
    confirmAlert({
      title: 'Confirm delete',
      message: "Are you sure you want to delete this feature and all of the it's associated links, files, and data?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteFeature(feature);
            history.push(`/desktop/${accountUUID}`);
          }
        },
        {
          label: 'No',
          onClick: () => { console.log("Aborting deleteFeature =>", feature) }
        }
      ]
    })
  }

  // _handleContextMenu = (event) => {
  //   event.preventDefault();
  //   this.setState({deleting:true})
  // }
  //
  // _handleDeleteMapSelect = (event) => {
  //   const { place } = this.props;
  //   const mapUUID = event.target.value;
  //
  //   const features = place.features.filter(feature => feature.mapUUID===mapUUID);
  //   if (features.length>0) {
  //     this.setState({
  //       deleteFeatureUUID: features[0].uuid,
  //       deleteMapUUID: features[0].mapUUID
  //     })
  //   }
  // }

  _handleStatusSelect = (event) => {
    const { onDataPublish, statusDataType, mapType, place } = this.props;
    const selectedStatus = event.target.value;
    this.setState({ selectedStatus })
    onDataPublish({
      dataType: statusDataType,
      locationType: {
        dataTypes: [],
        locationTypeKey: place.locationTypeKey,
      },
      locationTypeKey: place.locationTypeKey,
      mapType,
      snapshotAt: moment().utc(),
      values: {
        status_selection: selectedStatus
      }
    });
  }


  _handleLifecycleSelect = (event) => {
    const { onDataPublish, lifecycleDataType, mapType, place } = this.props;
    const selectedLifecycle = event.target.value;
    this.setState({ selectedLifecycle })
    onDataPublish({
      dataType: lifecycleDataType,
      locationType: {
        dataTypes: [],
        locationTypeKey: place.locationTypeKey,
      },
      locationTypeKey: place.locationTypeKey,
      mapType,
      snapshotAt: moment().utc(),
      values: {
        lifecycle_selection: selectedLifecycle
      }
    });
  }

  _handlePlaceNameChange = (event) => {
    const selectedPlaceName = event.target.value;
    this.setState({ selectedPlaceName })
  }

  _submitPlaceNameChange = () => {
    const { onDataPublish, locationNameDataType, mapType, place } = this.props;
    const { selectedPlaceName } = this.state;
    onDataPublish({
      dataType: locationNameDataType,
      locationType: {
        dataTypes: [],
        locationTypeKey: place.locationTypeKey,
      },
      locationTypeKey: place.locationTypeKey,
      mapType,
      snapshotAt: moment().utc(),
      values: {
        building_name: selectedPlaceName
      }
    });
    this.setState({ editingPlaceName: false })
  }

  _onDetailDropdownClick(documentUUID) {
    this.setState({ documentUUID })
  }

  _renderHeader() {
    const { place, map, isRegularAccountType, toggleMediaGallery, placeDocumentsByType } = this.props;
    const { selectedPlaceName, editingPlaceName } = this.state;
    const images = placeDocumentsByType.links.filter(link => link.og && link.og.image).map((link) => link.og.image);

    return place.name && map && <div className='GeoSeeds__header'>
      <div className='GeoSeeds__header-accessory'>
        <img alt='Icon' src={place.icon} />
      </div>
      <div className='GeoSeeds__header-title'>
        <div className='GeoSeeds__header-title-group'>
          <div className='GeoSeeds__header-title-group-item'>
            {(map.role === 'follower') && <h3>{place.name}</h3>}
            {this.editors.includes(map.role) && !editingPlaceName && <h3 onClick={() => {
              // disabled title editing in public view
              if (isRegularAccountType) this.setState({ editingPlaceName: true })
            }}>{place.name}</h3>}
            {this.editors.includes(map.role) && editingPlaceName && <div className='GeoSeeds__header-title-group-item-edit-title'>
              <input onChange={this.handlePlaceNameChange} value={selectedPlaceName} />
              <img alt='submit' src={ArrowRight} onClick={this.submitPlaceNameChange}></img>
            </div>}
          </div>
        </div>
        <h5><img alt='location' src={LocationIcon} /> {place.title}</h5>
      </div>
      {isRegularAccountType && <div className='GeoSeeds__header-twiddle'>
        <div className='GeoSeeds__header-twiddle-group'>
          <div className='GeoSeeds__header-twiddle-group-item' onClick={() => {
            if (images.length > 0) toggleMediaGallery()
          }}>
            <div className='GeoSeeds__header-photos'>
              <img alt='deck' src={PhotoIcon} />
            </div>
            <div className='GeoSeeds__header-badge'>
              <span>{images.length}</span>
            </div>
          </div>
        </div>
      </div>}
    </div>
  }

  _renderLinksAndFilesSection({ isDetailsEnabled }) {
    const {
      place,
      placeId,
      map,
      mapUUID,
      featureUUID,
      account,
      accountUUID,
      placeDocumentsByType,
      viewDocument,
      document,
      documentUUID,
      trashDocument,
      createBookmark,
      updateDocumentTitle,
      toggleDocumentAnalysis,
      isRegularAccountType
    } = this.props;


    return place.name && map && featureUUID && <div className='GeoSeeds__content-group Files'>
      <div className='GeoSeeds__content-desc'>
        <div className='GeoSeeds__content-desc-header'>
          <img alt='Files' style={{ color: '#AAAAAA' }} src={documentIcon} />
          <h4>Jobs</h4>
        </div>
        <p>Web links and files</p>
      </div>
      <div className='GeoSeeds__content-box'>
        <div className='GeoSeeds__DataTable'>
          {placeDocumentsByType.filesAndLinks.map((doc, index) => {
            if (doc.type==='LINK') {
              return (
                <LinkItem
                  key={doc.uuid}
                  viewDocument={viewDocument}
                  account={account}
                  document={document}
                  documentUUID={documentUUID}
                  onDetailDropdownClick={this.onDetailDropdownClick}
                  doc={doc}
                  toggleDocumentAnalysis={toggleDocumentAnalysis}
                  trashDocument={trashDocument}
                  accountUUID={accountUUID}
                  createBookmark={createBookmark}
                  updateDocumentTitle={updateDocumentTitle}
                  isRegularAccountType={isRegularAccountType}
                />
              )
            } else {
              return (
                <FileItem
                  key={doc.uuid}
                  viewDocument={viewDocument}
                  account={account}
                  document={document}
                  documentUUID={documentUUID}
                  onDetailDropdownClick={this.onDetailDropdownClick}
                  doc={doc}
                  toggleDocumentAnalysis={toggleDocumentAnalysis}
                  trashDocument={trashDocument}
                  accountUUID={accountUUID}
                  createBookmark={createBookmark}
                  updateDocumentTitle={updateDocumentTitle}
                  isRegularAccountType={isRegularAccountType}
                  ctaText='View'
                />
              )
            }
          })}
        </div>
      </div>
    </div>
  }

  _renderLinksSection({ isDetailsEnabled }) {
    const {
      place,
      placeId,
      map,
      mapUUID,
      featureUUID,
      account,
      accountUUID,
      placeDocumentsByType,
      viewDocument,
      document,
      trashDocument,
      createBookmark,
      updateDocumentTitle,
      toggleDocumentAnalysis,
      isRegularAccountType,
    } = this.props;

    const { documentUUID } = this.state;

    if (!isRegularAccountType && placeDocumentsByType.links.length === 0) {
      return undefined;
    }

    // @fixme: featureUUID used to be required in below condition
    return place.name && map && <div className='GeoSeeds__content-group Links'>
      <div className='GeoSeeds__content-desc'>
        <div className='GeoSeeds__content-desc-header'>
          <img alt='Icon' style={{ color: '#AAAAAA' }} src={linkIcon} />
          <h4>Links</h4>
        </div>
        {isRegularAccountType && <p>Add web links relating to this property</p>}
      </div>
      <div className='GeoSeeds__content-box'>
        <div className='GeoSeeds__DataTable'>
          {placeDocumentsByType.links.map((doc, index) => {
            return (
              <LinkItem
                key={doc.uuid}
                viewDocument={viewDocument}
                account={account}
                document={document}
                documentUUID={documentUUID}
                onDetailDropdownClick={this.onDetailDropdownClick}
                doc={doc}
                toggleDocumentAnalysis={toggleDocumentAnalysis}
                trashDocument={trashDocument}
                accountUUID={accountUUID}
                createBookmark={createBookmark}
                updateDocumentTitle={updateDocumentTitle}
                isRegularAccountType={isRegularAccountType}
              />
            )
          })}
        </div>
        {isRegularAccountType && <div className={this.editors.includes(map.role) ? 'GeoSeeds__content-box-cta' : 'GeoSeeds__content-box-cta disabled'}>
          <img alt='Add' src={addIcon} />
          <Link className='GeoSeedAddLink' to={'/publisher/link/' + accountUUID + '/' + (mapUUID ? mapUUID : 'null') + '/' + placeId}><h5>Add link</h5></Link>
        </div>}
      </div>
    </div>
  }

  _renderFilesSection({ isDetailsEnabled }) {
    const {
      place,
      placeId,
      map,
      mapUUID,
      featureUUID,
      account,
      accountUUID,
      placeDocumentsByType,
      viewDocument,
      document,
      trashDocument,
      createBookmark,
      updateDocumentTitle,
      toggleDocumentAnalysis,
      isRegularAccountType
    } = this.props;

    const { documentUUID } = this.state;


    if (!isRegularAccountType && placeDocumentsByType.files.length === 0) {
      return undefined;
    }

    // @fixme: featureUUID used to be required in below condition
    return place.name && map && <div className='GeoSeeds__content-group Files'>
      <div className='GeoSeeds__content-desc'>
        <div className='GeoSeeds__content-desc-header'>
          <img alt='Files' style={{ color: '#AAAAAA' }} src={documentIcon} />
          <h4>Files</h4>
        </div>
        {isRegularAccountType && <p>Add files to this property</p>}
      </div>
      <div className='GeoSeeds__content-box'>
        <div className='GeoSeeds__DataTable'>
          {placeDocumentsByType.files.map((doc, index) => {
            return (
              <FileItem
                key={doc.uuid}
                viewDocument={viewDocument}
                account={account}
                document={document}
                documentUUID={documentUUID}
                onDetailDropdownClick={this.onDetailDropdownClick}
                doc={doc}
                toggleDocumentAnalysis={toggleDocumentAnalysis}
                trashDocument={trashDocument}
                accountUUID={accountUUID}
                createBookmark={createBookmark}
                updateDocumentTitle={updateDocumentTitle}
                isRegularAccountType={isRegularAccountType}
              />
            )
          })}
        </div>
        {isRegularAccountType && <div className={this.editors.includes(map.role) ? 'GeoSeeds__content-box-cta' : 'GeoSeeds__content-box-cta disabled'}>
          <img alt='Add' src={addIcon} />
          <Link className='GeoSeedAddFile' to={'/publisher/file/' + accountUUID + '/' + (mapUUID ? mapUUID : 'null') + '/' + placeId}><h5>Add file</h5></Link>
        </div>}
      </div>
    </div>
  }

  _renderDataSection() {
    const {
      place,
      placeId,
      map,
      mapUUID,
      featureUUID,
      accountUUID,
      placeDocumentsByType,
      trashDocument,
      isRegularAccountType,
    } = this.props;

    console.log('renderData', {      place,
      placeId,
      map,
      mapUUID,
      featureUUID,
      accountUUID,
      placeDocumentsByType,
      trashDocument,
      isRegularAccountType})

    // @fixme: featureUUID used to be required in below condition
    return place.name && map && isRegularAccountType && <div className='GeoSeeds__content-group Data'>
      <div className='GeoSeeds__content-desc'>
        <div className='GeoSeeds__content-desc-header'>
          <img alt='Data' style={{ color: '#AAAAAA' }} src={layersIcon} />
          <h4>Data</h4>
        </div>
        {isRegularAccountType && <p>Add asset-specific data to this property.</p>}
      </div>
      <div className='GeoSeeds__content-box'>
        <div className='GeoSeeds__DataTable'>
          <div className='header'>
          </div>
          {placeDocumentsByType.dataGroupedByType.map((doc, index) => (
            <DataItem
              key={index}
              index={index}
              doc={doc}
              accountUUID={accountUUID}
              trashDocument={trashDocument}
            />
          ))}
        </div>
        <div className={this.editors.includes(map.role) ? 'GeoSeeds__content-box-cta' : 'GeoSeeds__content-box-cta disabled'}>
          <img alt='Add' src={addIcon} />
          <Link className='GeoSeedAddData' to={'/publisher/data/' + accountUUID + '/' + (mapUUID ? mapUUID : 'null') + '/' + placeId}><h5>Add data</h5></Link>
        </div>
      </div>
    </div>
  }

  _renderStatusAndLifecycleSelector() {
    const {
      featureUUID,
      place,
      map,
      statusDataType,
      lifecycleDataType,
      isRegularAccountType,
    } = this.props;
    const { selectedStatus, selectedLifecycle } = this.state;


    return isRegularAccountType && place.name && featureUUID && map && this.editors.includes(map.role) && <div className='GeoSeeds__content-group--top-drawer'>
      <div className='GeoSeeds__content-desc'>
        <h5 className='title'>
          Set Status
        </h5>
        <select onChange={this.handleStatusSelect} value={(selectedStatus) ? selectedStatus : ''}>
          <option value=''></option>
          {statusDataType.fields[0].choices.map((status, index) => (
            <option key={index} value={status}>{status}</option>
          ))}
        </select>
      </div>
      <div className='GeoSeeds__content-desc'>
        <h5 className='title'>
          Set Lifecyle
        </h5>
        <select onChange={this.handleLifecycleSelect} value={(selectedLifecycle) ? selectedLifecycle : ''}>
          <option value=''></option>
          {lifecycleDataType.fields[0].choices.map((lifecycle, index) => (
            <option key={index} value={lifecycle}>{lifecycle}</option>
          ))}
        </select>
      </div>
    </div>
  }

  render() {
    const {
      navigation,
      account,
      //documents,
      placeDocumentsByType,
      //feature,
      featureUUID,
      placeId,
      place,
      document,
      map,
      toggleMediaGallery,
      toggleDocumentAnalysis,
      //toggleDocumentConfigDropwdown,
      viewDocument,
      trashDocument,
      //updateDocumentAssetType,
      //updateDocumentFileType,
      //toggleSeedPublisher,
      accountUUID,
      mapUUID,
      //setMapUUID,
      //featureUUID,
      //setDocPaneMapView,
      createBookmark,
      //resetBookmarks,
      viewport,
      //writableMaps,
      history,
      updateDocumentTitle,
      statusDataType,
      lifecycleDataType,
      updateViewport,
      places,
      mapStyle,
      setPlaceMapHeight,
      isRegularAccountType,
    } = this.props;

    const { documentUUID, deleteMapUUID, deleteFeatureUUID, selectedStatus, selectedLifecycle, selectedPlaceName, editingPlaceName } = this.state;

    const images = placeDocumentsByType.links.filter(link => link.og && link.og.image).map((link) => link.og.image);

    if (!isRegularAccountType) {
      return (
        <div className={navigation.map.full ? 'GeoSeeds GeoSeeds--full' : 'GeoSeeds'} style={{
          width: (window.innerWidth > 700) ? window.innerWidth - (navigation.map.full ? 280 : 350) : window.innerWidth,
          height: window.innerHeight
        }}>
          {place && <div className='GeoSeeds__breadcrumbs'>
            <div className='GeoSeeds__breadcrumb BackToMap' onClick={() => {
              updateViewport({
                ...viewport,
                height: window.innerHeight,
                pitch: 0,
                bearing: 0
              })
              history.push(`/desktop/${accountUUID}`)
            }}>Back to Map</div>
            <div className='GeoSeeds__breadcrumb'>></div>
            <div className='GeoSeeds__breadcrumb GeoSeeds__breadcrumb-active'>{place.title}</div>
          </div>}

          {!place.name && <div className='GeoSeeds__content-loader'>
            <Spinner name="ball-triangle-path" color="orange" />
          </div>}

          <div className='GeoSeeds__DocumentList' style={{
            width: (window.innerWidth > 700) ? window.innerWidth - (navigation.map.full ? 280 : 350) : window.innerWidth,
            height: window.innerHeight
          }}>
            {this._renderStatusAndLifecycleSelector()}
            {this._renderHeader()}
            {this._renderLinksAndFilesSection({ isDetailsEnabled: false })}
            {/* {this._renderLinksSection({ isDetailsEnabled: false })}
            {this._renderFilesSection({ isDetailsEnabled: false })} */}
            
            {place && <div className='GeoSeeds__content-group--delete'>
              <div className="button-box">
                <a className="button danger" onClick={e => {
                  updateViewport({
                    ...viewport,
                    height: window.innerHeight,
                    pitch: 0,
                    bearing: 0
                  })
                  history.push(`/desktop/${accountUUID}`)
                }}>Back to map</a>
              </div>
            </div>}

            <div className='GeoSeeds__footer'>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={navigation.map.full ? 'GeoSeeds GeoSeeds--full' : 'GeoSeeds'} style={{
        width: (window.innerWidth > 700) ? window.innerWidth - (navigation.map.full ? 280 : 350) : window.innerWidth,
        height: window.innerHeight
      }} ref={elem => this.RootPanel = elem} onClick={() => {
        if (documentUUID) this.onDetailDropdownClick(null);
      }}>

        {false && <div className='GeoSeeds__paddingTop'></div>}

        {false && (place) && <div className='GeoSeeds__breadcrumbs'>
          <div className='GeoSeeds__breadcrumb BackToMap' onClick={() => {
            updateViewport({
              ...viewport,
              height: window.innerHeight,
              pitch: 0,
              bearing: 0
            })
            history.push(`/desktop/${accountUUID}`)
          }}>Back to Map</div>
          <div className='GeoSeeds__breadcrumb'>></div>
          <div className='GeoSeeds__breadcrumb GeoSeeds__breadcrumb-active'>{place.title}</div>
        </div>}

        {true && <div className='GeoSeeds__controls'>
          <img onClick={(e) => {
            updateViewport({
              ...viewport,
              height: window.innerHeight,
              pitch: 0,
              bearing: 0
            })
            history.push(`/desktop/${accountUUID}`)
          }} src={FullScreenIcon} />
        </div>}

        {!place.name && <div className='GeoSeeds__content-loader'>
          <Spinner name="ball-triangle-path" color="orange" />
        </div>}

        <SplitPane onChange={(size) => {
          setPlaceMapHeight(size);
          this.resize();
        }} split="horizontal" minSize={50} defaultSize={navigation.docpane.placeMapHeight} style={{ width: '100px !important' }}>

          {true && <PlaceMap
            accountUUID={accountUUID}
            viewport={{
              ...viewport,
              height: navigation.docpane.placeMapHeight,
              width: navigation.docpane.placeMapWidth,
              pitch: 50,
              bearing: 200,
              //zoom: 14
            }}
            mapStyle={mapStyle}
            places={places}
            place={place}
            updateViewport={updateViewport}
            history={history}
            // style={{ width: '300px' }}
            style={{ width: `${navigation.docpane.placeMapWidth}px` }}
          />}

          <div className='GeoSeeds__DocumentList' style={{
            width: (window.innerWidth > 700) ? window.innerWidth - (navigation.map.full ? 280 : 350) : window.innerWidth,
            height: window.innerHeight - navigation.docpane.placeMapHeight
          }}>

            {this._renderStatusAndLifecycleSelector()}
            {this._renderHeader()}
            {this._renderLinksSection({ isDetailsEnabled: true })}
            {this._renderFilesSection({ isDetailsEnabled: true })}
            {this._renderDataSection()}

            {place.name && map && (map.role === 'owner') && <div className='GeoSeeds__content-group--delete'>
              <div className="button-box">
                <a className={!featureUUID ? 'button danger disabled' : 'button danger'} onClick={e => {
                  if (!featureUUID) {
                    console.log('No featureUUID');
                  } else {
                    this.confirmDeleteFeature({
                      featureUUID: deleteFeatureUUID,
                      mapUUID: deleteMapUUID
                    })
                  }
                }}>Delete</a>
              </div>
            </div>}

            <div className='GeoSeeds__footer'></div>
            {navigation.mediagallery && <MediaGallery images={images} toggleMediaGallery={toggleMediaGallery} />}

          </div>
        </SplitPane>
      </div>
    );
  };
};

export default DocumentList
