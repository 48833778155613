
import React, {Component} from 'react'
//import ReactDOM from 'react-dom'

//import AddressItem from './AddressItem'
//import AssetTypeItem from './AssetTypeItem'
//import FileTypeItem from './FileTypeItem'

import ellipsis from './../../../assets/ellipsis.svg';

import { confirmAlert } from 'react-confirm-alert'; // Import

import { fileTypes } from './../../../config';

const uuidv4 = require('uuid/v4');

class FileItem extends Component {

  constructor(props) {
    super(props);
    this.handleViewSelection = (doc) => this._handleViewSelection(doc);
    this.handleDownloadSelection = (doc) => this._handleDownloadSelection(doc);
    this.confirmTrashDocument = (doc) => this._confirmTrashDocument(doc);
    this.confirmTitleUpdate = (doc) => this._confirmTitleUpdate(doc);

    this.state = {
      fileName: this.props.doc.title,
      fileDisplay: true
    };
  }

  _handleViewSelection = (doc) => {
    const { viewDocument } = this.props;
    console.log(doc);
    if (fileTypes.includes(doc.type)) viewDocument(doc);
    else if (doc.type==='LINK') {
      window.open(doc.url, "_blank");
    } else if (doc.type==='DATA') {
      console.log(doc.dataTable);
    } else {
      console.log("Nothing to show!");
    }
  }

  _handleDownloadSelection = (doc) => {
    const { viewDocument } = this.props;
    console.log(doc);
    if (doc.type==='PDF') viewDocument(doc);
    else {
      console.log("Nothing to download!");
    }
  }

  _confirmTrashDocument = (doc) => {
    const { trashDocument } = this.props;
    confirmAlert({
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this file?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {trashDocument(doc)}
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting trashDocument =>", doc)}
        }
      ]
    })
  }

  _confirmTitleUpdate = () => {
    const { doc, updateDocumentTitle } = this.props;
    const { fileName } = this.state;
    confirmAlert({
      title: 'Confirm update',
      message: 'Are you sure you want to change the title of this file?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            updateDocumentTitle(doc, fileName);
            this.setState({fileDisplay: true})
          }
        },
        {
          label: 'No',
          onClick: () => {
            console.log("Aborting updateDocumentTitle =>", doc);
            this.setState({fileName: doc.title, fileDisplay: true})
          }
        }
      ]
    })
  }

  render() {
    const {
      account,
      document,
      doc,
      toggleDocumentAnalysis,
      accountUUID,
      createBookmark,
      documentUUID,
      onDetailDropdownClick,
      isRegularAccountType,
      ctaText,
    } = this.props;

    const {
      fileName,
      fileDisplay
    } = this.state;

    return (
      <div key={doc.uuid} className={(document.uuid===doc.uuid) ? 'GeoSeed__item GeoSeed__item--detail' : 'GeoSeed__item'}>
        <div className='GeoSeed__item-wrapper'>
            <div className='GeoSeed__item-content'>
              {fileDisplay && <div className='GeoSeed__item-content-display' style={{
                maxWidth: (window.innerWidth/3)
              }}>
                <span>{fileName}</span>
              </div>}
              {!fileDisplay && <div className='GeoSeed__item-content-edit'>
                <input onChange={e => this.setState({fileName: e.target.value})} value={fileName} />
              </div>}
            </div>
            {!fileDisplay && <div className='GeoSeed__cta' onClick={e => this.confirmTitleUpdate()}>
              <a>Update</a>
            </div>}
            {fileDisplay && <div className='GeoSeed__cta' onClick={() => this.handleViewSelection(doc)}>
              <a>{ctaText || 'Download'}</a>
            </div>}
            {fileDisplay && isRegularAccountType && <div className={doc.author && doc.author.uuid===accountUUID ? 'GeoSeed__config' : 'GeoSeed__config disabled'}>
              <a>
                <img alt='Settings' src={ellipsis} style={{width: 20}} onClick={() => {
                  console.log('Click')
                  if (doc.author.uuid===accountUUID) {
                    onDetailDropdownClick(doc.uuid);
                  }
                }}/>
              </a>
            </div>}
            <div className='GeoSeed__config-dropdown-container'>
            <ul className={(documentUUID===doc.uuid) ? 'GeoSeed__config-dropdown GeoSeed__config-dropdown--active': 'GeoSeed__config-dropdown'}>
              <li><a onClick={() => createBookmark({
                uuid: uuidv4(),
                account: {
                  uuid: account.uuid,
                  firstName: account.firstName,
                  lastName: account.lastName,
                  email: account.email
                },
                accountUUID: accountUUID,
                document: doc,
                documentUUID: doc.uuid,
                feature: doc.feature,
                featureUUID: doc.feature.uuid,
                map: {
                  uuid: doc.mapUUID
                }
              }) }>Bookmark</a></li>
              {false && <li><a onClick={() => this.handleDownloadSelection(doc)}>Download</a></li>}
              {(doc.author.uuid===accountUUID) && <li><a onClick={e => this.setState({fileDisplay: !fileDisplay})}>Rename</a></li>}
              {false && (doc.author.uuid===accountUUID) && <li><a>Move</a></li>}
              {false && (doc.author.uuid===accountUUID) && <li><a onClick={() => toggleDocumentAnalysis(doc) }>Edit</a></li>}
              {(doc.author.uuid===accountUUID) && <li className='DeleteFile'><a onClick={() => this.confirmTrashDocument(doc) }>Delete</a></li>}
            </ul>
            </div>
        </div>
      </div>
    )
  }

};

export default FileItem
