import { withRouter } from 'react-router-dom';

import AutoLogin from '../components/AutoLogin/AutoLogin'

import { connect } from 'react-redux'
import { history } from './../util/Store'

import {
  createAccount,
  setAccountType,
  setAccountUUID,
  setToken,
  resetSession
} from '../actions/session';

import {
  resetConsole
} from '../actions/console';

function mapStateToProps(state) {
  return {
    console: state.console,
    session: state.session,
    token: state.session.token
  };
}

const mapDispatchToProps = {
  resetSession,
  resetConsole,
  createAccount,
  setAccountType,
  setAccountUUID,
  setToken,
  history
}

const AutoLoginHoc = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoLogin);

export default withRouter(AutoLoginHoc)
