import DefaultTenant from './tenants/Default'
import ClientA from './tenants/ClientA'
import ClientB from './tenants/ClientB'
import GSL from './tenants/GSL'

const tenants = {
  default: DefaultTenant,
  clienta: ClientA,
  clientb: ClientB,
  gsl: GSL,
}

const initialSession = {
  account: {
    uuid: null,//'account_uuid_1',
    firstName: null,
    lastName: null,
    email: null,
    maps: [],
    contacts: {
      recent: []
    }
  },
  showDebug: false,
  token: {
    idToken: {
      jwtToken: null,
    },
    refreshToken: {
      token: null
    },
    accessToken: {
      jwtToken: null
    }
  },
  accountType: 'regular', // used to limit features in PublicApp -- use 'limited' for PublicApp,
  tenant: DefaultTenant, // tenant specific configuration
};

const session = (
  state = initialSession,
  action
) => {
  switch (action.type) {
    case 'RESET_SESSION':
      return initialSession
    case 'SET_TENANT':
      return {
        ...state,
        tenant: tenants[action.tenantIdentifier.toLowerCase()] || DefaultTenant,
      }
    case 'APPEND_ACCOUNT_MAPS':
      var unique = {};
      state.account.maps.concat(action.maps).forEach((map) => unique[map.uuid] = map);
      var maps = Object.keys(unique).map((mapUUID) => unique[mapUUID]);
      return {
        ...state, account: {
          ...state.account,
          maps
        }
      }
    case 'SET_TOKEN':
      return {
        ...state, token: {
          idToken: action.idToken,
          refreshToken: action.refreshToken,
          accessToken: action.accessToken
        }
      }
    case 'SET_ACCOUNT_UUID':
      return {
        ...state, account: {
          ...state.account,
          uuid: action.accountUUID
        }
      }
    case 'SET_ACCOUNT_TYPE':
      return { ...state, accountType: action.accountType }
    case 'RESET_ACCOUNT':
      return {
        ...state,
        account: action.account
      }
    case 'SET_ACCOUNT':
      return state
    default:
      return state
  }
}

export default session
