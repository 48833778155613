
import React, {Component} from 'react';

import './DesktopFooter.css';
import MenuIcon from './../../assets/zondicons/menu-white.svg';

class DesktopFooter extends Component {

  componentDidUpdate() {
  }

  render () {
    const {
      navigation,
      setSubnav,
    } = this.props;

    return (
        <footer className="DesktopFooter">
          <div className="DesktopFooter__container">
              {!navigation.subnav && <div className='menu' onClick={() => setSubnav(true)}>
                <a>
                  <img alt='Menu' style={{color: 'white'}} src={MenuIcon}/>
                </a>
              </div>}
          </div>
      </footer>
    );
  }
};

export default DesktopFooter
