
const bookmarks = (
    state = [],
    action
  ) => {
  switch (action.type) {
    case 'TOGGLE_BOOKMARK':
      return state.map((bookmark) => {
        if (bookmark.uuid!==action.bookmark.uuid) {
          return bookmark;
        } else {
          return {
            ...bookmark,
            active: !bookmark.active
          }
        }
      });
    case 'CREATE_BOOKMARK':
      return state;
    case 'RESET_BOOKMARKS':
      return action.bookmarks;
    // case 'ADD_BOOKMARK':
    //   return [...state, action.doc]
    // case 'REMOVE_BOOKMARK':
    //   return state.filter((doc) => doc.uuid!=action.doc.uuid)
    default:
      return state
  }
}

export default bookmarks
