
const viewport__LosAngeles = {
  latitude: 33.96737618219826,
  longitude: -118.2858107546878,
  zoom: 9.376743565436692,
  minZoom: 4.5,
  width: window.innerWidth,
  height: window.innerHeight,
  bounds: {
    _sw: {
      lng: -119.04469121431183,
      lat: 33.457530664165404
    },
    _ne: {
      lng: -117.51846891011422,
      lat: 34.4724396748221
    },
  },
  pitch: 0,
  maxPitch: 60,
  bearing: 0,
};

const viewport__UnitedStates = {
  latitude: 38.54990077406305,
  longitude: -98.27058116137705,
  zoom: 4.5,
  minZoom: 4.5,
  width: window.innerWidth,
  height: window.innerHeight,
  bounds: {
    _sw: {
      lng: -139.5991667499471,
      lat: 3.001125982915454
    },
    _ne: {
      lng: -58.878964825538134,
      lat: 61.35570405587663
    },
  },
  pitch: 0,
  maxPitch: 60,
  bearing: 0,
};

const initialState = {
  viewport: viewport__LosAngeles,
  propertyStatuses: [], //array to keep track of order of property statuses
  invertedMapStyle: "mapbox://styles/mapbox/satellite-v9",
  mapStyle: "mapbox://styles/mapbox/streets-v9",
  mapStyles: [
    "mapbox://styles/mapbox/streets-v9",
    "mapbox://styles/mapbox/satellite-v9"
  ],
  // markerSettings: {
  //   zoomClass: 'large',
  //   offsetTop: -10,
  //   offsetLeft: -10
  // },
  navigation: {
    desktopOpacity: 1, //used to make desktop opaque when subnav is displayed
    subnav: false, //roll down panel for Info Maps
    mediagallery: false,
    userdropdown: false, //dropdown for user settings
    hamburger: true,
    profile: false,
    bookmarkView: {
      display: false,
      height: window.innerHeight / 3,
      feedHeight: window.innerHeight - (window.innerHeight / 3),
      viewport: {
        latitude: 39.017437,
        longitude: -97.815787,
        zoom: 4,
        height: window.innerHeight / 3,
        width: 900
      }
    }, //drop down panel displaying bookmarks
    docpane: {
      map: true, //map view
      list: false, //list view
      placeMapHeight: window.innerHeight / 3,
      placeMapWidth: window.innerWidth - 280
    },
    seedpublisher: {
      display: false, //display seed publisher
      tabcontainer: {
        file: true,
        link: false,
        data: false
      },
      height: window.innerHeight,
      feedHeight: window.innerHeight - (window.innerHeight / 2),
      viewport: {
        latitude: 39.017437,
        longitude: -97.815787,
        zoom: 4,
        height: window.innerHeight,
        width: window.innerWidth
      },
      loading: {
        blocking: false,
        progress: 0
      },
      onFilePublishSuccess: false
    },
    sidebar: {
      width: 350,
      full: (((window.innerWidth - 600) < 600) ? false : true),
      tabcontainer: {
        features: true,
        documents: false
      },
      createFeature: false,
      createDocument: false,
      customizePropertyStatus: false //display component that allows for sorting of property statuses
    },
    map: {
      full: (((window.innerWidth - 600) < 600) ? true : false)
    },
    carddeck: {
      tabcontainer: {
        all: true,
        owned: false,
        member: false,
        following: false
      }
    },
    infomapeditor: {
      display: false,
    },
    infomap: {
      height: window.innerHeight / 3,
      feedHeight: window.innerHeight - (window.innerHeight / 3),
      viewport: {
        latitude: 39.017437,
        longitude: -97.815787,
        zoom: 4,
        height: window.innerHeight / 3,
        width: window.innerWidth
      }
    }
  },
  error: {
    message: null
  },
  loading: {
    blocking: false
  },
  //accountUUID: null,
  fetchingProgress: 0,
  mapSearchResults: [], //results from geocoder
  link: {
    title: ""
  },
  placeId: null, //user for display popup
  //placeholderFeatureUUID: null, //placeholder feature on seed publisher view
  featureUUID: null,
  features: [],
  documents: [],
  document: {
    uuid: null
  },
  file: {
    documentUUID: null,
    signingURL: null,
    key: null
  },
  maps: [], //for list of maps in desktop view
  mapUUIDs: [], //selected maps in desktop view, feed view will have array of 1 map id
  mapUUID: null, //mapUUID for SeedPublisher
  assetTypeTopics: [], //array of string asset types
  fileTypeTopics: [], //array of string file types,
  mapTypeKey: "Real Estate", //used for data time series collection (wasn't able to initialize as null)
  locationTypeKey: null,//"Retail", //used for data time series collection
  dataTypeKey: null//"lease_comp", //used for data time series collection
}

var unique = {};

const consoleReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {

    case 'RESET_CONSOLE':
      return initialState

    case 'SET_DESKTOP_OPACITY':
      return {
        ...state, navigation: {
          ...state.navigation,
          desktopOpacity: action.desktopOpacity
        }
      }

    case 'SET_MAP_TYPE_KEY':
      return { ...state, mapTypeKey: action.mapTypeKey, locationTypeKey: "", dataTypeKey: "" }
    case 'SET_LOCATION_TYPE_KEY':
      return { ...state, locationTypeKey: action.locationTypeKey, dataTypeKey: "" }
    case 'SET_DATA_TYPE_KEY':
      return { ...state, dataTypeKey: action.dataTypeKey }

    case 'SET_ERROR':
      return { ...state, error: action.error }

    case 'SET_LOADING':
      return { ...state, loading: action.loading }

    case 'SET_PROPERTY_STATUS_ORDER':
      return { ...state, propertyStatuses: action.propertyStatuses }

    case 'TOGGLE_DOCUMENT_CONFIG_DROPDOWN':
      return {
        ...state, documents: state.documents.map((document) => {
          if (document.uuid === action.document.uuid) {
            return {
              ...document,
              configDropdown: true
            }
          }
          return document;
        })
      }

    case 'TOGGLE_HAMBURGER':
      return {
        ...state, navigation: {
          ...state.navigation,
          hamburger: action.status
        }
      }

    case 'TOGGLE_USER_DROPDOWN':
      return {
        ...state, navigation: {
          ...state.navigation,
          userdropdown: !state.navigation.userdropdown
        }
      }

    case 'HIDE_USER_DROPDOWN':
      return {
        ...state, navigation: {
          ...state.navigation,
          userdropdown: false
        }
      }
    case 'TOGGLE_ASSET_TYPE_FILTER':
      return {
        ...state,
        assetTypeTopics: action.topics.map((topic) => topic)
      }

    case 'TOGGLE_FILE_TYPE_FILTER':
      return {
        ...state,
        fileTypeTopics: action.topics.map((topic) => topic)
      }

    case 'TOGGLE_INFOMAP_EDITOR':
      return {
        ...state, navigation: {
          ...state.navigation,
          subnav: true,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            display: false
          },
          bookmarkView: {
            ...state.navigation.bookmarkView,
            display: false
          },
          infomapeditor: {
            ...state.navigation.infomapeditor,
            display: !state.navigation.infomapeditor.display
          }
        }
      }

    case 'TOGGLE_SEED_PUBLISHER':
      return {
        ...state, navigation: {
          ...state.navigation,
          subnav: false,
          infomapeditor: {
            ...state.navigation.infomapeditor,
            display: false
          },
          bookmarkView: {
            ...state.navigation.bookmarkView,
            display: false
          },
          seedpublisher: {
            ...state.navigation.seedpublisher,
            display: !state.navigation.seedpublisher.display
          }
        }
      }

    case 'SET_SEED_PUBLISHER_PROGRESS':
      return {
        ...state, navigation: {
          ...state.navigation,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            loading: action.loading
          }
        }
      }

    case 'SET_SEED_PUBLISHER_TABCONTAINER':
      return {
        ...state, navigation: {
          ...state.navigation,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            tabcontainer: action.tabcontainer
          }
        }
      }

    case 'SET_SEED_PUBLISHER_FILE_SUCCESS':
      return {
        ...state, navigation: {
          ...state.navigation,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            onFilePublishSuccess: true
          }
        }
      }

    case 'UNSET_SEED_PUBLISHER_FILE_SUCCESS':
      return {
        ...state, navigation: {
          ...state.navigation,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            onFilePublishSuccess: false,
            loading: { blocking: false, progress: 0 }
          }
        }
      }

    case 'SET_SUBNAV':
      return {
        ...state, navigation: {
          ...state.navigation,
          subnav: action.display
        }
      }

    case 'TOGGLE_MEDIA_GALLERY':
      return {
        ...state, navigation: {
          ...state.navigation,
          mediagallery: !state.navigation.mediagallery
        }
      }

    case 'TOGGLE_PROFILE':
      return {
        ...state, navigation: {
          ...state.navigation,
          profile: !state.navigation.profile
        }
      }
    case 'HIDE_PROFILE':
      return {
        ...state, navigation: {
          ...state.navigation,
          profile: false
        }
      }

    case 'TOGGLE_SUBNAV':
      return {
        ...state, navigation: {
          ...state.navigation,
          infomapeditor: {
            ...state.navigation.infomapeditor,
            display: false
          },
          bookmarkView: {
            ...state.navigation.bookmarkView,
            display: false
          },
          seedpublisher: {
            ...state.navigation.seedpublisher,
            display: false
          },
          subnav: !state.navigation.subnav
        }
      }

    case 'TOGGLE_BOOKMARK_VIEW':
      return {
        ...state, navigation: {
          ...state.navigation,
          subnav: false,
          infomapeditor: {
            ...state.navigation.infomapeditor,
            display: false
          },
          seedpublisher: {
            ...state.navigation.seedpublisher,
            display: false
          },
          bookmarkView: {
            ...state.navigation.bookmarkView,
            display: !state.navigation.bookmarkView.display
          }
        }
      }

    case 'SET_SIDEBAR_CREATE_FEATURE':
      return {
        ...state, navigation: {
          ...state.navigation,
          sidebar: {
            ...state.navigation.sidebar,
            createFeature: action.status
          }
        }
      }

    case 'SET_SIDEBAR_CREATE_DOCUMENT':
      return {
        ...state, navigation: {
          ...state.navigation,
          sidebar: {
            ...state.navigation.sidebar,
            createDocument: action.status
          }
        }
      }

    case 'SET_SIDEBAR_CUSTOMIZE_PROPERTY_STATUS':
      return {
        ...state, navigation: {
          ...state.navigation,
          sidebar: {
            ...state.navigation.sidebar,
            customizePropertyStatus: action.status
          }
        }
      }

    case 'SET_SIDEBAR_WIDTH':
      const sidebarWidth = action.width;
      const mapWidth = window.innerWidth - sidebarWidth;
      return {
        ...state, navigation: {
          ...state.navigation,
          sidebar: {
            ...state.navigation.sidebar,
            width: sidebarWidth,
            full: (sidebarWidth < mapWidth) ? true : false
          },
          map: {
            ...state.navigation.map,
            width: mapWidth,
            full: (mapWidth < sidebarWidth) ? true : false
          }
        }
      }

    case 'SET_PUBLISHER_MAP_HEIGHT':
      return {
        ...state, navigation: {
          ...state.navigation,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            height: action.height,
            feedHeight: window.innerHeight - action.height
          }
        }
      }

    case 'SET_PLACEMAP_HEIGHT':
      return {
        ...state, navigation: {
          ...state.navigation,
          docpane: {
            ...state.navigation.docpane,
            placeMapHeight: action.height,
          }
        }
      }

    case 'SET_INFOMAP_HEIGHT':
      return {
        ...state, navigation: {
          ...state.navigation,
          infomap: {
            ...state.navigation.infomap,
            height: action.height,
            feedHeight: window.innerHeight - action.height,
            //viewport: state.navigation.infomap.viewport
          }
        }
      }

    case 'SET_BOOKMARK_VIEW_HEIGHT':
      return {
        ...state, navigation: {
          ...state.navigation,
          bookmarkView: {
            ...state.navigation.bookmarkView,
            height: action.height,
            feedHeight: window.innerHeight - action.height
          }
        }
      }

    case 'SET_DOCPANE_MAP_VIEW':
      return {
        ...state, navigation: {
          ...state.navigation,
          docpane: {
            ...state.navigation.docpane,
            list: false,
            map: true
          }
        }
      }

    case 'SET_DOCPANE_LIST_VIEW':
      return {
        ...state, navigation: {
          ...state.navigation,
          docpane: {
            ...state.navigation.docpane,
            list: true,
            map: false
          }
        }
      }

    case 'SET_SIDEBAR_TABCONTAINER':
      return {
        ...state, navigation: {
          ...state.navigation,
          sidebar: {
            ...state.navigation.sidebar,
            tabcontainer: action.tabcontainer
          }
        }
      }

    case 'SET_CARDDECK_TABCONTAINER':
      return {
        ...state, navigation: {
          ...state.navigation,
          carddeck: {
            ...state.navigation.carddeck,
            tabcontainer: action.tabcontainer
          }
        }
      }

    case 'UPDATE_VIEWPORT':
      return {
        ...state, viewport: {
          ...state.viewport,
          ...action.viewport
        }
      }

    case 'UPDATE_MAP_STYLE':
      return { ...state, mapStyle: action.mapStyle, invertedMapStyle: state.mapStyles.filter(style => style !== action.mapStyle)[0] }

    case 'UPDATE_INFOMAP_VIEWPORT':
      return {
        ...state, navigation: {
          ...state.navigation,
          infomap: {
            ...state.navigation.infomap,
            viewport: {
              ...state.navigation.infomap.viewport,
              ...action.viewport
            }
          }
        }
      }

    case 'UPDATE_PUBLISHER_MAP_VIEWPORT':
      return {
        ...state, navigation: {
          ...state.navigation,
          seedpublisher: {
            ...state.navigation.seedpublisher,
            viewport: {
              ...state.navigation.seedpublisher.viewport,
              ...action.viewport
            }
          }
        }
      }

    case 'UPDATE_BOOKMARK_VIEW_VIEWPORT':
      return {
        ...state, navigation: {
          ...state.navigation,
          bookmarkView: {
            ...state.navigation.bookmarkView,
            viewport: {
              ...state.navigation.bookmarkView.viewport,
              ...action.viewport
            }
          }
        }
      }

    // case 'UPDATE_MARKER_SETTINGS':
    //   return {...state, markerSettings:action.markerSettings}

    // case 'SET_ACCOUNT_UUID':
    //   return {...state, accountUUID:action.accountUUID}

    case 'SET_FETCHING_PROGRESS':
      return { ...state, fetchingProgress: action.fetchingProgress }

    /* FILES */
    case 'RESET_FILE':
      return { ...state, file: action.file }

    /* MAPS */
    case 'SET_MAP_SEARCH_RESULTS':
      return { ...state, mapSearchResults: action.results }
    case 'SET_MAP_UUID':
      return { ...state, mapUUID: action.mapUUID }
    case 'UNSET_MAP_UUID':
      return { ...state, mapUUID: null, mapUUIDs: state.mapUUIDs.filter(mapUUID => mapUUID !== action.mapUUID).map(mapUUID => mapUUID) }
    case 'SET_MAP':
      return { ...state, map: action.map }
    case 'PREPEND_MAPS':
      unique = {};
      action.maps.concat(state.maps).forEach((map) => unique[map.uuid] = map);
      let prependMaps = Object.keys(unique).map((mapUUID) => unique[mapUUID]);
      return { ...state, maps: prependMaps }
    case 'APPEND_MAPS':
      //return {...state, maps:(state.maps.concat(action.maps))}
      unique = {};
      state.maps.concat(action.maps).forEach((map) => unique[map.uuid] = map);
      let appendMaps = Object.keys(unique).map((mapUUID) => unique[mapUUID]);
      return { ...state, maps: appendMaps }
    case 'RESET_MAPS':
      return { ...state, maps: action.maps }
    case 'EXCLUSIVE_SELECT_MAP':
      return {
        ...state,
        mapUUID: action.map.uuid,
        mapUUIDs: [action.map.uuid]
      }
    case 'DELETE_MAP':
      return {
        ...state,
        maps: state.maps.filter(map => map.uuid !== action.map.uuid),
        mapUUIDs: state.mapUUIDs.filter(mapUUID => mapUUID !== action.map.uuid)
      }

    case 'SELECT_MAP':
      return {
        ...state,
        mapUUID: action.map.uuid,
        mapUUIDs: state.mapUUIDs.concat(action.map.uuid), //[...state.arr, action.newItem]
        propertyStatuses: action.map.propertyStatuses,
        navigation: {
          ...state.navigation,
          docpane: {
            ...state.navigation.docpane,
            list: false,
            map: true
          }
        }
      }
    case 'DESELECT_MAP':
      return {
        ...state,
        mapUUID: null,
        mapUUIDs: state.mapUUIDs.filter(mapUUID => mapUUID !== action.map.uuid), //[...state.arr, action.newItem]
        navigation: {
          ...state.navigation,
          docpane: {
            ...state.navigation.docpane,
            list: false,
            map: true
          }
        }
      }
    case 'DESELECT_ALL_MAPS':
      return {
        ...state,
        mapUUID: null,
        mapUUIDs: []
      }
    case 'UPDATE_MAP':
      return {
        ...state,
        maps: state.maps.map((map) => {
          var permissions = map.permissions;
          if (map.uuid === action.map.uuid) {
            permissions = action.map.permissions;
          }
          return {
            ...map,
            title: ((map.uuid === action.map.uuid) ? action.map.title : map.title),
            permissions
          }
        })
      }

    /* FEATURES */
    case 'ADD_FEATURE':
      const features = state.features;
      features.push(action.feature);
      return { ...state, features: features }
    case 'RESET_FEATURES':
      return { ...state, features: action.features }
    case 'APPEND_FEATURES':
      //return {...state, features:(state.features.concat(action.features))}
      unique = {};
      state.features.concat(action.features).forEach((feature) => unique[feature.uuid] = feature);
      //var features = Object.keys(unique).map((featureUUID) => unique[featureUUID]);
      return { ...state, features: Object.keys(unique).map((featureUUID) => unique[featureUUID]) }
    case 'FETCH_FEATURES':
      return { ...state, features: action.features }
    // case 'SET_PLACEHOLDER_FEATURE_UUID':
    //   return {...state, placeholderFeatureUUID:action.featureUUID}
    case 'SET_FEATURE_UUID':
      return { ...state, featureUUID: action.featureUUID }
    case 'UNSET_FEATURE_UUID':
      return { ...state, featureUUID: null }
    case 'CREATE_FEATURE':
      return { ...state }
    case 'DELETE_FEATURE':
      return { ...state, features: state.features.filter(feature => feature.uuid !== action.feature.uuid) }

    /* PLACES */
    case 'SET_PLACE_ID':
      return { ...state, placeId: action.placeId }
    case 'UNSET_PLACE_ID':
      return { ...state, placeId: null }

    /* DOCUMENTS */
    case 'APPEND_DOCUMENTS':
      //return {...state, documents:(state.documents.concat(action.documents))}
      unique = {};
      state.documents.concat(action.documents).forEach((document) => unique[document.uuid] = document);
      var documents = Object.keys(unique).map((documentUUID) => ({
        ...unique[documentUUID],
        // dataTable: {},
      }));
      console.log('here?', {documents})
      return { ...state, documents }
    case 'RESET_DOCUMENTS':
      return { ...state, documents: action.documents.filter(doc => !doc.isTrashed) }
    case 'SUBMIT_DOCUMENT':
      var newDocuments = state.documents;
      const filteredDocuments = newDocuments.filter(doc => doc.uuid === action.document.uuid);
      if (filteredDocuments.length === 0) {
        newDocuments.push(action.document)
      }
      return { ...state, documents: newDocuments }
    case 'TRASH_DOCUMENT':
      return {
        ...state,
        documents: state.documents.filter(doc => doc.uuid !== action.document.uuid)
      }
    case 'UPDATE_DOCUMENT':
      return {
        ...state,
        documents: state.documents.map((doc) => {
          if (action.document.uuid === doc.uuid) {
            return action.document
          } else {
            return doc
          }
        })
      }
    case 'VIEW_DOCUMENT':
      return { ...state, document: action.document }
    case 'TOGGLE_DOCUMENT_ANALYSIS':
      return {
        ...state, document:
          state.document.uuid === action.document.uuid ?
            { uuid: null }
            : action.document
      }

    case 'SET_DOCUMENT_ADDRESS':
      return {
        ...state, documents: state.documents.map((doc) => {
          if (doc.uuid === action.document.uuid) {
            doc.addressTopic = action.topic;
          }
          return doc;
        })
      }
    case 'SET_DOCUMENT_ASSET_TYPE':
      return {
        ...state, documents: state.documents.map((doc) => {
          if (doc.uuid === action.document.uuid) {
            doc.assetTypeTopic = action.topic;
          }
          return doc;
        })
      }

    case 'SET_DOCUMENT_FILE_TYPE':
      return {
        ...state, documents: state.documents.map((doc) => {
          if (doc.uuid === action.document.uuid) {
            doc.fileTypeTopic = action.topic;
          }
          return doc;
        })
      }

    default:
      return state
  }
}

export default consoleReducer
