import { createSelector } from 'reselect'
import { point, polygon, buffer, intersect,bboxPolygon } from '@turf/turf';
import { get } from 'lodash-es';

import FlagIcon from './../assets/markers/red-flag-v2.svg';
import Industrial from './../assets/markers/industrialBuilding-geotiller.svg';
import Hotel from './../assets/markers/hotel-geotiller.svg';
import Land from './../assets/markers/land-geotiller.svg';
import SingleFamily from './../assets/markers/singleFamilyHouse-geotiller.svg';
import Office from './../assets/markers/office-geotiller.svg';
import Apartment from './../assets/markers/apartment-geotiller.svg';
import Condo from './../assets/markers/condo-geotiller.svg';
import MixedUse from './../assets/markers/mixedUse-geotiller.svg';
import Retail from './../assets/markers/retail-geotiller.svg';

import { fileTypes } from './../config';

export const getSession = state => state.session
export const getFeatures = state => state.console.features
export const getDocuments = state => state.console.documents.filter((doc) => !doc.isTrashed)
export const getFeatureUUID = state => state.console.featureUUID
export const getPlaceholderFeatureUUID = state => state.console.placeholderFeatureUUID
export const getPlaceId = state => state.console.placeId
export const getAccountMaps = state => state.session.account.maps
export const getMaps = state => state.console.maps
export const getMapUUID = state => state.console.mapUUID
export const getMapUUIDs = state => state.console.mapUUIDs
export const getNavigation = state => state.console.navigation
export const getBookmarks = state => state.bookmarks
export const getViewport = state => state.console.viewport
export const getAssetTypeTopics = state => state.console.assetTypeTopics
export const getFileTypeTopics = state => state.console.fileTypeTopics
export const getMapTypes = state => state.mapTypes
export const getMapTypeKey = state => state.console.mapTypeKey
export const getLocationTypeKey = state => state.console.locationTypeKey
export const getDataTypeKey = state => state.console.dataTypeKey
export const getPropertyStatuses = state => state.console.propertyStatuses

export const selectIcon = (locationType) => {
  switch(locationType) {
    case 'Apartments':
      return Apartment
    case 'Condo':
      return Condo
    case 'Health Care':
      return FlagIcon
    case 'Hotel':
      return Hotel
    case 'Industrial':
      return Industrial
    case 'Land':
      return Land
    case 'Mixed-Use':
      return MixedUse
    case 'Office':
      return Office
    case 'Retail':
      return Retail
    case 'Single Family':
      return SingleFamily
    case 'Generic':
      return FlagIcon
    default:
      return null
  }
}

export const selectIconClass = (locationType) => {
  switch(locationType) {
    case 'Apartments':
      return 'Apartment'
    case 'Condo':
      return 'Condo'
    case 'Health Care':
      return 'FlagIcon'
    case 'Hotel':
      return 'Hotel'
    case 'Industrial':
      return 'Industrial'
    case 'Land':
      return 'Land'
    case 'Mixed-Use':
      return 'MixedUse'
    case 'Office':
      return 'Office'
    case 'Retail':
      return 'Retail'
    case 'Single Family':
      return 'SingleFamily'
    case 'Generic':
      return 'FlagIcon'
    default:
      return null
  }
}


// v1
// export const buildMarker = (locationType, viewport) => {
//   let icon = (selectIcon(locationType) || FlagIcon);
//
//   let marker = {
//     zoomClass: 'small',
//     offsetTop: -10,
//     offsetLeft: -10,
//     width: 20,
//     icon: icon
//   };
//   let popup = {
//     offsetTop: -10,
//     offsetLeft: -10
//   }
//   var fullWidth = 50;
//   marker.width = (viewport.zoom/10)*fullWidth;
//
//   marker.offsetTop = -(((viewport.zoom/10)*marker.width)/2);
//   marker.offsetLeft = -(((viewport.zoom/10)*marker.width)/2);
//   popup.offsetTop = 50;
//   popup.offsetLeft = -100;
//
//   return {
//     marker,
//     popup
//   };
// }

// v2
// export const buildMarker = (locationType, viewport) => {
//   //console.log("Building Marker => ", locationType);
//   let icon = (selectIcon(locationType) || FlagIcon);
//
//   let marker = {
//     zoomClass: 'small',
//     offsetTop: 0,
//     offsetLeft: 0,
//     width: 20,
//     icon: icon
//   };
//   let popup = {
//     offsetTop: -200,
//     offsetLeft: -200
//   }
//
//   //console.log(viewport.zoom);
//
//   var baseline = {
//     width: 50, //as zoom goes down, width decreases
//     height: '50px'
//   }
//
//   var coef = (viewport.zoom/20);
//   coef = (coef > 1) ? 1 : coef;
//
//   var calc = {
//     width: coef*baseline.width
//   }
//
//   var widthPx = 150 * calc.width*0.01;
//
//   //console.log('width px => ', widthPx);
//   //console.log(calc)
//
//
//   //var calcWidth = (viewport.zoom/10)*calc.width;
//   marker.width = calc.width;//(calcWidth<100) ? calcWidth : 100;
//
//   // marker.offsetTop = -(((viewport.zoom/10)*marker.width)/2);
//   marker.offsetTop = -(widthPx);
//   //marker.offsetLeft = -(((viewport.zoom/10)*marker.width)/2);
//
//   marker.offsetLeft = -((widthPx/2));
//
//   if (icon===FlagIcon) {
//     marker.offsetLeft += (widthPx/6);
//   }
//
//   //console.log(marker);
//
//
//   //popup.offsetTop += 20;
//   // if (icon===FlagIcon) {
//   //   marker.offsetLeft+=50;
//   // }
//
//   return {
//     marker,
//     popup
//   };
// }

export const buildMarker = (locationType, viewport) => {
  let icon = (selectIconClass(locationType) || 'FlagIcon');

  let marker = { zoomClass: 'small', offsetTop: 0, offsetLeft: 0, width: 40, icon: icon };
  let popup = { offsetTop: 0, offsetLeft: 0 };

  //baseline flag icon
  if (icon==='FlagIcon') {
    marker.offsetTop = -40;
    marker.offsetLeft = 5;

    popup.offsetLeft = 0;
    popup.offsetTop = 10;

  //baseline building icons
  } else {
    marker.offsetTop = -40;
    marker.offsetLeft = 20;

    popup.offsetLeft = 5;
    popup.offsetTop = 10;
  }

  if (icon==='FlagIcon') {
    if (viewport.zoom < 17) {
      //marker.offsetTop = -(40*(viewport.zoom/17))
      marker.offsetLeft = -10;
    }
  } else {
    if (viewport.zoom < 17) {
      marker.offsetTop = -(40*(viewport.zoom/17))
      marker.offsetLeft = -10;
    }
  }

  return {
    marker,
    popup
  };
}

export const getFilteredAccountMaps = createSelector(
  [getMaps, getAccountMaps],
  (maps, accountMaps) => {
    //hide maps that are not 'active', status=='pending'
    const accountMapUUIDs = accountMaps.map(accountMap => accountMap.uuid);
    const pendingAccountMapUUIDs = accountMaps.filter(accountMap => accountMap.status!=='pending').map(accountMap => accountMap.uuid);

    return maps.filter((map) => accountMapUUIDs.includes(map.uuid)).map((map) => {
      return {
        ...map,
        pending: false //pendingAccountMapUUIDs.includes(map.uuid)
      }
    });
  }
)

export const getSelectedMaps = createSelector(
  [getFilteredAccountMaps, getMapUUIDs],
  (maps, mapUUIDs) => {
    return maps.filter((map) => mapUUIDs.includes(map.uuid));
  }
)

export const getSelectedMap = createSelector(
  [getFilteredAccountMaps, getMapUUID, getSession],
  (maps, mapUUID, session) => {
    if (!maps) {
      //console.log("No getSelectedMap.maps!");
      return null;
    }
    if (!mapUUID) {
      //console.log("No getSelectedMap.mapUUID!");
      return null;
    }
    if (!session) {
      //console.log("No getSelectedMap.session!");
      return null;
    }
    const map = maps.find((map) => mapUUID===map.uuid);
    if (!map) {
      return null;
    }
    const permission = map.permissions.filter(p => p.accountUUID===session.account.uuid);
    return {
      ...map,
      role: ((permission.length===0) ? 'denied' : permission[0].role )
    }
  }
)

export const getLocationTypes = createSelector(
  [getMapTypes, getSelectedMap],
  (mapTypes, map) => {
    if (!mapTypes) {
      console.log("No getLocationTypes.mapTypes!");
      return [];
    }
    if (!map) {
      console.log("No getLocationTypes.map!");
      return [];
    }
    return Object.keys(mapTypes[map.mapType]).filter((locationType) => locationType!=="Shared");
  }
)

export const getMapType = createSelector(
  [getMapTypes, getMapTypeKey],
  (mapTypes, mapTypeKey) => {
    return mapTypes[mapTypeKey];
  }
)

export const getLocationType = createSelector(
  [getMapType, getLocationTypeKey],
  (mapType, locationTypeKey) => {
    if (locationTypeKey) {
      return mapType[locationTypeKey];
    } else {
      return null
    }
  }
)

export const getStatusDataType = createSelector(
  [getMapType, getLocationTypeKey],
  (mapType, locationTypeKey) => {
    var dataType = mapType['Shared'].dataTypes.filter((dataType) => dataType.key==='status' && dataType.fields[0].key==='status_selection')
    return dataType[0]
  }
)

export const getStatusDataTypeChoices = createSelector(
  [getStatusDataType],
  (statusDataType) => {
    return statusDataType.fields[0].choices
  }
)

export const getLifecycleDataType = createSelector(
  [getMapType, getLocationTypeKey],
  (mapType, locationTypeKey) => {
    var dataType = mapType['Shared'].dataTypes.filter((dataType) => dataType.key==='lifecycle' && dataType.fields[0].key==='lifecycle_selection')
    return dataType[0]
  }
)

export const getLocationNameDataType = createSelector(
  [getMapType, getLocationTypeKey],
  (mapType, locationTypeKey) => {
    var dataType = mapType['Shared'].dataTypes.filter((dataType) => dataType.key==='building_name' && dataType.fields[0].key==='building_name')
    return dataType[0]
  }
)


export const getSharedDataTypes = createSelector(
  [getMapType],
  (mapType) => {
    if (mapType && mapType.Shared && mapType.Shared.dataTypes) {
      return mapType.Shared.dataTypes || []
    } else {
      return []
    }
  }
)

export const getDataTypes = createSelector(
  [getLocationType, getSharedDataTypes],
  (locationType, sharedDataTypes) => {
    if (locationType && locationType.dataTypes) {
      return locationType.dataTypes.concat(sharedDataTypes) || []
    } else {
      return []
    }
  }
)

export const getDataType = createSelector(
  [getDataTypes, getDataTypeKey],
  (dataTypes, dataTypeKey) => {
    return dataTypes.find((dataType) => dataType.key===dataTypeKey) || {
      fields: []
    }
  }
)

export const getFilteredBookmarks = createSelector(
  [getBookmarks, getViewport, getDocuments, getFeatures, getMaps],
  (bookmarks, viewport, documents, features, maps) => {
    return bookmarks.map((bookmark) => {
      //console.log(bookmark);
      const mapUUID = bookmark.map ? bookmark.map.uuid : null;
      const map = (
        maps.find((map) => map.uuid===mapUUID) || {
          title: null
        }
      );
      const documentUUID = bookmark.document ? bookmark.document.uuid : null;
      const document = (
        documents.find((doc) => doc.uuid===documentUUID) || {
          title: null
        }
      );
      const featureUUID = bookmark.feature ? bookmark.feature.uuid : null;
      const feature = (
        features.find((feature) => feature.uuid===featureUUID) || {
          title: null
        }
      );
      const locationType = null;
      const builder = buildMarker(locationType, viewport);
      return {
        ...bookmark,
        map,
        document,
        feature: {
          ...feature,
          marker: builder.marker,
          popup: builder.popup
        }
      }
    })
  }
)

export const getActiveBookmarks = createSelector(
  [getFilteredBookmarks],
  (bookmarks) => {
    return bookmarks.filter((bookmark) => bookmark.active)
  }
)

export const getMapsByUUID = state => {
  return state.console.maps.reduce((map,obj) => {
    map[obj.uuid] = obj;
    return map;
  }, {})
}

export const getWritableMaps = createSelector(
  [getFilteredAccountMaps, getAccountMaps],
  (maps, accountMaps) => {
    const writableMapUUIDs = accountMaps.filter((map) => ['owner','member'].includes(map.role)).map((map) => map.uuid)
    return maps.filter((map) => writableMapUUIDs.includes(map.uuid));
  }
)

export const getOwnedMaps = createSelector(
  [getFilteredAccountMaps, getAccountMaps],
  (maps, accountMaps) => {
    const ownedMapUUIDs = accountMaps.filter((map) => map.role==='owner').map((map) => map.uuid)
    return maps.filter((map) => ownedMapUUIDs.includes(map.uuid));
  }
)

export const getMemberMaps = createSelector(
  [getFilteredAccountMaps, getAccountMaps],
  (maps, accountMaps) => {
    const memberMapUUIDs = accountMaps.filter((map) => map.role==='member').map((map) => map.uuid)
    return maps.filter((map) => memberMapUUIDs.includes(map.uuid));
  }
)

export const getFollowingMaps = createSelector(
  [getFilteredAccountMaps, getAccountMaps],
  (maps, accountMaps) => {
    const followingMapUUIDs = accountMaps.filter((map) => map.role==='follower').map((map) => map.uuid)
    return maps.filter((map) => followingMapUUIDs.includes(map.uuid));
  }
)

export const getFilteredMaps = createSelector(
  [getFilteredAccountMaps, getOwnedMaps, getMemberMaps, getFollowingMaps, getNavigation],
  (maps, ownedMaps, memberMaps, followingMaps, navigation) => {
    var filteredMaps = {
      maps,
      ownedMaps,
      memberMaps,
      followingMaps
    };
    if (navigation.carddeck.tabcontainer.all) {
      filteredMaps.filteredMaps = maps;
    } else if (navigation.carddeck.tabcontainer.owned) {
      filteredMaps.filteredMaps = ownedMaps;
    } else if (navigation.carddeck.tabcontainer.member) {
      filteredMaps.filteredMaps = memberMaps;
    } else if (navigation.carddeck.tabcontainer.following) {
      filteredMaps.filteredMaps = followingMaps;
    }
    return filteredMaps;
  }
)


export const getDocumentsByFeatureUUID = createSelector(
  [getDocuments],
  (documents) => {
    var featureObj = {}
    documents.forEach((doc) => {
      if (!featureObj[doc.featureUUID]) featureObj[doc.featureUUID] = []
      featureObj[doc.featureUUID].push(doc)
    })
    return featureObj
  }
)

export const getFeatureByUUID = createSelector(
  [getFeatures, getDocumentsByFeatureUUID],
  (features, documentsByFeatureUUID) => {
    return features.reduce((obj, item) => {
      obj[item.uuid] = {
        ...item,
        documents: documentsByFeatureUUID[item.uuid]
      };
      return obj
    }, {})
  }
)

export const getAssetTypeTopicNames = createSelector(
  [getAssetTypeTopics],
  (assetTypeTopics) => {
    return assetTypeTopics.map((topic) => topic.assetType)
  }
)

export const getFileTypeTopicNames = createSelector(
  [getFileTypeTopics],
  (fileTypeTopics) => {
    return fileTypeTopics.map((topic) => topic.fileType)
  }
)

export const getFeatureTree = createSelector(
  [getMapUUIDs, getFeatures],
  (mapUUIDs, features) => {
    var featureTree = [];
    features.forEach((feature) => {
      //setup root items with no parent features
      if (!feature.parentFeatureUUID) {
        let thisFeature = Object.assign(feature, { expanded: false, children: [] })
        featureTree.push(thisFeature);
      }
    })
    features.forEach((feature) => {

      //find parent node, append to children array
      if (feature.parentFeatureUUID) {
        featureTree.forEach((f) => {
          if (f.uuid===feature.parentFeatureUUID) {
            let thisFeature = Object.assign(feature, { expanded: false, children: [] })
            f.children.push(thisFeature);
          }
        })

      }
    })
    return featureTree;
  }
)


export const getFilteredDocuments = createSelector(
  [getMapUUIDs, getAssetTypeTopicNames, getFileTypeTopicNames, getDocuments, getFeatureByUUID],
  (mapUUIDs, assetTypeTopicNames, fileTypeTopicNames, documents, featureByUUID) => {

    return documents.filter((document) => {
      return mapUUIDs.includes(document.mapUUID)
    }).filter((document) => {

      //both assetType and fileType filter
      if (assetTypeTopicNames.length > 0 && fileTypeTopicNames.length > 0) {
        if (!document.assetTypeTopic || !document.fileTypeTopic) return false;
        return assetTypeTopicNames.includes(document.assetTypeTopic.assetType) && fileTypeTopicNames.includes(document.fileTypeTopic.fileType)
      }
      //only assetType filter
      else if (assetTypeTopicNames.length > 0 && fileTypeTopicNames.length === 0) {
        if (!document.assetTypeTopic) return false;
        return assetTypeTopicNames.includes(document.assetTypeTopic.assetType)

      //only fileType filter
      } else if (assetTypeTopicNames.length === 0 && fileTypeTopicNames.length > 0) {
        if (!document.fileTypeTopic) return false;
        return fileTypeTopicNames.includes(document.fileTypeTopic.fileType)
      }

      // if no filters set, return all documents
      else {
        return true
      }
    }).map((document) => {
      return {
        ...document,
        feature: featureByUUID[document.featureUUID]
      };
    });
  }
)

export const getFeature = createSelector(
  [getFeatureUUID, getFeatures, getDocuments],
  (featureUUID, features, documents) => {
    return {
      uuid: null,
      location: {
        place_id: null
      },
      ...features.filter((feature) => feature.uuid===featureUUID)[0],
      documents: documents.filter((document) => {
        return document.featureUUID===featureUUID;
      })
    }
  }
)

export const getFeatureWithMarker = createSelector(
  [getFeatureUUID, getFeatures, getDocuments],
  (featureUUID, features, documents) => {
    const feature = features.find((feature) => feature.uuid===featureUUID);
    if (!feature) return null;
    const locationType = null;
    const build = buildMarker(locationType, {
      zoom: 15
    });
    return {
      uuid: null,
      location: {
        place_id: null
      },
      ...feature,
      documents: documents.filter((document) => {
        return document.featureUUID===featureUUID;
      }),
      marker: build.marker,
      popup: build.popup,
    }
  }
)

export const getFilteredFeatures = createSelector(
  [getMapUUIDs, getViewport, getFeatures, getDocuments, getFilteredDocuments, getDocumentsByFeatureUUID, getMapsByUUID],
  (mapUUIDs, viewport, features, documents, filteredDocuments, documentsByFeatureUUID, mapsByUUID) => {
    return features.filter((feature) => {
      return mapUUIDs.includes(feature.mapUUID) && feature.location;
    }).map((feature) => {
      let locationType = null;
      let build = buildMarker(locationType, viewport);
      return {
        ...feature,
        map: mapsByUUID[feature.mapUUID],
        marker: build.marker,
        popup: build.popup,
        documents: documents,
        fileTypeTopics: documents.filter((doc) => doc.fileTypeTopic).map((doc) => doc.fileTypeTopic),
        assetTypeTopics: documents.filter((doc) => doc.assetTypeTopic).map((doc) => doc.assetTypeTopic)
      }
    });
  }
)

export const getJoinedFeatures = createSelector(
  [getFeature, getFilteredFeatures],
  (feature, features) => {
    return features.filter((f) => f.location).filter((f) => f.location.place_id===feature.location.place_id)
  }
)

export const getFeaturesByPlaceId = createSelector(
  //[getPlaceId, getFilteredFeatures],
  [getPlaceId, getFeatures],
  (placeId, features) => {
    return features.filter((f) => f.location).filter((f) => f.location.place_id===placeId)
  }
)

export const getDocumentsByPlaceId = createSelector(
  [getPlaceId, getFilteredDocuments],
  (placeId, documents) => {
    return documents
      .filter((f) => f.feature)
      .filter((f) => f.feature.location)
      .filter((f) => f.feature.location.place_id===placeId)
      .map(doc => doc)
  }
)

export const getDocumentsWithFeatures = createSelector(
  [getFilteredDocuments, getFeatures],
  (documents, features) => {
    return documents.map((document) => {
      return {
        ...document,
        feature: features.find((feature) => feature.uuid===document.featureUUID)
      }
    })
  }
)

export const getFeatureDocuments = createSelector(
  [getDocumentsWithFeatures, getJoinedFeatures],
  (documents, features) => {
    let featureUUIDs = features.map((feature) => feature.uuid)
    return documents.filter((document) => featureUUIDs.includes(document.featureUUID))
  }
)

export const getPlaceDocumentsByType = createSelector(
  [getDocumentsWithFeatures, getFeaturesByPlaceId],
  (documents, features) => {
    const featureUUIDs = features.map((feature) => feature.uuid)
    const featureDocuments = documents.filter((document) => featureUUIDs.includes(document.featureUUID));
    const data = featureDocuments.filter((document) => document.type==='DATA');
    let dataByField = [];
    data.forEach((d) => {
      Object.keys(d.dataTable).forEach((k) => {
        d.dataTable[k].forEach((field) => {
          dataByField.push({
            uuid: d.uuid,
            type: d.type,
            author: d.author,
            snapshotAt: field.snapshotAt,
            snapshotAtDateTime: new Date(field.snapshotAt),
            key: field.title,
            value: field.value,
            dataType: d.title
          })
        })
      })
    })

    var dataGroupedByType = {};

    data.forEach((d) => {

      if (!dataGroupedByType[d.title]) {
        dataGroupedByType[d.title] = {
          uuid: d.uuid,
          author: d.author,
          mapUUID: d.mapUUID,
          featureUUID: d.featureUUID,
          dataType: d.title,
          snapshotAt: new Date(),
          records: [],
          recordsByDataUUID: {}
        }
      }

      Object.keys(d.dataTable).forEach((k) => {
        d.dataTable[k].sort((a,b) => {
          return new Date(a.snapshotAt) - new Date(b.snapshotAt);
        }).forEach((field) => {
          if (!dataGroupedByType[d.title]['recordsByDataUUID'][d.uuid]) {
            dataGroupedByType[d.title]['recordsByDataUUID'][d.uuid] = [];
          }
          let record = {
            dataUUID: d.uuid,
            value: field.value,
            title: field.title,
            snapshotAt: field.snapshotAt
          };
          dataGroupedByType[d.title]['recordsByDataUUID'][d.uuid].push(record);
          dataGroupedByType[d.title].records.push(record);
        })
      })

    });

    dataGroupedByType = Object.keys(dataGroupedByType).map((dataType) => {
      // sort for document-list data list -- latest entry on bottomm
      var records = dataGroupedByType[dataType].records.sort((a,b) => {
        return new Date(a.snapshotAt) - new Date(b.snapshotAt);
      });
      dataGroupedByType[dataType].records = records;
      if (records.length>0) {
        dataGroupedByType[dataType].snapshotAt = records[records.length-1].snapshotAt;
      }
      return dataGroupedByType[dataType]
    });

    //console.log(dataGroupedByType);
    dataGroupedByType = dataGroupedByType.sort((a,b) => {
      return new Date(a.snapshotAt) - new Date(b.snapshotAt);
    })

    dataByField.sort((a,b) => {
      return new Date(a.snapshotAtDateTime) - new Date(b.snapshotAtDateTime);
    })

    const statusSelections = dataByField.filter((field) => field.key==='Status Selection').map((field) => field).sort((a,b) => {
      return new Date(b.snapshotAt) - new Date(a.snapshotAt);
    });
    const lifecycleSelections = dataByField.filter((field) => field.key==='Lifecycle Selection').map((field) => field).sort((a,b) => {
      return new Date(b.snapshotAt) - new Date(a.snapshotAt);
    });

    const selectedStatus = ((statusSelections[0]) ? statusSelections[0].value : null);
    const selectedLifecycle = ((lifecycleSelections[0]) ? lifecycleSelections[0].value : null);

    let sortedFiles = featureDocuments.filter((document) => fileTypes.includes(document.type)).sort((a,b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    let sortedLinks = featureDocuments.filter((document) => document.type==='LINK').sort((a,b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });

    return {
      files: sortedFiles,
      links: sortedLinks,
      filesAndLinks: [...sortedFiles, ...sortedLinks],
      data,
      dataGroupedByType,
      selectedStatus,
      selectedLifecycle,
      dataByField: dataByField.sort((a,b) => {
        return new Date(a.snapshotAtDateTime) - new Date(b.snapshotAtDateTime);
      })
    }
  }
)


export const getFeaturesWithDocuments = createSelector(
  [getMapUUIDs, getFilteredDocuments, getFeatures],
  (mapUUIDs, filteredDocuments, features) => {
    return features.filter((feature) => {
      return mapUUIDs.includes(feature.mapUUID) && feature.location;
    }).map((feature) => {
      const documents = filteredDocuments.filter(doc => doc.featureUUID === feature.uuid);
      const dataDocuments = documents.filter(doc => doc.type==='DATA'&&doc.dataTable)
        .filter(doc => Object.keys(doc.dataTable).includes('status'))
        .map(doc => doc)
        .sort((a,b) => {
          return new Date(a.dataTable.status[0].snapshotAt) - new Date(b.dataTable.status[0].snapshotAt);
        })
      var targetProperty = false;
      if (dataDocuments.length !== 0) {
        if (dataDocuments.filter(d => (d && d.dataTable && d.dataTable.status && d.dataTable.status[0] && d.dataTable.status[0].key==='status_selection' && d.dataTable.status[0].value==="Target Property")).length > 0) {
          targetProperty = true;
        }
      }
      return {
        ...feature,
        documents,
        dataDocuments,
        targetProperty,
        fileTypeTopics: filteredDocuments.filter((doc) => doc.fileTypeTopic).map((doc) => doc.fileTypeTopic),
        assetTypeTopics: filteredDocuments.filter((doc) => doc.assetTypeTopic).map((doc) => doc.assetTypeTopic)
      }
    });
  }
)

export const getFeedFeatures = createSelector(
  [getFeaturesWithDocuments],
  (features) => {
    //console.log(features);
    return features.map((feature) => {
      let i, chunk=3, photoRows=[];
      for (i=0; i<feature.documents.length; i+=chunk) {
        photoRows.push( feature.documents.slice(i,i+chunk) )
      }
      return {
        ...feature,
        photoRows: photoRows
      }
    })
  }
)

export const getFeedFeaturesByPlaceId = createSelector(
  [getFeaturesWithDocuments, getViewport],
  (features, viewport) => {
    if (!viewport || !viewport.bounds || !viewport.bounds._sw || !viewport.bounds._sw.lng) {
      console.log("%cError return empty array from cgetFeedFeaturesByPlaceId", "color: red");
      return [];
    } else {
      //console.log("%cLooking good getFeedFeaturesByPlaceId ", "color: blue");
      //console.log({viewport, features});
    }
    var boundingBox = bboxPolygon([
      viewport.bounds._sw.lng,
      viewport.bounds._ne.lat,
      viewport.bounds._ne.lng,
      viewport.bounds._sw.lat,
    ]);
    return features.filter((feature) => {
      if (!feature.polygon) return true;
      let intersection = intersect(boundingBox, feature.polygon);
      return (intersection) ? true : false
    }).reduce((map,obj) => {
      if (!map[obj.location.place_id]) map[obj.location.place_id]=[];
      map[obj.location.place_id].push(obj)
      return map;
    }, {})

    // no viewport scoping
    // return features.reduce((map,obj) => {
    //   if (!map[obj.location.place_id]) map[obj.location.place_id]=[];
    //   map[obj.location.place_id].push(obj)
    //   return map;
    // }, {})
  }
)

export const getFeedFeaturesByMapUUID = createSelector(
  [getFeaturesWithDocuments],
  (features) => {
    return features.reduce((map,obj) => {
      if (!map[obj.mapUUID]) map[obj.mapUUID]=[];
      map[obj.mapUUID].push(obj)
      return map;
    }, {})

  }
)

export const getPlace = createSelector(
  [getPlaceId, getFeaturesByPlaceId, getDocumentsByPlaceId, getSelectedMaps, getViewport],
  (placeId, features, documents, selectedMaps, viewport) => {
    var data = documents.filter(doc => doc.type==='DATA')

    // start: get location type from LAST recorded document
    var locationTypeKey = 'Generic';

    if (data.length!==0) {
      var lastDoc = data.reduce( (acc, cur) => {
        var d1, d2;
        let d1Keys = Object.keys(acc.dataTable);
        let d2Keys = Object.keys(cur.dataTable);
        if (d1Keys.length===0 || d2Keys.length===0) return acc;
        let d1Key = d1Keys[0];
        let d2Key = d2Keys[0];

        if (!d1Key || !acc.dataTable[d1Key] ||  !acc.dataTable[d1Key][0] || !acc.dataTable[d1Key][0].snapshotAt || !d2Key || !cur.dataTable[d2Key] || !cur.dataTable[d2Key][0] || !cur.dataTable[d2Key][0].snapshotAt) return acc;

        d1 = new Date(acc.dataTable[d1Key][0].snapshotAt);
        d2 = new Date(cur.dataTable[d2Key][0].snapshotAt);

        return (d1 > d2) ? acc : cur;
      })

      if (lastDoc) {
        let keys = Object.keys(lastDoc.dataTable);
        if (keys.length>0) {
          let key = keys[0];
          locationTypeKey = lastDoc.dataTable[key][0].locationTypeKey;
        }
      }
    }
    // end: get location type from LAST recorded document

    // start: set up place name
    var name = "";
    if (features.length>0) name = features[0].title;

    var locationNameData = data.filter(doc => doc.title==="Building/Location Name");

    if (locationNameData.length!==0) {
      var locationDoc = data.filter(doc => doc.title==="Building/Location Name").reduce( (acc, cur) => {
        let d1 = new Date(acc.dataTable['building_name'][0].snapshotAt);
        let d2 = new Date(cur.dataTable['building_name'][0].snapshotAt);
        return (d1 > d2) ? acc : cur;
      })

      if (locationDoc && locationDoc.dataTable && locationDoc.dataTable.building_name && locationDoc.dataTable.building_name.length>0 && locationDoc.dataTable.building_name[0].value) {
        name = locationDoc.dataTable.building_name[0].value;
      }
    }
    // end: set up place name

    let featureMaps = features.map(feature => feature.mapUUID);
    let mapUUIDs = [...new Set(featureMaps)];
    let maps = selectedMaps.filter((map) => mapUUIDs.includes(map.uuid));

    var links = documents.filter(doc => doc.type==='LINK').map(doc => doc);

    let build = buildMarker(locationTypeKey, viewport);

    return {
      id: placeId,
      name,
      locationTypeKey,
      icon: (selectIcon(locationTypeKey) || FlagIcon),
      popup: build.popup,
      documents,
      maps,
      jobs: documents.filter(doc => !['DATA'].includes(doc.type)).map(doc => doc),
      files: documents.filter(doc => !['LINK','DATA'].includes(doc.type)).map(doc => doc),
      links,
      photos: links.filter(link => link.og && link.og.image).map((link) => link.og.image),
      data,
      location: {
        place_id: null
      },
      features,
      ...features[0]
    }
  }
)

export const getFeatureByMapAndPlace = createSelector(
  [getPlaceId, getFeaturesByPlaceId, getMapUUID],
  (placeId, features, mapUUID) => {
    let feature = features.filter(feature => feature.location.place_id).find(feature => feature.mapUUID===mapUUID && feature.location.place_id===placeId);

    if (!feature) return null;
    let locationType = null;
    let build = buildMarker(locationType, {
      zoom: 15
    });
    return {
      uuid: null,
      location: {
        place_id: null
      },
      ...feature,
      marker: build.marker,
      popup: build.popup,
    }
  }
)

export const getPropertyStatusesFromDocuments = createSelector(
  [getPropertyStatuses, getDocuments],
  (propertyStatuses, documents) => {
    const data = documents.filter(doc => doc.type==='DATA');
    var statusSelections = [];
    data.forEach((record) => {
      Object.keys(record.dataTable || {}).forEach((dataTypeKey) => {
        if (record.dataTable[dataTypeKey] && Array.isArray(record.dataTable[dataTypeKey])) {
          record.dataTable[dataTypeKey].forEach((dataType) => {

            if (dataType.key==='status_selection') {
              statusSelections.push(dataType.value);
            }

          })
        }
      })
    });
    return statusSelections
  }
)


export const getPropertyStatusesForMap = createSelector(
  [getPropertyStatusesFromDocuments, getPropertyStatuses, getStatusDataTypeChoices],
  (propertyStatusesFromDocuments, propertyStatuses, statusDataTypeChoices) => {

    var propertyStatusesForMap = (propertyStatuses || []).sort((a,b) => { return a.index - b.index })
      .map(propertyStatus => propertyStatus.status)
      .filter(propertyStatus => statusDataTypeChoices.includes(propertyStatus))
      .filter(propertyStatus => propertyStatusesFromDocuments.includes(propertyStatus))

    propertyStatusesFromDocuments.forEach((propertyStatus) => {
      if (!propertyStatusesForMap.includes(propertyStatus)) {
        propertyStatusesForMap.push(propertyStatus)
      }
    })
    // console.log('getPropertyStatusesForMap', {propertyStatusesForMap })
    return propertyStatusesForMap;
  }
)

export const getFeedPlacesByStatus = createSelector(
  [getFeedFeaturesByPlaceId, getFeedFeaturesByMapUUID, getViewport, getPropertyStatusesForMap],
  (featuresByPlaceId, featuresByMapUUID, viewport, statuses) => {


    var richPlaces = Object.keys(featuresByPlaceId).map((placeId) => {
      var features = featuresByPlaceId[placeId];
      var lifecycleSelectionRecords = [];
      var lifecycleSelections = [];
      var buildingNameRecords = [];
      var buildingNames = [];
      var statusSelectionRecords = [];
      var statusSelections = [];
      var allFeatureRecords = [];
      features.forEach((feature) => {
        let data = feature.documents.filter(doc => doc.type==='DATA');

        data.forEach((record) => {
          Object.keys(record.dataTable || {}).forEach((dataTypeKey) => {

            allFeatureRecords.push({
              featureUUID: feature.uuid,
              locationTypeKey: get(record, 'dataTable[dataTypeKey][0].locationTypeKey'),
              snapshotAt: get(record, 'dataTable[dataTypeKey][0].snapshotAt'),
              snapshotAtDateTime: new Date(get(record, 'dataTable[dataTypeKey][0].snapshotAt')),
            })

            if (record.dataTable[dataTypeKey] && Array.isArray(record.dataTable[dataTypeKey])) {
              record.dataTable[dataTypeKey].forEach((dataType) => {
                if (dataType.key==='lifecycle_selection') {
                  lifecycleSelectionRecords.push(dataType)
                }
                if (dataType.key==='status_selection') {
                  statusSelectionRecords.push(dataType)
                }
                if (dataType.key==='building_name') {
                  buildingNameRecords.push(dataType);
                }
              })
            }
          })
        });

      });

      allFeatureRecords.sort((a,b) => {
        return new Date(a.snapshotAtDateTime) - new Date(b.snapshotAtDateTime);
      })

      lifecycleSelections = lifecycleSelectionRecords.sort((a,b) => {
        return new Date(a.snapshotAt) - new Date(b.snapshotAt);
      }).map(record => record.value);

      statusSelections = statusSelectionRecords.sort((a,b) => {
        return new Date(a.snapshotAt) - new Date(b.snapshotAt);
      }).map(record => record.value);

      buildingNames = buildingNameRecords.sort((a,b) => {
        return new Date(a.snapshotAt) - new Date(b.snapshotAt);
      }).map(record => record.value);

      var locationData = allFeatureRecords.sort((a,b) => {
        return new Date(a.snapshotAtDateTime) - new Date(b.snapshotAtDateTime);
      })

      var locationType = 'Generic';
      if (locationData.length>0) {
        locationType = locationData[locationData.length-1].locationTypeKey;
      }

      let build = buildMarker(locationType, viewport);

      var name = "";

      if (features.length>0) {
        name = features[0].title;
      }

      if (buildingNames.length>0) {
        name = buildingNames[buildingNames.length-1];
      }

      var maps = []

      var photoSet = []

      return {
        ...features[0],
        id: placeId,
        lifecycleSelections,
        statusSelections,
        name,
        photoSet,
        marker: build.marker,
        icon: (selectIcon(locationType) || FlagIcon),
        maps
      }
    })

    var returnStatuses = {
      'Unsorted': []
    };

    statuses.forEach(s => {
      returnStatuses[s] = [];
    });

    richPlaces.forEach((place) => {
      if (place.statusSelections.length===0) {
        returnStatuses['Unsorted'].push(place)
      } else {
        if (returnStatuses[place.statusSelections[place.statusSelections.length-1]]) {
          returnStatuses[place.statusSelections[place.statusSelections.length-1]].push(place)
        } else {
          returnStatuses['Unsorted'].push(place)
        }
      }
    })

    //remove keys with empty arrays (so we don't display status buckets with no places)
    Object.keys(returnStatuses).forEach((status) => {
      if (status!=='Unsorted' && returnStatuses[status].length===0) {
        delete returnStatuses[status]
      }
    })

    return returnStatuses

  }
)




export const getFeedPlaces = createSelector(
  [getFeedFeaturesByPlaceId, getFeedFeaturesByMapUUID, getViewport],
  (featuresByPlaceId, featuresByMapUUID, viewport) => {
    if (!viewport || !viewport.bounds) {
      return [];
    } else {
      //console.log("%cLooking good getFeedPlaces", "color: blue");
      //console.log({viewport, featuresByPlaceId});
    }
    return Object.keys(featuresByPlaceId).map((placeId) => {
      var features = featuresByPlaceId[placeId];
      var featureMapUUIDs = features.map((feature) => feature.mapUUID);
      var uniqueFeatureMapUUIDs = [...new Set(featureMapUUIDs)];

      var name = "";

      if (features.length>0) {
        name = features[0].title;
      }

      var documents = [];

      var maps = uniqueFeatureMapUUIDs.map((mapUUID) => {
        var mapFeatures = featuresByMapUUID[mapUUID];
        var photoRows = [];
        mapFeatures.filter((feature) => feature.location.place_id===placeId).forEach((feature) => {
          if (feature.documents) {
            documents = documents.concat(feature.documents);
          }
          var featureDocuments = feature.documents.filter((doc) => doc.type==='LINK' && (doc.og && doc.og.image));
          var i, chunk=3;
          for (i=0; i<featureDocuments.length; i+=chunk) {
            photoRows.push( featureDocuments.slice(i,i+chunk) )
          }
        })
        return {
          uuid: mapUUID,
          feature: {
            ...features[0],
            photoRows
          }
        }
      });

      var data = documents.filter(doc => doc.type==='DATA');

      // @fixme
      // possibly issue with data structure
      // dataTable is array but this code is expecting it to be oject
      let locationDataArr = data.map(doc => {
        return Object.keys(doc.dataTable || {})
          .filter(key => {
            const locationTypeKey = get(doc, `dataTable[${key}][0].locationTypeKey`, null);
            return locationTypeKey ? true : false
          }).map(key => ({
            locationTypeKey: get(doc, `dataTable[${key}][0].locationTypeKey`, null),
            snapshotAt: get(doc, `dataTable[${key}][0].snapshotAt`, null),
            snapshotAtDateTime: new Date(doc.dataTable[key][0].snapshotAt)
          }));
      })

      var locationData = [];
      locationDataArr.forEach(arr1 => {
        arr1.forEach(arr2 => {
          locationData.push(arr2);
        })
      })

      locationData = locationData.sort((a,b) => {
        return new Date(a.snapshotAtDateTime) - new Date(b.snapshotAtDateTime);
      })

      var locationType = 'Generic';
      if (locationData.length>0) {
        locationType = locationData[locationData.length-1].locationTypeKey;
      }

      let build = buildMarker(locationType, viewport);

      var locationNameDocuments = data.filter(doc => doc.title==="Building/Location Name")

      if (locationNameDocuments.length>0) {
        let lnd = locationNameDocuments[0];
        if (lnd && lnd.dataTable && lnd.dataTable.building_name && lnd.dataTable.building_name.length>0 && lnd.dataTable.building_name[0].value) {
          name = lnd.dataTable.building_name[0].value;
        }
      }

      var photoSet = []
      uniqueFeatureMapUUIDs.forEach((mapUUID) => {
        var mapFeatures = featuresByMapUUID[mapUUID];

        mapFeatures.filter((feature) => feature.location.place_id===placeId).forEach((feature) => {
          if (feature.documents) {
            documents = documents.concat(feature.documents);
          }
          var featureDocuments = feature.documents.filter((doc) => doc.type==='LINK' && (doc.og && doc.og.image));
          featureDocuments.forEach((document) => {
            photoSet.push({
              src: document.og.image,
              width: 3.5,
              height: 3.5
            })
          })
        })
      });

      var feature = features[0];
      return {
        ...feature,
        id: placeId,
        name,
        photoSet,
        popup: build.popup,
        marker: build.marker,
        icon: (selectIcon(locationType) || FlagIcon),
        maps
      }
    })
  }
)

export const getFeedPlacesByMapUUID = createSelector(
  [getFeedFeaturesByMapUUID, getMapUUID],
  (featuresByMapUUID, mapUUID) => {

    const features = featuresByMapUUID[mapUUID];

    console.log('getFeedPlacesByMapUUID');
    console.log(features);

    if (!features) return []

    return features.map((feature) => {

      let name = feature.title;

      // var locationNameDocuments = data.filter(doc => doc.title==="Building/Location Name")
      //
      // if (locationNameDocuments.length>0) {
      //   let lnd = locationNameDocuments[0];
      //   if (lnd && lnd.dataTable && lnd.dataTable.building_name && lnd.dataTable.building_name.length>0 && lnd.dataTable.building_name[0].value) {
      //     name = lnd.dataTable.building_name[0].value;
      //   }
      // }

      return {
        ...feature,
        name
      }
    })

  }
)

export const getFeaturesMapByPlaceId = createSelector(
  [getFeatures],
  (features) => {
    return features.reduce((map,obj) => {
      if (!map[obj.location.place_id]) map[obj.location.place_id]=[];
      map[obj.location.place_id].push(obj)
      return map;
    }, {})
  }
)

export const getFilteredFeaturesByPlaceId = createSelector(
  [getFilteredFeatures],
  (filteredFeatures) => {
    return filteredFeatures.reduce((map,obj) => {
      if (!map[obj.location.place_id]) map[obj.location.place_id]=[];
      map[obj.location.place_id].push(obj)
      return map;
    }, {})
  }
)

export const getDocumentsByMapAndFeature = createSelector(
  [getMapUUIDs, getFeature, getFeatures, getDocuments],
  (mapUUIDs, feature, features, documents) => {
    return documents.filter((document) => {
      return mapUUIDs.includes(document.mapUUID) && document.featureUUID===feature.uuid;
    });
  }
)
