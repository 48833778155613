
import React, {Component} from 'react'

import DotsIcon from './../../assets/zondicons/dots-horizontal-triple.svg';

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';


const SortableItem = SortableElement(({index, status}) =>
  <li key={index}>
    <div className='feature'>
      <div className='feature__item'>
        <div><img alt='Place' src={DotsIcon} /></div>
        <div className='title'>
          <span>{status}</span>
        </div>
      </div>
    </div>
  </li>
);

const SortableList = SortableContainer(({items}) => {
  return (
    <ul className='feature-panel'>
      {items.map((status, index) => (
        <SortableItem key={`item-${index}`} index={index} status={status} />
      ))}
    </ul>
  );
});


class StatusList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.propertyStatuses
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    console.log("onSortEnd");
    var itemsNew = arrayMove(this.state.items, oldIndex, newIndex);
    this.props.setPropertyStatusOrder(itemsNew);
    this.setState({
      items: itemsNew
    });
  };

  render() {
    return (
      <SortableList items={this.state.items} onSortEnd={this.onSortEnd} hideSortableGhost={false}/>
    )
  }
};

export default StatusList
