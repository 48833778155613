import React, {Component} from 'react'
//import PropTypes from 'prop-types'

//import SplitPane from 'react-split-pane';

//import { store, history } from '../../util/Store'

import DesktopNav from './DesktopNav'
import DesktopFooter from './DesktopFooter'

//import DesktopList from './DesktopList/DesktopList'
import DocumentList from './DocumentList/DocumentList'
import FeatureMap from './FeatureMap'
import Profile from './../Profile/Profile'
import CustomizePropertyStatus from './CustomizePropertyStatus'
//import { Auth } from 'aws-amplify';

import FeatureItemFeedHoc from './../../containers/FeatureItemFeedHoc'

import MapIcon from './../../assets/color-map.svg';
import PartitionIcon from './../../assets/container.svg';
import LayerIcon from './../../assets/zondicons/layers.svg';
import SaveIcon from './../../assets/zondicons/close-outline.svg';

import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import appConfig from './../../config'

import './GeoDesktop.css';

class GeoDesktop extends Component {

  constructor(props) {
    super(props);

    // document.__geotiller__ = {
    //   version: appConfig.AppVersion,
    // };

    this.resize = (size) => this._resize(size);
  }

  componentDidMount() {

    const {
      getAccount,
      //resetAccount,
      setPlaceId,
      selectMap,
      setDocPaneListView,
      setDocPaneMapView,
      setSidebarWidth,
      accountUUID,
      hideUserDropdown,
      history,
      selectedMap,
      isRegularAccountType,
    } = this.props;

    var { placeId, view, mapUUID } = this.props.match.params

    //if (accountUUID) resetAccount(accountUUID);

    console.log('HUH', { accountUUID })
    if (accountUUID) getAccount(accountUUID)

    if (mapUUID) selectMap({uuid: mapUUID});

    // LA Job Openings
    if (!isRegularAccountType) selectMap({ uuid: 'd519c48d-d14d-4797-b762-1f5ddf2f826e'});

    setPlaceId( ((placeId==='null') ? null : placeId) || null);

    if (view==='docpane') {
      //on docpane view, deselect map, refresh screen
      if (!selectedMap) {
        history.push(`/desktop`);
        return;
      } else {
        setDocPaneListView()
      }
    } else if (view==='map') {
      setDocPaneMapView()
    } else {
      setDocPaneMapView()
    }

    //show infomap selection if no maps selected
    // if (filteredMaps.maps.length===0 || selectedMaps.length===0) {
    //   setSubnav(true)
    // }

    // if width of screen is restricted, remove sidebar
    // @fixme if resize after component did mount, then back to full screen, it doesn't modify
    if (window.innerWidth <= 760) setSidebarWidth(0);

    // if drawer is open, and the user clicks outside of the drawer, close the drawer
    window.addEventListener('click', (e) => hideUserDropdown());

    // if the app picks up a viewport from cache and the user reloads the page, the map width & height may not reflect the current browser width & height
    window.addEventListener('resize', this.resize);
    setTimeout(() => this.resize(), 400);
  }

  componentDidUpdate(prevProps) {

    const {
      setPlaceId,
      setDocPaneListView,
      setDocPaneMapView,
      setPlaceAnimate,
      place,
      match
    } = prevProps;

    const nextProps = this.props;

    if (nextProps.match.params.placeId !== match.params.placeId) {
      setPlaceId(nextProps.match.params.placeId);
    }

    if (nextProps.place.id !== place.id) {
      if ( nextProps.place && nextProps.place.location && nextProps.place.location.lat && nextProps.place.location.lng ) {
        setPlaceAnimate(nextProps.place);
      }
    }

    if (nextProps.match.params.view !== match.params.view) {
      if (nextProps.match.params.view==='docpane') {
        setDocPaneListView();
      } else if (nextProps.match.params.view==='map') {
        setDocPaneMapView();
      } else {
        console.log("profile");
      setDocPaneMapView()
      }
      this.resize();
    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  _resize = (size) => {
    this.props.updateViewport({
      ...this.props.console.viewport,
      width: window.innerWidth,//-this.props.navigation.sidebar.width,
      height: window.innerHeight
    })
  };

  render() {

    const { session: { tenant: { nomenclature: { partition: { singular: Partition, plural: Partitions } } } } } = this.props;

    return (
      <div>
        <NotificationContainer enterTimeout={10} leaveTimeout={10} />
        <DesktopNav
          toggleSubnav={this.props.toggleSubnav}
          toggleBookmarkView={this.props.toggleBookmarkView}
          filteredMaps={this.props.filteredMaps}
          mapUUID={this.props.console.mapUUID}
          mapUUIDs={this.props.console.mapUUIDs}
          navigation={this.props.navigation}
          deselectMap={this.props.deselectMap}
          selectMap={this.props.selectMap}
          selectedMap={this.props.selectedMap}
          setCardDeckTab={this.props.setCardDeckTab}
          createMap={this.props.createMap}
          buildMap={this.props.buildMap}
          accountUUID={this.props.accountUUID}
          toggleInfomapEditor={this.props.toggleInfomapEditor}
          setMapUUID={this.props.setMapUUID}
          updateMap={this.props.updateMap}
          //resetMaps={this.props.resetMaps}
          getMaps={this.props.getMaps}
          setBookmarkViewHeight={this.props.setBookmarkViewHeight}
          updateBookmarkViewViewport={this.props.updateBookmarkViewViewport}
          feature={this.props.feature}
          unsetFeatureUUID={this.props.unsetFeatureUUID}
          setFeatureUUID={this.props.setFeatureUUID}
          resetBookmarks={this.props.resetBookmarks}
          filteredBookmarks={this.props.filteredBookmarks}
          activeBookmarks={this.props.activeBookmarks}
          toggleBookmark={this.props.toggleBookmark}
          setFeatureAnimateBookmarkView={this.props.setFeatureAnimateBookmarkView}
          setError={this.props.setError}
          account={this.props.account}
          toggleUserDropdown={this.props.toggleUserDropdown}
          toggleHamburger={this.props.toggleHamburger}
          history={this.props.history}
          resetConsole={this.props.resetConsole}
          resetSession={this.props.resetSession}
          setSubnav={this.props.setSubnav}
          toggleProfile={this.props.toggleProfile}
          trashMap={this.props.trashMap}
          togglePitchAndBearing={this.props.togglePitchAndBearing}
          isRegularAccountType={this.props.isRegularAccountType}
          session={this.props.session}
        />

        {this.props.navigation.profile && <Profile toggleProfile={this.props.toggleProfile} account={this.props.account}/>}

        {false && this.props.navigation.hamburger && <div className='SubnavMenu'>
          <button className={(this.props.navigation.subnav||this.props.navigation.bookmarkView.display) ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse'} type="button" onClick={this.props.toggleSubnav}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>}

        <div className={this.props.navigation.subnav ? 'desktop_map_container blur' : 'desktop_map_container'} style={{
          opacity: this.props.navigation.desktopOpacity,
          pointerEvents: (this.props.navigation.subnav) ? 'none' : 'auto',
        }}>

          { this.props.navigation.docpane.map &&
            <FeatureMap
              toggleSubnav={this.props.toggleSubnav}
              places={this.props.places}
              viewport={this.props.console.viewport}
              navigation={this.props.navigation}
              updateViewport={this.props.updateViewport}
              feature={this.props.feature}
              place={this.props.place}
              createFeature={this.props.createFeature}
              featuresByPlaceId={this.props.filteredFeaturesByPlaceId}
              setFeatureUUID={this.props.setFeatureUUID}
              setDocPaneListView={this.props.setDocPaneListView}
              unsetFeatureUUID={this.props.unsetFeatureUUID}
              map={this.props.selectedMap}
              maps={this.props.selectedMaps}
              writableMaps={this.props.writableMaps}
              mapUUID={this.props.mapUUID}
              setMapUUID={this.props.setMapUUID}
              unsetMapUUID={this.props.unsetMapUUID}
              setMapSearchResults={this.props.setMapSearchResults}
              mapSearchResults={this.props.console.mapSearchResults}
              setFeatureAnimate={this.props.setFeatureAnimate}
              history={this.props.history}
              accountUUID={this.props.accountUUID}
              unsetPlaceId={this.props.unsetPlaceId}
              getUserLocation={this.props.getUserLocation}
              mapStyle={this.props.mapStyle}
              invertedMapStyle={this.props.invertedMapStyle}
              updateMapStyle={this.props.updateMapStyle}
              isRegularAccountType={this.props.isRegularAccountType}
              tenant={this.props.tenant}
            />
          }

          <div className="documents-panel">

              <div className="dp-side-panel">
                <div>
                  <div className={this.props.navigation.map.full ? 'SidePanelContainer SidePanelContainer--full' : 'SidePanelContainer'} >
                    {true && <div className='SidePanelContainer__header'>
                      {this.props.selectedMaps.length===0 && <div className='title'><span>No {Partitions} selected</span></div>}
                      {this.props.selectedMaps.length>0 && this.props.selectedMaps.map((map, index) => <div key={index} className='title'><img alt='Partition' src={PartitionIcon}/><span>{map.title}</span></div>)}
                      {false && this.props.selectedMaps.length>0 && <div className='title'>{Partitions}</div>}
                      {false && this.props.selectedMaps.length>0 && <ul>
                      {this.props.selectedMaps.map(map => <li key={map.uuid}>{map.title}</li>)}
                      </ul>}
                    </div>}
                    {!(this.props.selectedMaps.length===1 && this.props.propertyStatuses.length===0) && this.props.isRegularAccountType && <div className='SidePanelContainer__controls'>
                      <div className='SidePanelContainer__control'>
                        {this.props.selectedMaps.length===0 && <a onClick={() => { this.props.setSubnav(true) }}>
                          <img alt='Select Partition' src={PartitionIcon}/>
                          <span>Select {Partition}</span>
                        </a>}
                        {!this.props.console.navigation.sidebar.customizePropertyStatus && this.props.propertyStatuses.length>0 && this.props.selectedMaps.length>0 && <a onClick={() => { this.props.setSidebarCustomizePropertyStatus(true) }}>
                          <img alt='Customize Property Statuses' src={LayerIcon}/>
                          <span>Customize Statuses</span>
                        </a>}
                        {this.props.console.navigation.sidebar.customizePropertyStatus && this.props.propertyStatuses.length>0 && this.props.selectedMaps.length>0 && <a className='right-align' onClick={() => { this.props.setSidebarCustomizePropertyStatus(false) }}>
                          <img alt='Save Property Statuses' src={SaveIcon}/>
                          <span>Close</span>
                        </a>}
                      </div>
                    </div>}
                    {this.props.console.navigation.sidebar.customizePropertyStatus && <div className='SidePanelContainer__feed'>
                      <CustomizePropertyStatus propertyStatuses={this.props.propertyStatuses} setPropertyStatusOrder={this.props.setPropertyStatusOrder}/>
                    </div>}
                    {!this.props.console.navigation.sidebar.customizePropertyStatus && this.props.places.length>0 && <div className='SidePanelContainer__feed'>
                      <FeatureItemFeedHoc match={this.props.match} parentView='geodesktop' displayPhotos={false} setPlaceAnimate={this.props.setPlaceAnimate}/>
                    </div>}
                  </div>
                </div>
              </div>

              <div className='dp-content-panel'>
                <div className='dp-content-panel-body'>
                  { this.props.navigation.docpane.list &&
                    <DocumentList
                      toggleMediaGallery={this.props.toggleMediaGallery}
                      account={this.props.account}
                      accountUUID={this.props.accountUUID}
                      mapUUID={this.props.console.mapUUID}
                      setMapUUID={this.props.setMapUUID}
                      map={this.props.selectedMap}
                      place={this.props.place}
                      placeId={this.props.console.placeId}
                      placeDocumentsByType={this.props.placeDocumentsByType}
                      document={this.props.console.document}
                      toggleDocumentAnalysis={this.props.toggleDocumentAnalysis}
                      toggleSeedPublisher={this.props.toggleSeedPublisher}
                      viewDocument={this.props.viewDocument}
                      toggleDocumentConfigDropwdown={this.props.toggleDocumentConfigDropwdown}
                      trashDocument={this.props.trashDocument}
                      updateDocumentAssetType={this.props.updateDocumentAssetType}
                      updateDocumentFileType={this.props.updateDocumentFileType}
                      setDocPaneMapView={this.props.setDocPaneMapView}
                      viewport={this.props.console.viewport}
                      createBookmark={this.props.createBookmark}
                      writableMaps={this.props.writableMaps}
                      history={this.props.history}
                      navigation={this.props.navigation}
                      updateDocumentTitle={this.props.updateDocumentTitle}
                      deleteFeature={this.props.deleteFeature}
                      mapType={this.props.mapType}
                      statuses={this.props.statuses}
                      onDataPublish={this.props.onDataPublish}
                      statusDataType={this.props.statusDataType}
                      featureUUID={this.props.console.featureUUID}
                      lifecycleDataType={this.props.lifecycleDataType}
                      locationNameDataType={this.props.locationNameDataType}
                      updateViewport={this.props.updateViewport}
                      places={this.props.places}
                      mapStyle={this.props.mapStyle}
                      setPlaceMapHeight={this.props.setPlaceMapHeight}
                      isRegularAccountType={this.props.isRegularAccountType}
                    />
                  }
                </div>
              </div>
          </div>

          {this.props.isRegularAccountType && <DesktopFooter
            navigation={this.props.navigation}
            setSubnav={this.props.setSubnav}
          />}

        </div>
      </div>
    );
  }
}

// GeoDesktop.propTypes = {
//   session: PropTypes.object.isRequired,
//   jobGroups: PropTypes.object.isRequired,
//   setSelectedJob: PropTypes.func.isRequired,
//   setSelectedJobGroup: PropTypes.func.isRequired,
//   setCompletedFilter:PropTypes.func.isRequired
// }

export default GeoDesktop;
