import React, {Component} from 'react'
import { Field, reduxForm } from 'redux-form';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Industrial from './../../assets/markers/industrialBuilding-geotiller.svg';
import Hotel from './../../assets/markers/hotel-geotiller.svg';
import Land from './../../assets/markers/land-geotiller.svg';
import SingleFamily from './../../assets/markers/singleFamilyHouse-geotiller.svg';
import Apartments from './../../assets/markers/apartment-geotiller.svg';
import Office from './../../assets/markers/office-geotiller.svg';
import Retail from './../../assets/markers/retail-geotiller.svg';
import Condo from './../../assets/markers/condo-geotiller.svg';
import MixedUse from './../../assets/markers/mixedUse-geotiller.svg';

import Generic from './../../assets/red-flag.svg';

import './DataPublisher.css';

// percentage //1 decimal
// decimal //2 decimal
// number //with comma
// date_year
// full_date //06/11/2018
// currency_with_decimal
// currency_without_decimal
// string
// select
// textarea

const DateField = (field, callback) => {
  const renderDatePicker = ({input, placeholder, defaultValue, meta: {touched, error} }) => (
    <div>
      <DatePicker {...input} dateForm="MM/DD/YYYY" selected={input.value ? moment(input.value) : null} />
      {touched && error && <span>{error}</span>}
    </div>
  )
  return (
    <div key={field.type+field.key}>
      <Field
        name={field.key}
        component={renderDatePicker}
        type="text"
        className='Field__date'
        onChange={callback}
      />
    </div>
  )
}

const TextAreaField = (field, callback) => {
  return (
    <div key={field.type+field.key}>
      <Field
        name={field.key}
        component="textarea"
        className='Field__textarea'
        onChange={callback}
      />
    </div>
  )
}

const SelectField = (field, callback) => {
  return (
    <div key={field.type+field.key}>
      <Field
        name={field.key}
        component="select"
        className='Field__select'
        onChange={callback}
      >
        <option value=''></option>
        {field.choices.map((choice, index) => {
          return <option key={index} value={choice}>{choice}</option>
        })}
      </Field>
    </div>
  )
}

const StringField = (field, callback) => {
  return (
    <div key={field.type+field.key}>
      <Field
        name={field.key}
        component="input"
        type="text"
        placeholder={field.title}
        className='Field__input'
        onChange={callback}
      />
    </div>
  )
}


class DataPublisher extends Component {

  constructor(props) {
    super(props);
    //this.props.setLocationTypeKey('Retail'); //nothing selected on load
    this.state = {
      snapshotAt: moment().utc(),
      ready: false,
      //locationType: null
    };
    //this.onFormChange = (e) => this._onFormChange(e);
    this.onFieldChange = (e) => this._onFieldChange(e);
  }

  _onFieldChange(e) {
    console.log('onFieldChange => ', e.target);
    console.log('onFieldChange name => ', e.target.name);
    console.log('onFieldChange value => ',e.target.value);

    //const { locationType, snapshotAt } = this.state;
    const { snapshotAt } = this.state;

    const {
      handleSubmit,
      mapType,
      //mapTypeKey,
      locationType,
      locationTypeKey,
      dataType,
      //dataTypeKey
    } = this.props;

    handleSubmit(values => {
      //console.log('onFieldChange handleSubmit => ', values);
      this.props.onSubmit({
        mapType,
        locationType,
        locationTypeKey,
        dataType,
        snapshotAt,
        values: {
          ...values,
          [e.target.name]: e.target.value
        }
      })
    })();

  }

  componentDidMount() {
    if (this.props.locationTypeKey) {
      this.setState({ready:true})
    }
  }

  componentWillUnmount() {
    //this.props.setLocationTypeKey(null);
    this.props.setDataTypeKey(null);
  }

  render() {

    const {
      //handleSubmit,
      dataTypes,
      locationTypes,
      //mapType,
      //mapTypeKey,
      //setMapTypeKey,
      //locationType,
      locationTypeKey,
      setLocationTypeKey,
      dataType,
      dataTypeKey,
      setDataTypeKey
    } = this.props;

    //const { snapshotAt, ready, locationType } = this.state;
    const { ready } = this.state;

    return (
      <div className='DataPublisher'>
        <div className='DataPublisher__locationTypeBox'>
          {locationTypes.map((locType, index) => (
            <div key={index} className={(locType===locationTypeKey) ? `DataPublisher__locationTypeItem selected ${locType}` : `DataPublisher__locationTypeItem ${locType}`} onClick={() => {
              this.setState({ready: true, locationType: locType});
              setLocationTypeKey(locType);
              //console.log('Setting locationTypeKey => ', locType);
              //console.log(locationType);
              //console.log(locationTypeKey);
            }}>
              <div className='DataPublisher__locationTypeItemContainer'>
                {(() => {
                  switch(locType) {
                    case "Retail":
                      return <img alt='Retail' src={Retail} />
                    case "Hotel":
                      return <img alt='Hotel' src={Hotel} />
                    case "Apartments":
                      return <img alt='Apartments' src={Apartments} />
                    case "Condo":
                      return <img alt='Condo' src={Condo} />
                    case "Mixed-Use":
                      return <img alt='Mixed-Use' src={MixedUse} />
                    case "Industrial":
                      return <img alt='Industrial' src={Industrial} />
                    case "Office":
                      return <img alt='Office' src={Office} />
                    case "Land":
                      return <img alt='Land' src={Land} />
                    case "Single Family":
                      return <img alt='Single Family' src={SingleFamily} />
                    case "Generic":
                      return <img alt='Generic' style={{padding: 9}} src={Generic} />
                    default:
                      return <img alt='Hotel' src={Hotel} />
                  }
                })()}
                <span>{locType}</span>
              </div>
            </div>
          ))}
        </div>
        {ready && (
          <form autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            //console.log("DataPublisher onSubmit")
            this.props.handlePublish();
            return false;
          }}>
            <div className='DataPublisher__dataModule'>
              <div className='DataPublisher__dataModuleItem'>
                <h5>Select Data Type</h5>
                <select className='DataPublisher__dataTypeSelect' value={dataTypeKey} onChange={(e) => setDataTypeKey(e.target.value)}>
                  <option value=''></option>
                  {dataTypes.map((dataType, index) => {
                    return <option key={locationTypeKey+dataType.key+index} value={dataType.key}>{dataType.title}</option>
                  })}
                </select>
              </div>
            </div>
            <div className='DataPublisher__dataModule'>
              <div className='DataPublisher__dataModuleItem'>
                  <div>
                    {dataType.fields.map((field) => {
                      switch(field.type) {
                        case 'select':
                          return SelectField(field, this.onFieldChange)
                        case 'full_date':
                          return DateField(field, this.onFieldChange)
                        case 'string':
                          return StringField(field, this.onFieldChange)
                        case 'textarea':
                          return TextAreaField(field, this.onFieldChange)
                        default:
                          return StringField(field, this.onFieldChange)
                      }
                    })}
                  </div>
              </div>
            </div>
          </form>
        )}
      </div>
    )
  }
};

export default reduxForm({
  form: 'DataPublisher', // a unique identifier for this form
})(DataPublisher);
