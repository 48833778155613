
import React, {Component} from 'react'

import './FeatureView.css';

import FileItem from './../GeoDesktop/DocumentList/FileItem'
import LinkItem from './../GeoDesktop/DocumentList/LinkItem'
import DataItem from './../GeoDesktop/DocumentList/DataItem'
import moment from 'moment';

import { confirmAlert } from 'react-confirm-alert'; // Import

import { Link } from 'react-router-dom';

import SplitPane from 'react-split-pane';

import addIcon from './../../assets/black-add.svg';
import linkIcon from './../../assets/zondicons/link.svg';
import layersIcon from './../../assets/zondicons/layers.svg';
import documentIcon from './../../assets/zondicons/document.svg';
import LocationIcon from './../../assets/zondicons/location.svg';
import PhotoIcon from './../../assets/zondicons/photo.svg';
import ArrowRight from './../../assets/zondicons/arrow-outline-right.svg';
import FullScreenIcon from './../../assets/zondicons/screen-full.svg';

const Spinner = require('react-spinkit');


class FeatureView extends Component {

  constructor(props) {
    super(props);
    this.onDetailDropdownClick = (doc) => this._onDetailDropdownClick(doc);
    this.confirmDeleteFeature = (feature) => this._confirmDeleteFeature(feature);
    this.handleDeleteMapSelect = (event) => this._handleDeleteMapSelect(event);
    this.handleStatusSelect = (event) => this._handleStatusSelect(event);
    this.handleLifecycleSelect = (event) => this._handleLifecycleSelect(event);
    this.handlePlaceNameChange = (event) => this._handlePlaceNameChange(event);
    this.submitPlaceNameChange = () => this._submitPlaceNameChange();
    this.resize = () => this._resize();

    var features = [];
    if (this.props.place && this.props.place.maps && this.props.place.maps.length>0 && this.props.place.features && this.props.place.features.length>0) {
      features = this.props.place.features.filter(feature => feature.mapUUID===this.props.place.maps[0].uuid);
    }

    this.state = {
      documentUUID: null,
      deleting: false,
      deleteMapUUID: (features.length > 0) ? features[0].mapUUID : null,
      deleteFeatureUUID: (features.length > 0) ? features[0].uuid : null,
      selectedStatus: this.props.placeDocumentsByType.selectedStatus,
      selectedLifecycle: this.props.placeDocumentsByType.selectedLifecycle,
      editingPlaceName: false,
      selectedPlaceName: this.props.place.name
    }
  }

  componentDidMount() {
    const { setPlaceMapHeight, updateViewport, viewport} = this.props;

    setPlaceMapHeight((window.innerHeight/3));
    window.addEventListener('resize', this.resize);
    this.resize();
    updateViewport({
      ...viewport,
      zoom: 12
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize);
  }

  componentDidUpdate(prevProps) {

    if (
      (prevProps.place.features !== this.props.place.features)
      || (prevProps.place.maps !== this.props.place.maps)
    ) {
      var features = [];
      if (this.props.place && this.props.place.maps && this.props.place.maps.length>0 && this.props.place.features && this.props.place.features.length>0) {
        features = this.props.place.features.filter(feature => feature.mapUUID===this.props.place.maps[0].uuid);
      }
      this.setState({
        deleteMapUUID: (features.length > 0) ? features[0].mapUUID : null,
        deleteFeatureUUID: (features.length > 0) ? features[0].uuid : null,
        selectedStatus: this.props.placeDocumentsByType.selectedStatus,
        selectedLifecycle: this.props.placeDocumentsByType.selectedLifecycle,
        selectedPlaceName: this.props.place.name
      })
    }

  }

  _resize = () => {
    const { viewport } = this.props;
    this.props.updateViewport({
      ...viewport,
      height: this.props.navigation.docpane.placeMapHeight,
      width: window.innerWidth-350
    })
  };

  _confirmDeleteFeature = (feature) => {
    const { deleteFeature, accountUUID, history } = this.props;
    confirmAlert({
      title: 'Confirm delete',
      message: "Are you sure you want to delete this feature and all of the it's associated links, files, and data?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteFeature(feature);
            history.push(`/desktop/${accountUUID}`);
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting deleteFeature =>", feature)}
        }
      ]
    })
  }

  _handleStatusSelect = (event) => {
    const { onDataPublish, statusDataType, mapType, place } = this.props;
    const selectedStatus = event.target.value;
    this.setState({selectedStatus})
    onDataPublish({
      dataType: statusDataType,
      locationType: {
        dataTypes: [],
        locationTypeKey: place.locationTypeKey,
      },
      locationTypeKey: place.locationTypeKey,
      mapType,
      snapshotAt: moment().utc(),
      values: {
        status_selection: selectedStatus
      }
    });
  }


  _handleLifecycleSelect = (event) => {
    const { onDataPublish, lifecycleDataType, mapType, place } = this.props;
    const selectedLifecycle = event.target.value;
    this.setState({selectedLifecycle})
    onDataPublish({
      dataType: lifecycleDataType,
      locationType: {
        dataTypes: [],
        locationTypeKey: place.locationTypeKey,
      },
      locationTypeKey: place.locationTypeKey,
      mapType,
      snapshotAt: moment().utc(),
      values: {
        lifecycle_selection: selectedLifecycle
      }
    });
  }

  _handlePlaceNameChange = (event) => {
    const selectedPlaceName = event.target.value;
    this.setState({selectedPlaceName})
  }

  _submitPlaceNameChange = () => {
    const { onDataPublish, locationNameDataType, mapType, place } = this.props;
    const { selectedPlaceName } = this.state;
    onDataPublish({
      dataType: locationNameDataType,
      locationType: {
        dataTypes: [],
        locationTypeKey: place.locationTypeKey,
      },
      locationTypeKey: place.locationTypeKey,
      mapType,
      snapshotAt: moment().utc(),
      values: {
        building_name: selectedPlaceName
      }
    });
    this.setState({editingPlaceName: false})
  }

  _onDetailDropdownClick(documentUUID) {
    this.setState({documentUUID})
  }

  render() {
    const {
      navigation,
      account,
      placeDocumentsByType,
      featureUUID,
      placeId,
      place,
      document,
      map,
      toggleMediaGallery,
      toggleDocumentAnalysis,
      viewDocument,
      trashDocument,
      accountUUID,
      mapUUID,
      createBookmark,
      viewport,
      history,
      updateDocumentTitle,
      statusDataType,
      lifecycleDataType,
      updateViewport,
      places,
      //mapStyle,
      setPlaceMapHeight
    } = this.props;



    const editors = ['owner','member'];

    const { documentUUID, deleteMapUUID, deleteFeatureUUID, selectedStatus, selectedLifecycle, selectedPlaceName, editingPlaceName } = this.state;

    const images = placeDocumentsByType.links.filter(link => link.og && link.og.image).map((link) => link.og.image);

    return (
        <div className='GeoSeeds'>
          <div className='GeoSeeds__PlaceView'>
            <div className='GeoSeeds__header'>
              <div className='GeoSeeds__header-accessory'>
                <img alt='Icon' src={place.icon}/>
              </div>
              <div className='GeoSeeds__header-title'>
                <div className='GeoSeeds__header-title-group'>
                  <div className='GeoSeeds__header-title-group-item'>
                    {(map.role==='follower') && <h3>{place.name}</h3>}
                    {editors.includes(map.role) && !editingPlaceName && <h3 onClick={() => this.setState({editingPlaceName: true})}>{place.name}</h3>}
                    {editors.includes(map.role) && editingPlaceName && <div className='GeoSeeds__header-title-group-item-edit-title'>
                      <input onChange={this.handlePlaceNameChange} value={selectedPlaceName} />
                      <img alt='submit' src={ArrowRight} onClick={this.submitPlaceNameChange}></img>
                    </div>}
                  </div>
                </div>
                <h5><img alt='location' src={LocationIcon} /> {place.title}</h5>
              </div>
              <div className='GeoSeeds__header-twiddle'>
                <div className='GeoSeeds__header-twiddle-group'>
                </div>
              </div>
            </div>

            <div className='GeoSeeds__content-group Links'>
              <div className='GeoSeeds__content-desc'>
                <div className='GeoSeeds__content-desc-header'>
                  <img alt='Icon' style={{color: '#AAAAAA'}} src={linkIcon} />
                  <h4>Links</h4>
                </div>
                <p>Add web links relating to this property</p>
              </div>
              <div className='GeoSeeds__content-box'>
                <div className='GeoSeeds__DataTable'>
                    {placeDocumentsByType.links.map((doc, index) => {
                      return (
                        <LinkItem
                          key={doc.uuid}
                          viewDocument={viewDocument}
                          account={account}
                          document={document}
                          documentUUID={documentUUID}
                          onDetailDropdownClick={this.onDetailDropdownClick}
                          doc={doc}
                          toggleDocumentAnalysis={toggleDocumentAnalysis}
                          trashDocument={trashDocument}
                          accountUUID={accountUUID}
                          createBookmark={createBookmark}
                          updateDocumentTitle={updateDocumentTitle}
                        />
                      )
                    })}
                </div>
                <div className={editors.includes(map.role) ? 'GeoSeeds__content-box-cta' : 'GeoSeeds__content-box-cta disabled'}>
                  <img alt='Add' src={addIcon}/>
                  <Link className='GeoSeedAddLink' to={'/publisher/link/'+accountUUID+'/'+(mapUUID ? mapUUID : 'null')+'/'+placeId}><h5>Add link</h5></Link>
                </div>
              </div>
            </div>

            <div className='GeoSeeds__content-group Files'>
              <div className='GeoSeeds__content-desc'>
                <div className='GeoSeeds__content-desc-header'>
                  <img alt='Files' style={{color: '#AAAAAA'}} src={documentIcon} />
                  <h4>Files</h4>
                </div>
                <p>Add files to this property</p>
              </div>
              <div className='GeoSeeds__content-box'>
              <div className='GeoSeeds__DataTable'>
                    {placeDocumentsByType.files.map((doc, index) => {
                      return (
                        <FileItem
                          key={doc.uuid}
                          viewDocument={viewDocument}
                          account={account}
                          document={document}
                          documentUUID={documentUUID}
                          onDetailDropdownClick={this.onDetailDropdownClick}
                          doc={doc}
                          toggleDocumentAnalysis={toggleDocumentAnalysis}
                          trashDocument={trashDocument}
                          accountUUID={accountUUID}
                          createBookmark={createBookmark}
                          updateDocumentTitle={updateDocumentTitle}
                        />
                      )
                    })}
                </div>
                <div className={editors.includes(map.role) ? 'GeoSeeds__content-box-cta' : 'GeoSeeds__content-box-cta disabled'}>
                  <img alt='Add' src={addIcon}/>
                  <Link className='GeoSeedAddFile' to={'/publisher/file/'+accountUUID+'/'+(mapUUID ? mapUUID : 'null')+'/'+placeId}><h5>Add file</h5></Link>
                </div>
              </div>
            </div>

            <div className='GeoSeeds__content-group Data'>
              <div className='GeoSeeds__content-desc'>
                <div className='GeoSeeds__content-desc-header'>
                  <img alt='Data' style={{color: '#AAAAAA'}} src={layersIcon}/>
                  <h4>Data</h4>
                </div>
                <p>Add asset-specific data to this property.</p>
              </div>
              <div className='GeoSeeds__content-box'>
                <div className='GeoSeeds__DataTable'>
                  <div className='header'>
                  </div>
                  {placeDocumentsByType.dataGroupedByType.map((doc, index) => (
                    <DataItem
                      key={index}
                      index={index}
                      doc={doc}
                      accountUUID={accountUUID}
                      trashDocument={trashDocument}
                    />
                  ))}
                </div>
                <div className={editors.includes(map.role) ? 'GeoSeeds__content-box-cta' : 'GeoSeeds__content-box-cta disabled'}>
                  <img alt='Add' src={addIcon}/>
                  <Link className='GeoSeedAddData' to={'/publisher/data/'+accountUUID+'/'+(mapUUID ? mapUUID : 'null')+'/'+placeId}><h5>Add data</h5></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  };
};

export default FeatureView
