export default {
  "dataTypes": [{
      "key": "hotel_type",
      "title": "Hotel Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Full Service", "Limited Service", "Boutique", "Suite", "Extended-Stay"]
      }]
    },
    {
      "key": "hotel_rank",
      "title": "Hotel Rank",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["Luxury", "Upper-Upscale", "Upscale", "Upper-MidScale", "Mid-Scale", "Economy"]
      }]
    },
    {
      "key": "hotel_brand",
      "title": "Hotel Brand",
      "fields": [{
        "key": "hotel_brand",
        "title": "Hotel Brand",
        "type": "string"
      }]
    }
  ]
}
