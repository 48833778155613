
import React, {Component} from 'react'

import MapGL, {Marker, StaticMap, Popup, NavigationControl} from 'react-map-gl';

import PropertyMiniPopup from './../../PropertyMiniPopup'


import config from './../../../config'

class PlaceMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hoverPlace: null
    };
    this.handleMarkerMouseOver = (place) => this._handleMarkerMouseOver(place);
    this.handleMarkerMouseOut = () => this._handleMarkerMouseOut();
  }

  _handleMarkerMouseOver(hoverPlace) {
    this.setState({hoverPlace});
  }

  _handleMarkerMouseOut() {
    this.setState({hoverPlace: null});
  }

  render() {
    const {
      accountUUID,
      viewport,
      mapStyle,
      places,
      place,
      updateViewport,
      history
    } = this.props;

    const { hoverPlace } = this.state;

    return (
      <div>
        {(viewport && viewport.longitude && viewport.latitude) && <MapGL
          // {...viewport}
          latitude={viewport.latitude}
          longitude={viewport.longitude}
          zoom={viewport.zoom}
          minZoom={viewport.minZoom}
          width={viewport.width}
          height={viewport.height}
          bounds={viewport.bounds}
          pitch={viewport.pitch}
          maxPitch={viewport.maxPitch}
          bearing={viewport.bearing}
          altitude={viewport.altitude}
          maxZoom={viewport.maxZoom}
          minPitch={viewport.minPitch}
          ref={ map => this.placeMapRef = map }
          onViewportChange={(viewport) => {
            if (this.placeMapRef) {
              console.log('PlaceMap getMap', this.placeMapRef.getMap().getBounds());
              updateViewport({
                ...viewport,
                height: window.innerHeight,
                bounds: this.placeMapRef.getMap().getBounds()
              })
            }
          }}
          style={{zIndex: 1}}
          onClick={(e) => console.log(e)}
          reuseMaps={true}
          mapStyle={mapStyle}
          mapboxApiAccessToken={config.MapBox}
        >
          {(hoverPlace) &&
            <Popup tipSize={5}
              anchor="top"
              longitude={hoverPlace.location.lng}
              latitude={hoverPlace.location.lat}
              offsetLeft={hoverPlace.popup.offsetLeft}
              offsetTop={hoverPlace.popup.offsetTop}
              className='MapPopup'
              closeButton={false}>
              <PropertyMiniPopup
                place={hoverPlace}
              />
            </Popup>
          }
          {places.map((p) => {
              var className = `MasterIcon ${p.marker.icon}`;
              if (p.uuid===place.uuid) className += ' Bounce';
              if (p.targetProperty) className += ' TargetProperty';
              return (
                <Marker key={p.location.place_id} latitude={p.location.lat} longitude={p.location.lng} offsetLeft={p.marker.offsetLeft} offsetTop={p.marker.offsetTop}>
                  <div className={className} onMouseOver={(e) => this.handleMarkerMouseOver(p)} onMouseOut={(e) => this.handleMarkerMouseOut()} onClick={(e) => {
                    history.push(`/desktop/${accountUUID}/${p.id}/docpane`);
                  }}/>
                </Marker>
              )
            }
          )}
        </MapGL>}
      </div>
    )
  }
};

export default PlaceMap
