export default {
  "dataTypes": [{
      "key": "apartment_type",
      "title": "Apartment Type",
      "fields": [{
        "key": "type",
        "title": "Type",
        "type": "select",
        "choices": ["High-Rise", "Mid-Rise", "Low-Rise", "Garden-Style", "Podium", "Walk-up/Brownstone", "Townhome", "Student Housing", "Manufactured"]
      }]
    },
    {
      "key": "unit_count",
      "title": "Unit Count",
      "fields": [{
        "key": "unit_count",
        "title": "Units",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "average_unit_size",
      "title": "Average Unit Size",
      "fields": [{
        "key": "unit_size",
        "title": "Average Unit Size",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "apartment_average_rent",
      "title": "Average Rent (gross)",
      "fields": [{
        "key": "average_unit_rent",
        "title": "Average Rent per Unit",
        "type": "currency_without_decimal"
      }]
    },
    {
      "key": "density",
      "title": "Density per Acre",
      "fields": [{
        "key": "unit_density",
        "title": "Density per Acre",
        "type": "number_with_comma"
      }]
    },
    {
      "key": "lease_comp",
      "title": "Lease Comp",
      "fields": [{
        "key": "lease_date",
        "title": "Lease Date",
        "type ": "string"
      }, {
        "key": "unit_type",
        "title": "Unit Type",
        "fields": [{
          "key": "type",
          "title": "Unit Type",
          "type": "select",
          "choices": ["0x1", "1x1", "2x1", "2x2", "3x2", "3x3", "4+"]
        }]
      }, {
        "key": "lease_rent",
        "title": "Lease Rent (gross)",
        "type": "currency_without_decimal"
      }, {
        "key": "lease_square_footage",
        "title": "Lease Square Footage",
        "type ": "number_with_comma"
      }, {
        "key": "lease_rent_psf",
        "title": "Lease Rent PSF",
        "type ": "currency_with_decimal"
      }, {
        "key": "lease_term",
        "title": "Lease Term (months)",
        "type ": "number_with_comma"
      }, {
        "key": "rent_concession",
        "title": "Rent Concession (months)",
        "type ": "number_with_comma"
      }, {
        "key": "lease_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    },
    {
      "key": "sale_comp",
      "title": "Sale Comp",
      "fields": [{
        "key": "sale_date",
        "title": "Sale Date",
        "type ": "string"
      }, {
        "key": "sale_price",
        "title": "Sale Price",
        "type ": "currency_without_decimal"
      }, {
        "key": "sale_price_per_unit",
        "title": "Price per Unit",
        "type ": "currency_without_decimal"
      }, {
        "key": "sale_price_psf",
        "title": "Price PSF",
        "type ": "currency_without_decimal"
      }, {
        "key": "cap_rate",
        "title": "Cap Rate",
        "type ": "percentage_2_decimal"
      }, {
        "key": "grm",
        "title": "GRM",
        "type ": "number_2_decimal"
      }, {
        "key": "percent_occupied",
        "title": "Occupancy at Sale",
        "type ": "percentage_2_decimal"
      }, {
        "key": "buyer",
        "title": "Buyer",
        "type ": "string "
      }, {
        "key": "seller",
        "title": "Seller",
        "type ": "string"
      }, {
        "key": "sale_comp_notes",
        "title": "Notes",
        "type ": "textarea"
      }]
    }
  ]
}
