
import React, {Component} from 'react'

//import ellipsis from './../../../assets/ellipsis.svg';
import TrashIcon from './../../../assets/zondicons/trash.svg';

import { confirmAlert } from 'react-confirm-alert'; // Import

//const moment = require('moment');

class DataItem extends Component {

  constructor(props) {
    super(props);
    this.confirmDeleteDocument = (doc) => this._confirmDeleteDocument(doc);
  }

  _confirmDeleteDocument = (doc) => {
    //console.log(doc)
    const { trashDocument } = this.props;
    confirmAlert({
      title: 'Confirm delete',
      message: "Are you sure you want to delete this data?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            trashDocument(doc);
            //history.push(`/desktop/${accountUUID}`);
          }
        },
        {
          label: 'No',
          onClick: () => {console.log("Aborting deleteDocument =>", {})}
        }
      ]
    })
  }

  render() {
    const {
      //account,
      //document,
      index,
      doc,
      //toggleDocumentAnalysis,
      //viewDocument,
      //trashDocument,
      accountUUID,
      //createBookmark,
      //documentUUID,
      //onDetailDropdownClick
    } = this.props;


    const multipleDataItems = [
      'Sale Comp',
      'Lease Comp',
      'For Lease Details',
      'For Sale Details',
      'Investment Overview',
      'Investment Details',
      'Office Lease Comp',
      'Retail Lease Comp',
      'Apt. Lease Comp"'
    ]

    const singleDateItem = (doc, index) => {
      return (
        <div className={(index%2===0) ? 'GeoSeed__singleDataItem' : 'GeoSeed__singleDataItem alt1'}>
              <div className='GeoSeed__singleDataItem-title'>
                <span>{doc.dataType}</span>
              </div>
              <div className='GeoSeed__singleDataItem-data'>
                <ul>
                {doc.records.map((record, dataIndex) => (
                  <li key={dataIndex}>
                    <div className='DataBox'>
                      <div className='cell'>{record.value}</div>
                      {doc.author && doc.author.uuid===accountUUID && <div className='trash GeoSeed__trash' onClick={() => this.confirmDeleteDocument({
                        ...doc,
                        uuid: record.dataUUID
                      })}>
                        <img alt='delete' src={TrashIcon} />
                      </div>}
                    </div>
                  </li>
                ))}
                </ul>
              </div>
        </div>
      )
    }

    const multipleDataItem = (doc, index) => {
      var recordsGroupedByDataUUID = Object.keys(doc.recordsByDataUUID).map(dataUUID => {
        //console.log(doc.recordsByDataUUID[dataUUID]);
        return {
          dataUUID,
          records: doc.recordsByDataUUID[dataUUID]
        }
      });
      return (
        <div className={(index%2===0) ? 'GeoSeed__multipleDataItem' : 'GeoSeed__multipleDataItem alt1'}>
              <div className='GeoSeed__multipleDataItem-title'>
                <span>{doc.dataType}</span>
              </div>
              <div className='GeoSeed__multipleDataItem-data'>
                {recordsGroupedByDataUUID.map((data, recordIndex) => (
                  <ul key={recordIndex}>
                    {data.records.map((record, dataIndex) => (
                      <li key={dataIndex}>
                        <div className='DataBox'>
                          <div className='cell'>{record.title}</div>
                          <div className='cell-2'>{record.value}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
              {doc.author && doc.author.uuid===accountUUID && <div className='GeoSeed__multipleDataItem-trash GeoSeed__trash' onClick={() => this.confirmDeleteDocument(doc)}>
                <img alt='delete' src={TrashIcon} />
              </div>}
        </div>
      )
    }

    // const multipleDataItem = (doc, index) => {
    //   return (
    //     <div className={(index%2===0) ? 'GeoSeed__multipleDataItem' : 'GeoSeed__multipleDataItem alt1'}>
    //           <div className='GeoSeed__multipleDataItem-title'>
    //             <span>{doc.dataType}</span>
    //           </div>
    //           <div className='GeoSeed__multipleDataItem-data'>
    //             <ul>
    //             {doc.records.map((record, index) => (
    //               <li key={index}>
    //                 <div className='DataBox'>
    //                   <div className='cell'>{record.title}</div>
    //                   <div className='cell-2'>{record.value}</div>
    //                 </div>
    //               </li>
    //             ))}
    //             </ul>
    //           </div>
    //           {doc.author.uuid===accountUUID && <div className='GeoSeed__multipleDataItem-trash GeoSeed__trash' onClick={() => this.confirmDeleteDocument(doc)}>
    //             <img alt='delete' src={TrashIcon} />
    //           </div>}
    //     </div>
    //   )
    // }

    const isMultipleDataItem = (doc) => {
      return multipleDataItems.includes(doc.dataType)
    }

    return (
      <div>
        {isMultipleDataItem(doc) && multipleDataItem(doc, index)}
        {!isMultipleDataItem(doc) && singleDateItem(doc, index)}
      </div>
    )
  }

};

export default DataItem
